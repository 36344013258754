import { Stack, TextField } from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  IReviewAndSignStage,
  isSomething,
  nothing,
  Optional,
  selectReviewAndSignConfiguration,
  setIsSBSElectionSaveEnabled,
  setReviewAndSignSignature,
  some,
} from "common";
import React, { forwardRef, Ref } from "react";
import {
  Control,
  Controller,
  ControllerRenderProps,
  useFormState,
} from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./SignAndSubmitCard.module.scss";

interface SignAndSubmitCardProps {
  control: Control<IReviewAndSignStage>;
}

const SignAndSubmitCardBase = (
  props: SignAndSubmitCardProps,
  ref: Ref<HTMLFormElement>
) => {
  const dispatch = useDispatch();
  const { submitMessage } = useSelector(selectReviewAndSignConfiguration);

  const handleChange = (
    field: ControllerRenderProps<IReviewAndSignStage, "signature">
  ) => {
    return (e: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setIsSBSElectionSaveEnabled(true));
      field.onChange(e.target.value == "" ? nothing : some(e.target.value));
    };
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dispatch(
      setReviewAndSignSignature(
        e.target.value == "" ? nothing : some(e.target.value)
      )
    );
  };

  const { errors } = useFormState({ control: props.control });
  const hasErrors = errors.signature?.message !== undefined;

  return (
    <ElectionCard error={hasErrors}>
      <FormCardHeader
        required
        title={ElectionsLabels.SIGN_AND_SUBMIT}
        subTitle={ElectionsLabels.SIGNATURE_IS_REQUIRED}
        hasError={hasErrors}
      />
      <Stack className={styles.acknowledgements}>
        {isSomething(submitMessage) ? submitMessage.value : ""}
      </Stack>
      <form ref={ref}>
        <Controller
          control={props.control}
          name="signature"
          render={({ field, fieldState }) => {
            return (
              <TextField
                id={styles.signatureField}
                value={isSomething(field.value) ? field.value.value : ""}
                placeholder={ElectionsLabels.SIGN_HERE}
                onChange={handleChange(field)}
                onBlur={(e) => handleBlur(e)}
                error={fieldState.error?.message !== undefined}
                autoComplete="off"
                // Single field forms will submit if the use presses "Enter" 😱!
                // We don't want that here so supress the browser default.
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
            );
          }}
          rules={{
            validate: (data: Optional<string>) => {
              if (!isSomething(data)) {
                return "Field required";
              }
            },
          }}
        />
      </form>
    </ElectionCard>
  );
};

export const SignAndSubmitCard = forwardRef(SignAndSubmitCardBase);
