import { Link, Typography } from "@mui/material";
import {
  buildAndDownloadZipFile,
  closeAlert,
  DocumentsLabel,
  ElectionsLabels,
  ErrorStringConstants,
  getDocDownloadTimeZoneQueryParameters,
  getMultipleDocuments,
  IElectionDocument,
  openAlert,
} from "common";
import React from "react";
import { useDispatch } from "react-redux";

import { DownloadDocumentLink } from "./DownloadDocumentLink";
import styles from "./DownloadDocumentPanel.module.scss";

export interface IDownloadDocumentPanelProps {
  documents: IElectionDocument[];
  electionRoundId: string;
}

export const DownloadDocumentPanel = (props: IDownloadDocumentPanelProps) => {
  const { documents, electionRoundId } = props;
  const dispatch = useDispatch();

  const downloadMultipleDocuments = async (
    electionRoundId: string,
    documentOIds: string[]
  ) => {
    const queryParams = getDocDownloadTimeZoneQueryParameters();
    documentOIds.forEach((documentOId) => {
      queryParams.append("documentOIds", documentOId);
    });

    const blobResponse = await getMultipleDocuments(
      electionRoundId,
      queryParams.toString()
    );

    if (blobResponse === undefined) {
      dispatch(
        openAlert({
          severity: "error",
          message: ErrorStringConstants.DOWNLOAD_FAILED,
        })
      );
      return;
    }

    buildAndDownloadZipFile(blobResponse, "SBS Election Documents_");
  };

  const handleMultipleDocumentsDownload = async (
    electionRoundId: string,
    documents: IElectionDocument[]
  ) => {
    const documentIds = documents.map((document) => document.id.toString());
    dispatch(
      openAlert({
        message: DocumentsLabel.PREPARING_DOCUMENTS,
        severity: "info",
        hideDuration: null, // Keep alert open until we or the user close it!
      })
    );
    await downloadMultipleDocuments(electionRoundId, documentIds);
    dispatch(closeAlert());
  };

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <div className={styles.label}>
          <Typography variant={"h5"}>
            {ElectionsLabels.ELECTION_LIST_TITLE}
          </Typography>
        </div>
      </div>
      <div className={styles.links}>
        {documents.map((document: IElectionDocument) => {
          return (
            <DownloadDocumentLink
              key={document.id}
              document={document}
              electionRoundId={electionRoundId}
            />
          );
        })}
      </div>
      <div className={styles.footer}>
        <Typography variant={"h5"}>
          <Link
            component={"button"}
            className={styles.text}
            onClick={() =>
              handleMultipleDocumentsDownload(electionRoundId, documents)
            }
          >
            {ElectionsLabels.DOWNLOAD_ALL_DOCUMENTS(documents.length)}
          </Link>
        </Typography>
      </div>
    </div>
  );
};
