import { Button, Card, Link, Stack, Typography } from "@mui/material";
import {
  DocumentsLabel,
  isSomething,
  nothing,
  Optional,
  PathFragment,
  some,
  StringConstants,
  useDownloadSingleDocument,
} from "common";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styles from "./SingleDocumentDownloadPage.module.scss";

interface DocumentIdentifier {
  type: string;
  value: string;
}

export const SingleDocumentDownloadPage = () => {
  const IDENTIFIER_SEPARATOR = "_";
  const { search } = useLocation();
  const navigate = useNavigate();
  const [documentIdIsInvalid, setDocumentIdIsInvalid] = useState(false);

  const { downloadSingleDocument } = useDownloadSingleDocument();

  // create a ref that can survive component being remounted and avoid downloading
  // the document twice if something weird happens
  const hasDownloaded = useRef(false);

  const navigateToAllDocs = useCallback(() => {
    navigate(`/${PathFragment.DOCUMENTS}`);
  }, [navigate]);

  const parseDocIdentifierFromUrl =
    useCallback((): Optional<DocumentIdentifier> => {
      const searchParams = new URLSearchParams(search);
      const documentIdentifier = searchParams.get("documentId");
      if (documentIdentifier) {
        const separatorIndex = documentIdentifier.indexOf(IDENTIFIER_SEPARATOR);
        if (separatorIndex > 0) {
          return some({
            type: documentIdentifier.substring(0, separatorIndex),
            value: documentIdentifier.substring(separatorIndex + 1),
          });
        }
      }

      return nothing;
    }, [search]);

  const handleDocDownload = useCallback(async () => {
    const identifier = parseDocIdentifierFromUrl();
    if (isSomething(identifier)) {
      switch (identifier.value.type) {
        case "oid":
          const responseCode = await downloadSingleDocument(
            Number(identifier.value.value)
          );
          if (responseCode >= 400) {
            setDocumentIdIsInvalid(true);
          }
          break;
        default:
          // If we don't recognize the identifer type show an error.
          setDocumentIdIsInvalid(true);
      }
    } else {
      setDocumentIdIsInvalid(true);
    }
  }, [downloadSingleDocument, parseDocIdentifierFromUrl]);

  useEffect(() => {
    if (hasDownloaded.current === false) {
      hasDownloaded.current = true;
      handleDocDownload();
    }
  }, [handleDocDownload]);

  return (
    <Stack id={styles.content}>
      <Button onClick={navigateToAllDocs}>
        {DocumentsLabel.RETURN_TO_ALL_DOCUMENTS}
      </Button>
      <Card id={styles.messageCard}>
        {documentIdIsInvalid ? (
          <>
            <Typography>{DocumentsLabel.INACCESIBLE_DOCUMENT}</Typography>
            <Typography>
              {DocumentsLabel.PLEASE}
              <Link onClick={navigateToAllDocs}>
                {DocumentsLabel.CLICK_HERE}
              </Link>
              {DocumentsLabel.TO_RETURN_TO_PLATFORM}
            </Typography>
          </>
        ) : (
          <>
            <Typography>{DocumentsLabel.DOWNLOADING_YOUR_DOCUMENT}</Typography>
            <Typography>
              {DocumentsLabel.PLEASE}
              <Link onClick={handleDocDownload}>
                {DocumentsLabel.CLICK_HERE}
              </Link>
              {DocumentsLabel.IF_YOUR_DOCUMENT_DOES_NOT_DOWNLOAD}
            </Typography>
          </>
        )}
        <Typography>
          {DocumentsLabel.IF_YOU_EXPERIENCE_ISSUES}
          <Link href={`mailto:${StringConstants.APP_SUPPORT_EMAIL}`}>
            {StringConstants.APP_SUPPORT_EMAIL}
          </Link>
          {DocumentsLabel.FOR_ASSISTANCE}
        </Typography>
      </Card>
    </Stack>
  );
};
