import { Text, View } from "@react-pdf/renderer";
import {
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardGridRow,
  zeroIfNothing,
} from "common";
import React from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "../ElectionAmountTableRows/ElectionAmountTableRowsStyleSheet";

interface IElectionAmountTableTotalProps {
  item: IElectCardGridRow;
}

export const ElectionAmountTableRowTotal = (
  props: IElectionAmountTableTotalProps
) => {
  const { item } = props;
  return (
    <View style={styles.row}>
      <Text style={[sharedStyles.textColumn, styles.strategy]}>
        {item.name}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.remainingCommitment]}>
        {getCurrencyFormattedValueWithZeroDefault(item.remainingCommitment)}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.mandatoryCommitment]}>
        {getCurrencyFormattedValueWithZeroDefault(item.mandatoryCommitment)}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.election]}>
        {getCurrencyFormattedValueWithZeroDefault(
          zeroIfNothing(item.optionalElection)
        )}
      </Text>
      <Text style={[sharedStyles.textColumn, styles.totalCommitment]}>
        {getCurrencyFormattedValueWithZeroDefault(item.totalCommitment)}
      </Text>
      <Text
        style={[
          sharedStyles.textColumn,
          styles.forecastedInvestment,
          sharedStyles.grayBackgroundColor,
        ]}
      >
        {getCurrencyFormattedValueWithZeroDefault(item.totalForecastInvestment)}
      </Text>
    </View>
  );
};
