import { StyleSheet } from "@react-pdf/renderer";
import { colors } from "common";

export const styles = StyleSheet.create({
  page: {
    padding: 32,
  },
  title: {
    textAlign: "left",
    fontWeight: 700,
    fontSize: 16,
    paddingBottom: 8,
  },
  subTitle: {
    textAlign: "left",
    fontWeight: 700,
    fontSize: 14,
    paddingBottom: 8,
  },
  paragraph: {
    textAlign: "justify",
    fontSize: 12,
    paddingBottom: 8,
  },
  signatureContainer: {
    marginBottom: 8,
  },
  signature: {
    padding: 8,
    textAlign: "left",
    fontSize: 16,
    fontStyle: "italic",
    borderColor: colors.bx_slate_shade,
    borderWidth: 1,
    borderRadius: 1,
  },
  footer: {
    position: "absolute",
    fontSize: 10,
    textAlign: "left",
    left: 32,
    bottom: 32,
  },
});
