import {
  Alert,
  closeAlert,
  getUserIdentifier,
  refreshTokenIfNearExpiration,
} from "common";
import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import { useAuth } from "react-oidc-context";
import { useDispatch } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { RouterProvider } from "../common/components/RouterProvider/RouterProvider";
import { Header } from "../features/Header/Header";
import { InvestorUIStore } from "../redux/store";

const App = () => {
  const auth = useAuth();
  const employeeNumber = getUserIdentifier(auth.user);
  const dispatch = useDispatch();

  if (employeeNumber !== undefined) {
    // Send the user identity to Google Analytics.
    ReactGA.set({ user_id: employeeNumber.toString() });
  }

  useEffect(() => {
    const interval = window.setInterval(
      () => refreshTokenIfNearExpiration(auth),
      60_000
    );
    return () => window.clearInterval(interval);
  }, [auth]);

  return (
    <BrowserRouter>
      <Header />
      <RouterProvider />
      <Alert
        alertSelector={(store: InvestorUIStore) => store.viewData.alert}
        handleClose={() => dispatch(closeAlert())}
      />
    </BrowserRouter>
  );
};

export default App;
