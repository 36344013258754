import {
  DataCard,
  ElectionsLabels,
  FormCardHeader,
  getAsOfLine,
  isEmptyResponse,
  isSomething,
  PriorElections,
  selectElectionInvestmentPortfolioLoadStatus,
  selectPriorElections,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import styles from "./PriorElectionsCard.module.scss";
import { PriorElectionsTable } from "./PriorElectionsTable/PriorElectionsTable";

export const PriorElectionsCard = () => {
  const priorElections = useSelector(selectPriorElections);
  const electionInvestmentPortfolioLoadStatus = useSelector(
    selectElectionInvestmentPortfolioLoadStatus
  );

  return (
    <DataCard
      id={styles.priorElectionsCard}
      className={styles.prElectionsCard}
      noData={
        isEmptyResponse(electionInvestmentPortfolioLoadStatus) ||
        !isSomething(priorElections)
      }
      hideIfNoData
    >
      {isSomething(priorElections) && (
        <PriorElectionsCardContent priorElections={priorElections.value} />
      )}
    </DataCard>
  );
};

interface IPriorElectionsCardContentProps {
  priorElections: PriorElections;
}

const PriorElectionsCardContent = (props: IPriorElectionsCardContentProps) => {
  const { priorElections } = props;
  return (
    <>
      <FormCardHeader
        title={ElectionsLabels.PRIOR_ELECTIONS}
        subTitle={getAsOfLine(priorElections.asOfDate)}
      />
      <PriorElectionsTable priorElections={priorElections.priorElections} />
    </>
  );
};
