import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import {
  ElectionsLabels,
  returnOptionalBooleanFromFormValue,
  setIsSBSElectionSaveEnabled,
  updateBankAccountStage,
} from "common";
import React, { useCallback, useEffect } from "react";
import { Control, Controller, FieldPath, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import { DirectDebitRequirementState } from "../BankAccountStage";

interface IDirectDebitRequirementFormProps {
  form: UseFormReturn<DirectDebitRequirementState>;
}

export const DirectDebitRequirementForm: React.FC<
  IDirectDebitRequirementFormProps
> = ({ form }) => {
  const dispatch = useDispatch();
  const handleChange = () => {
    dispatch(setIsSBSElectionSaveEnabled(true));
  };

  const validate = (
    _: string | null,
    value: DirectDebitRequirementState
  ): boolean | string => {
    if (value.hasUSBankAccount === null) {
      return "";
    } else {
      return true;
    }
  };

  const HasUSBankAccountQuestion = ({
    title,
    name,
    control,
  }: {
    title: string;
    name: FieldPath<DirectDebitRequirementState>;
    control: Control<DirectDebitRequirementState>;
  }) => {
    return (
      <FormControl>
        <FormLabel id={name + "-label"}>
          <span>{title}</span>
        </FormLabel>
        <Controller
          control={control}
          name={name}
          rules={{ validate: validate }}
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby={name + "-label"}>
              <FormControlLabel
                value={true}
                control={<Radio />}
                label={ElectionsLabels.YES}
                onChange={handleChange}
              />
              <FormControlLabel
                value={false}
                control={<Radio />}
                label={ElectionsLabels.NO}
                onChange={handleChange}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    );
  };

  const hasUSBankAccount: string | null = form.watch("hasUSBankAccount");

  const dispatchUpdateDirectDebitRequirementFunc = useCallback(
    (directDebitRequirementValue: DirectDebitRequirementState) => {
      dispatch(
        updateBankAccountStage({
          hasUSBankAccount: returnOptionalBooleanFromFormValue(
            directDebitRequirementValue.hasUSBankAccount
          ),
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchUpdateDirectDebitRequirementFunc({ hasUSBankAccount });
  }, [hasUSBankAccount, dispatchUpdateDirectDebitRequirementFunc]);

  return (
    <Stack
      id="questionsForm"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
    >
      <HasUSBankAccountQuestion
        title={ElectionsLabels.DO_YOU_HAVE_US_BANK_ACCOUNT}
        name="hasUSBankAccount"
        control={form.control}
      />
    </Stack>
  );
};
