import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

import styles from "./ElectionDialog.module.scss";

export interface IElectionDialogProps {
  id?: string;
  title: string;
  content: JSX.Element;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleNext: () => void;
  handleClose?: () => void;
  nextButtonLabel?: string;
  cancelButtonLabel?: string;
}

export const ElectionDialog = (props: IElectionDialogProps) => {
  const {
    id,
    content,
    handleNext,
    handleClose,
    nextButtonLabel = ElectionsLabels.I_AGREE,
    cancelButtonLabel = ElectionsLabels.CANCEL,
    open,
    setOpen,
    title,
  } = props;

  const onClose = () => {
    if (handleClose) {
      handleClose();
    }
    setOpen(false);
  };

  return (
    <Dialog id={id ?? ""} open={open} className={styles.dialog}>
      <DialogTitle className={styles.title}>
        {title}
        <IconButton className={styles.close} onClick={onClose}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent className={styles.dialogContentWrapper}>
        <div className={styles.dialogContent}>{content}</div>
        <Divider className={styles.divider} />
        <div className={styles.dialogFooter}>
          <Button color="secondary" onClick={onClose}>
            {cancelButtonLabel}
          </Button>
          <Button className={styles.agree} onClick={handleNext}>
            {nextButtonLabel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
