import {
  isInProgress,
  isSomething,
  LoadingIndicator,
  NoPermissionPage,
  RelativePath,
  RequireAuthentication,
  selectActiveInternalInvestmentDataClientId,
  useFetchEntitlements,
} from "common";
import React from "react";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { PageName, Pages } from "../../../constants/Pages/Pages";
import { Login } from "../../../features/Authentication/Login";
import { InvestorUIStore } from "../../../redux/store";
import {
  checkCanViewCommitmentsPage,
  isPageVisible,
} from "../../utils/routingUtils";
import { UIErrorBoundary } from "../UIErrorBoundary/UIErrorBoundary";
import styles from "./RouterProvider.module.scss";

export const RouterProvider = () => {
  const auth = useAuth();

  const location = useLocation();
  //Get the value of activeInternalInvestmentDataClientId, activeEquityEntitlement, isViewEquityEntitlementLoaded and isViewInvestorEntitlementsLoaded
  const {
    viewEquityEntitlements,
    hasViewCommitmentsEntitlement,
    hasViewDocumentsEntitlement,
    bankAccountsEntitlementsLoadStatus,
    equityDataEntitlementLoadStatus,
    internalInvestmentEntitlementLoadStatus,
    commitmentsEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    permittedElectionClients,
  } = useSelector((state: InvestorUIStore) => state.entitlements);

  const activeInternalInvestmentDataClientId = useSelector(
    selectActiveInternalInvestmentDataClientId
  );

  const pageLoadStatus = useSelector(
    (state: InvestorUIStore) => state.viewData.pageLoadStatus
  );

  const internalInvestmentData = useSelector(
    (state: InvestorUIStore) => state.internalInvestmentData
  );

  const canViewCommitmentsPage = checkCanViewCommitmentsPage(
    internalInvestmentData,
    activeInternalInvestmentDataClientId,
    hasViewCommitmentsEntitlement
  );

  useFetchEntitlements(auth.user, [
    internalInvestmentEntitlementLoadStatus,
    equityDataEntitlementLoadStatus,
    commitmentsEntitlementLoadStatus,
    documentsEntitlementLoadStatus,
    electionsEntitlementLoadStatus,
    bankAccountsEntitlementsLoadStatus,
  ]);

  /*
  Set Dashboard Page if user have internal investment data entitlements.
  Set Equity Page if user have equity data entitlements.
  Set NoPermission Page if user have neither. 
  */

  const SetRouteByEntitlements = () => {
    if (
      isInProgress(
        internalInvestmentEntitlementLoadStatus,
        equityDataEntitlementLoadStatus,
        commitmentsEntitlementLoadStatus,
        documentsEntitlementLoadStatus,
        electionsEntitlementLoadStatus
      )
    ) {
      return (
        <div className={styles.loadingIndicator}>
          <LoadingIndicator />
        </div>
      );
    }
    const urlPath = location.pathname;
    const urlPathLower = urlPath.toLowerCase();
    if (
      urlPath !== urlPathLower &&
      Object.keys(Pages)
        .map((object) => Pages[object as PageName].path)
        .some((item) => item === urlPathLower)
    ) {
      /*
      if url string contains uppercase and corresponds to a valid page when made lowercase, navigate to the lowercase route.
        This forces all urls to automatically become lowercase, even if typed in capitals
      */
      return <Navigate to={urlPathLower} />;
    }
    return isSomething(activeInternalInvestmentDataClientId) ? (
      <Navigate to={Pages.Dashboard.path} />
    ) : viewEquityEntitlements.length > 0 ? (
      <Navigate to={Pages.Equity.path} />
    ) : canViewCommitmentsPage ? (
      <Navigate to={Pages.Commitments.path} />
    ) : hasViewDocumentsEntitlement ? (
      <Navigate to={Pages.Documents.path} />
    ) : permittedElectionClients.length > 0 ? (
      <Navigate to={Pages.ElectionsList.path} />
    ) : (
      <NoPermissionPage />
    );
  };

  return (
    <Routes>
      <Route path={RelativePath.LOGIN} element={<Login />} />
      {Object.keys(Pages).map((pageKey) => {
        if (
          isPageVisible(
            pageKey as PageName,
            activeInternalInvestmentDataClientId,
            viewEquityEntitlements,
            hasViewDocumentsEntitlement,
            canViewCommitmentsPage,
            permittedElectionClients
          )
        ) {
          const page = Pages[pageKey as PageName];
          return (
            <Route
              key={page.path}
              path={page.path}
              caseSensitive
              element={
                <div className={styles.page}>
                  <UIErrorBoundary page={page}>
                    <RequireAuthentication
                      pageElement={page.element}
                      auth={auth}
                      pageLoadStatus={pageLoadStatus}
                    />
                  </UIErrorBoundary>
                </div>
              }
            />
          );
        }
      })}
      <Route path={"*"} element={SetRouteByEntitlements()} />
    </Routes>
  );
};
