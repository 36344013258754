import { Text, View } from "@react-pdf/renderer";
import { BrowserLabels, DisclosureLabels } from "common";
import React from "react";

import { styles } from "../ElectionAgreementDocumentStyleSheet";

export const DisclosurePage = () => {
  const textLines = [
    DisclosureLabels.TERMS_LINE_1,
    DisclosureLabels.TERMS_LINE_2,
    DisclosureLabels.TERMS_LINE_3,
    DisclosureLabels.TERMS_LINE_4,
    DisclosureLabels.TERMS_LINE_5,
    BrowserLabels.CHROME,
    BrowserLabels.EDGE,
    BrowserLabels.FIREFOX,
    BrowserLabels.SAFARI,
    DisclosureLabels.CONTACT_INFORMATION,
  ];
  return (
    <>
      <View break>
        <Text style={styles.title}>{DisclosureLabels.E_SIGN_DISCLOSURES}</Text>
      </View>
      {textLines.map((textLine: string, index: number) => (
        <View key={index}>
          <Text style={styles.paragraph}>{textLine}</Text>
        </View>
      ))}
    </>
  );
};
