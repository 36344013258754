import { usePDF } from "@react-pdf/renderer";
import {
  ErrorStringConstants,
  getElectCardData,
  getSubmission,
  mapElectionRoundConfigurationToModel,
  openAlert,
  parseJsonToSubmissionResponse,
} from "common";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { ElectionAgreementDocument } from "../features/Elections/Shared/ElectionAgreementDocument/ElectionAgreementDocument";

export const useDownloadElectionAgreement = () => {
  const fileName = "election-agreement.pdf";
  const dispatch = useDispatch();
  const [instance, setInstance] = usePDF({});
  const [isRequestingData, setIsRequestingData] = useState(false);

  useEffect(() => {
    if (isRequestingData && instance.url) {
      if (instance.error) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
        setIsRequestingData(false);
        return;
      }

      const link = document.createElement("a");
      link.href = instance.url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      setIsRequestingData(false);
    }
  }, [dispatch, instance.error, instance.url, isRequestingData]);

  const downloadPDF = useCallback(
    async (electionRoundId: string, investmentVehicleId: number) => {
      setIsRequestingData(true);

      const jsonResponse = await getSubmission(
        electionRoundId,
        investmentVehicleId
      );

      if (jsonResponse === undefined) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
        setIsRequestingData(false);
        return;
      }

      const submissionResponse = parseJsonToSubmissionResponse(jsonResponse);
      const electionRoundConfigurationModel =
        mapElectionRoundConfigurationToModel(
          submissionResponse.electionRoundConfiguration
        );

      const electionAmount = getElectCardData(
        electionRoundConfigurationModel,
        submissionResponse.electionIVConfiguration,
        submissionResponse.savedWorkflowStateResponse
      );

      setInstance(
        ElectionAgreementDocument({
          workflowState: submissionResponse.savedWorkflowStateResponse,
          roundConfig: submissionResponse.electionRoundConfiguration,
          ivConfig: submissionResponse.electionIVConfiguration,
          electionAmount: electionAmount,
        })
      );
    },
    [dispatch, setInstance]
  );

  return downloadPDF;
};
