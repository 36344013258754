import {
  BankAccountsResponse,
  ICountry,
  ICurrency,
} from "../types/bankAccountDataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleResponse } from "./utils";

export type BankAccountsResponseDto = Json<BankAccountsResponse>;
export type ICountryResponseDto = Json<ICountry>;
export type ICurrencyResponseDto = Json<ICurrency>;

export const getBankAccountsForIV = async (
  investmentVehicleAxiomId: number
): Promise<Maybe<BankAccountsResponseDto>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE +
    `/gp-bank-accounts/investment-vehicles/${investmentVehicleAxiomId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountCountries = async (): Promise<
  Maybe<ICountryResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/country`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};

export const getBankAccountCurrencies = async (): Promise<
  Maybe<ICurrencyResponseDto[]>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_BANK_ACCOUNTS_URL_BASE + `/currency`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
    },
  });

  return await handleResponse(response);
};
