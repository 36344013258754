import {
  AccountIntermediaryType,
  AccountNumberType,
  AccountType,
  addBankAccount,
  BankAccountAssignment,
  BankAccountConstants,
  BankIdType,
  hasPendingContribution,
  hasPendingDistribution,
  IBaseStore,
  IntermediaryBankAccount,
  isSomething,
  nothing,
  openAlert,
  PendingBankAccountDetail,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
  selectHasUSBankAccount,
  UserAddedBankAccount,
} from "common";
import React, { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import { ElectionDialog } from "../../Elections/ElectionWorkflow/Shared/ElectionDialog/ElectionDialog";
import { AddBankAccountForm } from "./AddBankAccountForm/AddBankAccountForm";

export type AddBankAccountState = PendingBankAccountDetail & {
  confirmAccountId: string;
  hasIntermediaryAccount: boolean;
  intermediaryAccount: IntermediaryBankAccount & {
    confirmAccountId: string;
  };
};

export interface AddBankAccountFormState {
  addBankAccount: AddBankAccountState;
}

export interface IAddBankAccountDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isContribution: boolean;
}

export const AddBankAccountDialog = (props: IAddBankAccountDialogProps) => {
  const { open, setOpen, isContribution } = props;

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const usCountryName = useMemo(() => {
    const usCountry = bankAccountCountries.find(
      (country) => country.id === BankAccountConstants.US_COUNTRY_ID_NUMBER
    );
    return usCountry ? usCountry.name : "";
  }, [bankAccountCountries]);

  const usCurrencyCode = useMemo(() => {
    const usCurrency =
      bankAccountCurrencies.find(
        (currency) =>
          currency.id === BankAccountConstants.USD_CURRENCY_ID_NUMBER
      ) ?? "";
    return usCurrency ? usCurrency.currencyCode : "";
  }, [bankAccountCurrencies]);

  const DEFAULT_ADD_ACCOUNT_VALUES: AddBankAccountFormState = useMemo(() => {
    return {
      addBankAccount: {
        accountHolderName: "",
        accountType: AccountType.CHECKING,
        country: usCountryName,
        currency: usCurrencyCode,
        accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
        accountNumber: "",
        bankIdType: BankIdType.ABA_ROUTING_NUMBER,
        bankId: "",
        confirmAccountId: "",
        bankName: "",
        hasIntermediaryAccount: false,
        intermediaryAccount: {
          accountHolderName: "",
          accountType: AccountIntermediaryType.INTERMEDIARY,
          accountNumberType: AccountNumberType.ACCOUNT_NUMBER,
          accountNumber: "",
          bankIdType: BankIdType.ABA_ROUTING_NUMBER,
          bankId: "",
          confirmAccountId: "",
          bankName: "",
        },
      },
    };
  }, [usCountryName, usCurrencyCode]);

  const addBankAccountForm = useForm<AddBankAccountFormState>({
    mode: "onChange",
    defaultValues: DEFAULT_ADD_ACCOUNT_VALUES,
  });

  // needed to ensure default values are set to country and currency properly
  useEffect(() => {
    addBankAccountForm.reset(DEFAULT_ADD_ACCOUNT_VALUES);
  }, [DEFAULT_ADD_ACCOUNT_VALUES, addBankAccountForm]);

  const dispatch = useDispatch();

  const hasUSBankAccount = useSelector((state: InvestorUIStore) =>
    selectHasUSBankAccount(state)
  );

  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);

  /*
  helper function to determin if an added account is eligible for both assignments, according
    to the following rules:

    - If adding Distributions account and 
      - New Account is eligible for Contributions(is US checking account) and
      - Contributions account is required(selected yes on has US bank account question) and
      - Contributions account is not under treasury review(no pending contribution accounts)
    - If adding Contributions account and
      - Distributions account is not under treasury review(no pending distribution accounts)
  */
  const getIsEligibleForBothAssignments = useCallback(
    (userAddedAccount: AddBankAccountState) => {
      if (isContribution && !hasPendingDistributionAccount) {
        return true;
      }
      if (!isContribution) {
        const isEligibleForContribution =
          userAddedAccount.accountType === AccountType.CHECKING &&
          userAddedAccount.country === usCountryName &&
          userAddedAccount.currency === usCurrencyCode;

        return (
          isEligibleForContribution &&
          !hasPendingContributionAccount &&
          isSomething(hasUSBankAccount) &&
          hasUSBankAccount.value
        );
      }
      return false;
    },
    [
      isContribution,
      hasUSBankAccount,
      hasPendingContributionAccount,
      hasPendingDistributionAccount,
      usCountryName,
      usCurrencyCode,
    ]
  );

  const handleClose = useCallback(() => {
    addBankAccountForm.reset();
  }, [addBankAccountForm]);

  const handleContinue = useCallback(() => {
    // TODO: add validations
    const convertAddedAccount = (
      userAddedAccount: AddBankAccountState,
      isElgibleForBothAssignments: boolean
    ): UserAddedBankAccount => {
      return {
        main: userAddedAccount,
        intermediary: userAddedAccount.hasIntermediaryAccount
          ? userAddedAccount.intermediaryAccount
          : nothing,
        assignment: isContribution
          ? BankAccountAssignment.CONTRIBUTION
          : BankAccountAssignment.DISTRIBUTION,
        eligibleForBothAssignments: isElgibleForBothAssignments,
      };
    };

    const addedBankAccount: AddBankAccountState =
      addBankAccountForm.getValues().addBankAccount;
    const isElgibleForBothAssignments =
      getIsEligibleForBothAssignments(addedBankAccount);
    const convertPendingAccount = convertAddedAccount(
      addedBankAccount,
      isElgibleForBothAssignments
    );
    dispatch(addBankAccount(convertPendingAccount));
    setOpen(false);
    addBankAccountForm.reset(DEFAULT_ADD_ACCOUNT_VALUES);
    dispatch(
      openAlert({
        severity: "success",
        message: isElgibleForBothAssignments
          ? BankAccountConstants.BANK_ACCOUNT_ADDED_BOTH_PURPOSES_MESSAGE
          : BankAccountConstants.BANK_ACCOUNT_ADDED_MESSAGE,
      })
    );
  }, [
    dispatch,
    addBankAccountForm,
    isContribution,
    setOpen,
    DEFAULT_ADD_ACCOUNT_VALUES,
    getIsEligibleForBothAssignments,
  ]);

  return (
    <ElectionDialog
      title={BankAccountConstants.ADD_NEW_BANK_ACCOUNT}
      content={
        <AddBankAccountForm
          addBankAccountForm={addBankAccountForm}
          isContribution={isContribution}
        />
      }
      open={open}
      setOpen={setOpen}
      handleClose={handleClose}
      handleNext={handleContinue}
      nextButtonLabel={"Save"}
    />
  );
};
