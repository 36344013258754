import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { get } from "common";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import {
  AddBankAccountFormState,
  AddBankAccountState,
} from "../../AddBankAccountDialog";
import FieldLabelWithTooltip from "../FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./DropdownInput.module.scss";

export interface IDropdownInputProps {
  field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>;
  attributeName: string;
  label: string;
  options: string[];
  disabled: boolean;
  customOnChange?: (
    field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>,
    value: string
  ) => void;
  tooltip?: string | React.ReactNode;
  prefix?: string;
}

export const DropdownInput = (props: IDropdownInputProps) => {
  const {
    field,
    attributeName,
    options,
    disabled,
    customOnChange,
    prefix = "",
  } = props;

  const onChange = (
    field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>,
    name: string
  ) => {
    return (event: SelectChangeEvent<string>) => {
      // TODO: we might want to use immer in order to modify nested objects more easily
      if (customOnChange) {
        customOnChange(field, event.target.value ?? "");
      } else {
        const originalValue: AddBankAccountState = field.value;
        const getUpdatedState = (): AddBankAccountState => {
          if (prefix.length !== 0) {
            const nestedChild = originalValue[
              prefix as keyof typeof originalValue
            ] as Record<string, unknown>;
            return {
              ...originalValue,
              [prefix]: {
                ...nestedChild,
                [name]: event.target.value,
              },
            };
          } else {
            return {
              ...originalValue,
              [name]: event.target.value,
            };
          }
        };
        field.onChange(getUpdatedState());
      }
    };
  };

  return (
    <Stack className={styles.stack}>
      <FieldLabelWithTooltip {...props} />
      <FormControl>
        <Select
          disabled={disabled}
          variant={"outlined"}
          value={get(
            field.value as unknown as Record<string, unknown>,
            [prefix, attributeName].join(".")
          )}
          onChange={onChange(field, attributeName)}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            sx: {
              maxHeight: 300,
              maxWidth: 200,
            },
          }}
        >
          {options.map((value: string, index: number) => {
            return (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
};
