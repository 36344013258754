import { Tooltip, Typography } from "@mui/material";
import React from "react";

import {
  formatDateAndTimeMMMDDYYYY,
  formatDateMMMDDYYYYOrNull,
} from "../../utils/formatters";

interface DayOnlyDateWithDatetimeTooltipProps {
  date: Date;
}

export const DayOnlyDateWithDatetimeTooltip = (
  props: DayOnlyDateWithDatetimeTooltipProps
) => {
  return (
    <Tooltip
      title={formatDateAndTimeMMMDDYYYY(props.date)}
      placement="bottom-end"
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -15],
            },
          },
        ],
      }}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
    >
      <Typography>{formatDateMMMDDYYYYOrNull(props.date)}</Typography>
    </Tooltip>
  );
};
