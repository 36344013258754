import { FileDownloadOutlined } from "@mui/icons-material";
import { CardHeader, Typography } from "@mui/material";
import {
  IconButtonWithTooltip,
  StringConstants,
  UnrealizedValueLabels,
  UnrealizedValues,
  useExcelDownload,
  worksheetGenerators,
} from "common";
import React from "react";

import styles from "./TitleWithDownloadButton.module.scss";

interface ITitleWitDownloadButtonProps {
  sectionHeader: string;
  sectionSubHeader?: string | undefined;
  data: UnrealizedValues;
}

export const TitleWithDownloadButton = (
  props: ITitleWitDownloadButtonProps
) => {
  const handleExcelDownload = useExcelDownload();
  const handleDownloadClick = async () => {
    const generator = worksheetGenerators.UnrealizedValues;
    const fileName = UnrealizedValueLabels.UNREALIZED_VALUE;
    await handleExcelDownload(generator, fileName);
  };

  return (
    <div className={styles.headerWithButton}>
      <CardHeader
        className={styles.header}
        title={
          <Typography className={styles.title} variant="h3">
            {props.sectionHeader}
          </Typography>
        }
        subheader={
          <Typography className={styles.subTitle} variant="subtitle1">
            {props.sectionSubHeader}
          </Typography>
        }
        action={
          <IconButtonWithTooltip
            buttonIcon={FileDownloadOutlined}
            hoverText={StringConstants.DOWNLOAD}
            onClickFunction={() => handleDownloadClick()}
          />
        }
      />
    </div>
  );
};
