export const BankAccountConstants = {
  ADD_ACCOUNT: "Add Account",
  AUTHORIZATION:
    "I hereby authorize Blackstone, hereinafter called COMPANY, to initiate debit entries to my account indicated below at the depository financial institution named below, hereafter called DEPOSITORY, and to credit the same to such account.",
  NO_ACCOUNT_SELECTED: "No Account Selected",
  SUBMIT_CHANGES: "Submit Changes",
  UNDER_REVIEW_WARNING:
    'Your bank details are under review by Treasury. In order to finalize your election, you must click "Next" to proceed to the next stage.',
  ACCOUNT_HOLDER: "Account Holder",
  ACCOUNT_HOLDER_PLACEHOLDER: "Enter account holder",
  ACCOUNT_TYPE: "Account Type",
  ACCOUNT_ID_TYPE: "Account ID Type",
  ACCOUNT_ID_NUMBER: "Account ID #",
  ACCOUNT_ID_NUMBER_PLACEHOLDER: "Enter account ID",
  CONFIRM_ACCOUNT_ID: "Confirm Account ID #",
  COUNTRY: "Country",
  CURRENCY: "Currency",
  BANK_ID_TYPE: "Bank ID Type",
  BANK_ID_NUMBER: "Bank ID #",
  BANK_ID_NUMBER_PLACEHOLDER: "Enter bank ID",
  BANK_NAME: "Bank Name",
  ENTER_BANK_NAME: "Enter Bank Name",
  US_COUNTRY_ID_NUMBER: 236,
  USD_CURRENCY_ID_NUMBER: 1,
  ADD_NEW_BANK_ACCOUNT: "Add New Bank Account",
  THIRD_PARTY_FINANCING_WARNING:
    "If you plan on using third-party financing and want to use this loan bank account for contributions and/or distributions, you may not have the details until after the election deadline. Please use an existing account to assign as a contribution and/or distribution account to proceed with your election. Once your loan bank account details are available, you can update this account information on the Bank Accounts page.\n",
  BANK_ACCOUNT_ADDED_BOTH_PURPOSES_MESSAGE:
    "Bank account successfully added and available under both purposes.",
  BANK_ACCOUNT_ADDED_MESSAGE: "Bank Account successfully added.",
  SUBMIT_CHANGES_PROMPT:
    "You have unsaved changes. To ensure you don’t lose progress, submit changes before continuing on.",
  LINK_ACCOUNT:
    "Link intermediary or correspondent bank account information to this account",
  UNLINK_ACCOUNT:
    "Remove linked intermediary or correspondent bank account information from this account",
};

export const BankAccountTooltips = {
  ACCOUNT_HOLDER:
    "For AML compliance purposes the account must be in the name of the investor. The use of Trust accounts and LLC’s is not permitted.",
  ACCOUNT_TYPE:
    "Checking accounts can be assigned as both contribution and/or distribution accounts. Savings accounts cannot be assigned as contribution debit accounts.",
  INTERMEDIARY_ACCOUNT_TYPE:
    "An Intermediary account acts as a bridge between the originating and beneficiary banks, facilitating the transfer of funds across different countries or currencies. A Correspondent account is maintained by a domestic bank to handle foreign transactions on behalf of another bank. Please contact your bank if you are uncertain if your account should have an intermediary or correspondent.",
};
