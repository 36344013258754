import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import {
  buildIVByElectionRoundTitle,
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  ElectionStepperButton,
  ElectionWorkflowStageId,
  ElectionWorkflowStageOrder,
  FailedToLoadError,
  getBannerAccordionIdFromWorkflowStageId,
  getElectionStepperButtonClass,
  getElectionStepperButtonsStates,
  IElectionWorkflowState,
  IGetElectionDetailsRequestPayload,
  isElectionStepperButtonDisabled,
  isInProgress,
  isSomething,
  IStageConfiguration,
  isUnsuccessful,
  LoadingIndicator,
  NoDataAvailableError,
  openAlert,
  Optional,
  reqElectionIVConfiguration,
  reqElectionRoundConfigurationData,
  reqElectionsInvestmentPortfolio,
  reqElectionWorkflowState,
  reqPutElectionWorkflowState,
  requestStageValidation,
  selectActiveElectionClientId,
  setActiveElection,
  setActiveElectionClient,
  setIsSBSElectionSaveEnabled,
  some,
  updateWorkflowStageOrder,
  useFetchDatasetIfIdDefined,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import {
  ElectionWorkflowPages,
  IElectionWorkflowPage,
} from "../../../constants/Pages/ElectionWorkflowPages";
import {
  selectCurrentStageConfiguration,
  selectCurrentStageId,
  selectElectionRoundDocuments,
} from "../../../redux/selectors";
import { selectCanReadBankAccountsForActiveClient } from "../../../redux/selectors/entitlementSelectors";
import { InvestorUIStore } from "../../../redux/store";
import { BackToElectionsButton } from "../Shared/BackToElectionsButton/BackToElectionsButton";
import { DownloadDocumentButton } from "../Shared/DownloadDocumentButton/DownloadDocumentButton";
import styles from "./ElectionWorkflowPage.module.scss";
import { StageBanner } from "./Shared/StageBanner/StageBanner";
import { StageFootnote } from "./Shared/StageFootnote/StageFootnote";
import { StepIconComponent } from "./Shared/StepIconComponent/StepIconComponent";
import { UnsavedChangesModal } from "./Shared/UnsavedChangesModal/UnsavedChangesModal";

type ElectionStepperButtonsProps = {
  activeStageId: ElectionWorkflowStageId;
  isSBSElectionSaveEnabled: boolean;
  permittedElectionWorkflowStagesOrder: ElectionWorkflowStageId[];
  handlePreviousStageNavigation: (previousStageId: number) => void;
  activeStage?: IElectionWorkflowPage;
  activeStageIndex: number;
};
const ElectionStepperButtons = ({
  activeStageId,
  isSBSElectionSaveEnabled,
  permittedElectionWorkflowStagesOrder,
  handlePreviousStageNavigation,
  activeStage,
  activeStageIndex,
}: ElectionStepperButtonsProps) => {
  const backButtonRef = useRef<HTMLButtonElement>(null);
  const nextButtonRef = useRef<HTMLButtonElement>(null);
  const dispatch = useDispatch();
  const { electionWorkflowStateLocal } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  if (typeof activeStageId === "undefined") return null;
  const buttonStates = getElectionStepperButtonsStates(activeStageId);

  const handleClickSave = () => {
    if (isSomething(electionWorkflowStateLocal)) {
      dispatch(
        reqPutElectionWorkflowState({
          investmentVehicleId:
            electionWorkflowStateLocal.value.investmentVehicleId,
          electionRoundId: electionWorkflowStateLocal.value.electionRoundId,
          targetState: convertElectionWorkflowStateToUpdateSource(
            electionWorkflowStateLocal.value
          ),
        })
      );
    }
    dispatch(setIsSBSElectionSaveEnabled(false));
  };

  const handleClickBack = (activeStageIndex: number) => {
    const previousStageId =
      permittedElectionWorkflowStagesOrder[activeStageIndex - 1];
    handlePreviousStageNavigation(previousStageId);
    if (backButtonRef.current !== null) {
      backButtonRef.current.blur();
    }
  };

  const handleClickNext = () => {
    if (nextButtonRef.current !== null) {
      nextButtonRef.current.blur();
    }
    dispatch(requestStageValidation());
  };

  return (
    <>
      <Button
        color="secondary"
        variant="outlined"
        disabled={
          !isSBSElectionSaveEnabled ||
          isElectionStepperButtonDisabled(
            buttonStates[ElectionStepperButton.SAVE]
          )
        }
        onClick={handleClickSave}
        className={getElectionStepperButtonClass(
          styles,
          buttonStates[ElectionStepperButton.SAVE]
        )}
      >
        {ElectionsLabels.SAVE}
      </Button>
      <Button
        id={styles.backButton}
        color="secondary"
        variant="outlined"
        disabled={isElectionStepperButtonDisabled(
          buttonStates[ElectionStepperButton.BACK]
        )}
        onClick={() => handleClickBack(activeStageIndex)}
        ref={backButtonRef}
        className={getElectionStepperButtonClass(
          styles,
          buttonStates[ElectionStepperButton.BACK]
        )}
      >
        {ElectionsLabels.BACK}
      </Button>
      <Button
        ref={nextButtonRef}
        onClick={handleClickNext}
        disabled={isElectionStepperButtonDisabled(
          buttonStates[ElectionStepperButton.NEXT]
        )}
        endIcon={<ArrowForwardIcon />}
        className={getElectionStepperButtonClass(
          styles,
          buttonStates[ElectionStepperButton.NEXT]
        )}
      >
        {activeStage?.nextButtonTextOverride ?? ElectionsLabels.NEXT}
      </Button>
    </>
  );
};

export const ElectionWorkflowPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [
    permittedElectionWorkflowStagesOrder,
    setPermittedElectionWorkflowStagesOrder,
  ] = useState<ElectionWorkflowStageId[]>(ElectionWorkflowStageOrder);

  const { electionRoundId, investmentVehicleId: investmentVehicleIdParam } =
    useParams();

  const activeElectionClientId = useSelector(selectActiveElectionClientId);

  const { permittedElectionClients } = useSelector(
    (state: InvestorUIStore) => state.entitlements
  );

  const investmentVehicleId = useMemo(() => {
    if (
      investmentVehicleIdParam &&
      !isNaN(parseInt(investmentVehicleIdParam))
    ) {
      return parseInt(investmentVehicleIdParam);
    }
  }, [investmentVehicleIdParam]);

  // This catches cases where we've refreshed the page or otherwise navigated
  // to the workflow without passing through the Elections List. We want to set
  // the active election client to whatever client corresponds to the current
  // workflow.
  useEffect(() => {
    const clientForCurrentElection = permittedElectionClients.find((client) => {
      return client.investmentVehicles.some(
        (iv) => iv.investmentVehicleId === investmentVehicleId
      );
    });
    if (
      clientForCurrentElection &&
      (!isSomething(activeElectionClientId) ||
        clientForCurrentElection.clientId !== activeElectionClientId.value)
    ) {
      dispatch(setActiveElectionClient(some(clientForCurrentElection)));
    }
  }, [
    activeElectionClientId,
    dispatch,
    investmentVehicleId,
    permittedElectionClients,
  ]);

  const canReadBankAccounts = useSelector(
    selectCanReadBankAccountsForActiveClient
  );

  const {
    electionRoundConfigurationLoadStatus,
    electionRoundConfiguration,
    electionWorkflowStateLoadStatus,
    electionWorkflowStateUpdateStatus,
    electionIVConfigurationLoadStatus,
    electionInvestmentPortfolioLoadStatus,
    activeElection,
    electionIVConfiguration,
    electionWorkflowStateLocal,
    electionWorkflowStateApi,
    submissionRequested,
  } = useSelector((state: InvestorUIStore) => state.electionsData);

  // keeps track of the user's current stage
  const currentStageId = useSelector((state: InvestorUIStore) =>
    selectCurrentStageId(state)
  );

  const activeStageId = useMemo(() => {
    if (
      currentStageId === ElectionWorkflowStageId.COMPLETED &&
      submissionRequested
    ) {
      return ElectionWorkflowStageId.REVIEW_AND_SIGN;
    }
    return currentStageId;
  }, [currentStageId, submissionRequested]);

  // keeps trak of the current stage configuration
  const currentStageConfiguration: Optional<IStageConfiguration> = useSelector(
    (state: InvestorUIStore) =>
      selectCurrentStageConfiguration(state, activeStageId)
  );

  const documents = useSelector((state: InvestorUIStore) =>
    selectElectionRoundDocuments(state)
  );

  const { isSBSElectionSaveEnabled } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  useEffect(() => {
    if (isUnsuccessful(electionWorkflowStateUpdateStatus)) {
      dispatch(
        openAlert({
          severity: "error",
          message: ElectionsLabels.UNABLE_TO_SAVE_LATEST,
          hideDuration: 5000,
        })
      );
    }
  }, [dispatch, electionWorkflowStateUpdateStatus]);

  useEffect(() => {
    const permittedWorkflowOrder: ElectionWorkflowStageId[] = [];
    ElectionWorkflowStageOrder.forEach((workflowStageId) => {
      if (
        workflowStageId === ElectionWorkflowStageId.BANK_ACCOUNT &&
        !canReadBankAccounts
      ) {
        return;
      }
      permittedWorkflowOrder.push(workflowStageId);
    });
    setPermittedElectionWorkflowStagesOrder(permittedWorkflowOrder);
    dispatch(updateWorkflowStageOrder(permittedWorkflowOrder));
  }, [canReadBankAccounts, dispatch]);

  // if the stage is completed and not just came from clicking submit, redirect to review page
  useEffect(() => {
    if (
      activeStageId === ElectionWorkflowStageId.COMPLETED &&
      !submissionRequested
    ) {
      navigate(
        `/sbs-elections/${investmentVehicleId}/${electionRoundId}/review`
      );
    }
  }, [
    activeStageId,
    submissionRequested,
    investmentVehicleId,
    electionRoundId,
    navigate,
  ]);

  const { activeStageIndex, activeStage } = useMemo(() => {
    return {
      activeStageIndex:
        permittedElectionWorkflowStagesOrder.indexOf(activeStageId),
      activeStage: ElectionWorkflowPages[activeStageId],
    };
  }, [activeStageId, permittedElectionWorkflowStagesOrder]);

  const StageElement = activeStage?.element;

  const ivConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionIVConfiguration,
    activeElection,
    electionIVConfigurationLoadStatus
  );
  const roundConfigRetry = useFetchDatasetIfIdDefined(
    reqElectionRoundConfigurationData,
    activeElection,
    electionRoundConfigurationLoadStatus
  );
  const workflowStateRetry = useFetchDatasetIfIdDefined(
    reqElectionWorkflowState,
    activeElection,
    electionWorkflowStateLoadStatus
  );
  const portfolioRetry = useFetchDatasetIfIdDefined(
    reqElectionsInvestmentPortfolio,
    activeElectionClientId,
    electionInvestmentPortfolioLoadStatus
  );

  const combinedRetry = useCallback(() => {
    ivConfigRetry();
    roundConfigRetry();
    workflowStateRetry();
    portfolioRetry();
  }, [ivConfigRetry, portfolioRetry, roundConfigRetry, workflowStateRetry]);

  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState<boolean>(false);
  const [navBackTargetStage, setNavBackTargetStage] = useState<number>(0);

  const navigateToStage = useCallback(
    (stageId: number, electionWorkflowState: IElectionWorkflowState) => {
      dispatch(setIsSBSElectionSaveEnabled(false));
      dispatch(
        reqPutElectionWorkflowState({
          investmentVehicleId: electionWorkflowState.investmentVehicleId,
          electionRoundId: electionWorkflowState.electionRoundId,
          targetState: convertElectionWorkflowStateToUpdateSource({
            ...electionWorkflowState,
            currentStage: stageId,
          }),
        })
      );
      window.scrollTo(0, 0);
    },
    [dispatch]
  );

  const handlePreviousStageNavigation = (stageId: number) => {
    const shouldPromptUnsavedChanges =
      activeStageId !== ElectionWorkflowStageId.BANK_ACCOUNT;
    if (isSBSElectionSaveEnabled && shouldPromptUnsavedChanges) {
      setNavBackTargetStage(stageId);
      setIsUnsavedChangesModalOpen(true);
    } else {
      if (isSomething(electionWorkflowStateApi)) {
        navigateToStage(stageId, electionWorkflowStateApi.value);
      }
    }
  };

  const handleClickStageTab = (stageId: number) => {
    // if it goes to next step a form validation is needed otherwise it can go to a previous step
    if (stageId > activeStageId) {
      dispatch(requestStageValidation());
    } else {
      handlePreviousStageNavigation(stageId);
    }
  };

  useEffect(() => {
    if (
      isSomething(electionWorkflowStateApi) &&
      activeStageId === ElectionWorkflowStageId.BANK_ACCOUNT &&
      !canReadBankAccounts
    ) {
      navigateToStage(
        ElectionWorkflowStageId.ELECT,
        electionWorkflowStateApi.value
      );
    }
  }, [
    activeStageId,
    canReadBankAccounts,
    electionRoundId,
    electionWorkflowStateApi,
    navigateToStage,
  ]);

  useEffect(() => {
    if (electionRoundId && investmentVehicleId) {
      const currentElection: IGetElectionDetailsRequestPayload = {
        electionRoundId,
        investmentVehicleId,
      };

      if (
        !isSomething(activeElection) ||
        activeElection.value.electionRoundId !== electionRoundId ||
        activeElection.value.investmentVehicleId !== investmentVehicleId
      ) {
        dispatch(setActiveElection(currentElection));
      }
    }
  }, [
    dispatch,
    electionRoundId,
    investmentVehicleId,
    activeStageId,
    activeElection,
  ]);

  const displayStage: boolean = useMemo(() => {
    const anyInProgress: boolean = isInProgress(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus,
      electionInvestmentPortfolioLoadStatus
    );
    return !anyInProgress;
  }, [
    electionIVConfigurationLoadStatus,
    electionRoundConfigurationLoadStatus,
    electionWorkflowStateLoadStatus,
    electionInvestmentPortfolioLoadStatus,
  ]);

  if (
    isInProgress(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus
    )
  ) {
    return (
      <div className={styles.status}>
        <LoadingIndicator />
      </div>
    );
  }
  if (
    isUnsuccessful(
      electionIVConfigurationLoadStatus,
      electionRoundConfigurationLoadStatus,
      electionWorkflowStateLoadStatus
    )
  ) {
    return (
      <div className={styles.status}>
        <FailedToLoadError retryRequest={combinedRetry} />
      </div>
    );
  }
  if (
    Object.keys(permittedElectionClients).length === 0 ||
    !isSomething(electionIVConfiguration) ||
    !isSomething(electionRoundConfiguration) ||
    !isSomething(electionWorkflowStateApi)
  ) {
    return (
      <div className={styles.status}>
        <NoDataAvailableError />
      </div>
    );
  }

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <BackToElectionsButton checkForUnsavedChanges={true} />
        <div className={styles.title}>
          <Stack direction={"row"} alignItems={"center"} spacing={"16px"}>
            <Typography variant="h1">
              {buildIVByElectionRoundTitle(
                electionIVConfiguration,
                electionRoundConfiguration
              )}
            </Typography>
            {isSomething(activeElection) && (
              <DownloadDocumentButton
                outline={true}
                documents={documents}
                electionRoundId={activeElection.value.electionRoundId}
              />
            )}
          </Stack>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"flex-end"}
            spacing={"16px"}
          >
            <ElectionStepperButtons
              handlePreviousStageNavigation={handlePreviousStageNavigation}
              activeStageIndex={activeStageIndex}
              permittedElectionWorkflowStagesOrder={
                permittedElectionWorkflowStagesOrder
              }
              activeStageId={activeStageId}
              activeStage={activeStage}
              isSBSElectionSaveEnabled={isSBSElectionSaveEnabled}
            />
          </Stack>
        </div>
        <Stepper
          activeStep={activeStageId}
          className={styles.stepper}
          connector={<></>}
        >
          {permittedElectionWorkflowStagesOrder.map(
            (workflowStageId, index) => {
              const workflowPage = ElectionWorkflowPages[workflowStageId];

              const disabled =
                workflowStageId !== activeStageId &&
                !activeStage?.validStepperNavigationStages.includes(
                  workflowStageId
                );

              return (
                <Step
                  key={workflowStageId}
                  onClick={
                    disabled
                      ? undefined
                      : () => handleClickStageTab(workflowStageId)
                  }
                  active={activeStageId === workflowStageId}
                  disabled={disabled}
                  completed={index < activeStageIndex}
                >
                  <StepLabel
                    StepIconComponent={() => {
                      return (
                        // TODO: update logic for isComplete, isActive, and isDisabled once we have more data contracts
                        <StepIconComponent
                          value={index + 1} // add 1 so numbers start at 1 instead of 0
                          isComplete={index < activeStageIndex}
                          isActive={index === activeStageIndex}
                          isDisabled={disabled}
                        />
                      );
                    }}
                  >
                    {workflowPage?.name.toUpperCase()}
                  </StepLabel>
                </Step>
              );
            }
          )}
        </Stepper>
      </div>
      {isSomething(currentStageConfiguration) && displayStage && StageElement && (
        <div className={styles.pageContent}>
          <StageBanner
            accordionId={getBannerAccordionIdFromWorkflowStageId(activeStageId)}
            stageConfiguration={currentStageConfiguration.value}
          />
          <Stack>
            <StageElement />
            {!activeStage?.suppressFooter && (
              <StageFootnote
                stageConfiguration={currentStageConfiguration.value}
              />
            )}
          </Stack>
        </div>
      )}
      {isSomething(electionWorkflowStateApi) &&
        isSomething(electionWorkflowStateLocal) && (
          <UnsavedChangesModal
            open={isUnsavedChangesModalOpen}
            setOpen={setIsUnsavedChangesModalOpen}
            handleGoWithoutSave={() => {
              navigateToStage(
                navBackTargetStage,
                electionWorkflowStateApi.value
              );
            }}
            handleGoWithSave={() => {
              navigateToStage(
                navBackTargetStage,
                electionWorkflowStateLocal.value
              );
            }}
          />
        )}
    </div>
  );
};
