import { createReducer } from "@reduxjs/toolkit";
import {
  closeAlert,
  DEFAULT_ACCORDION_STATUSES,
  errPageLoad,
  isSomething,
  nothing,
  openAlert,
  recvEquityData,
  requestStageValidation,
  resetStageValidation,
  setAccordionStatus,
  setIsSBSElectionSaveEnabled,
  setPerformanceDetailsFilter,
  setSelectedStockSymbol,
  setUnacknowledgedElectStageWarnings,
  StockSymbol,
  UISupportedStocks,
} from "common";

import { InvestorUIViewDataStore } from "../../types/storetypes";

export const investorUIViewDataReducer = createReducer<InvestorUIViewDataStore>(
  {
    alert: {
      message: "",
      severity: "success",
      open: false,
    },
    isSBSElectionSaveEnabled: false,
    pageLoadStatus: 200,
    performanceDetailsFilter: undefined,
    selectedStockSymbol: StockSymbol.BX,
    electionAccordionCardStatuses: DEFAULT_ACCORDION_STATUSES,
    stageValidationRequested: false,
    unAcknowledgedElectStageWarnings: nothing,
  },
  (builder) => {
    builder.addCase(openAlert, (state, action) => {
      state.alert = {
        ...action.payload,
        open: true,
      };
    });
    builder.addCase(closeAlert, (state) => {
      state.alert.open = false;
    });
    builder.addCase(errPageLoad, (state, action) => {
      state.pageLoadStatus = action.payload;
    });
    builder.addCase(setPerformanceDetailsFilter, (state, action) => {
      state.performanceDetailsFilter = action.payload;
    });
    builder.addCase(setSelectedStockSymbol, (state, action) => {
      state.selectedStockSymbol = action.payload;
    });
    builder.addCase(recvEquityData, (state, action) => {
      const firstExistingSymbol = UISupportedStocks.find((symbol) =>
        isSomething(action.payload[symbol])
      );
      if (firstExistingSymbol !== undefined) {
        state.selectedStockSymbol = firstExistingSymbol;
      }
    });
    builder.addCase(setAccordionStatus, (state, action) => {
      state.electionAccordionCardStatuses[action.payload.accordionObject] =
        action.payload.status;
    });
    builder.addCase(requestStageValidation, (state) => {
      state.stageValidationRequested = true;
    });
    builder.addCase(resetStageValidation, (state) => {
      state.stageValidationRequested = false;
    });
    builder.addCase(setIsSBSElectionSaveEnabled, (state, action) => {
      state.isSBSElectionSaveEnabled = action.payload;
    });
    builder.addCase(setUnacknowledgedElectStageWarnings, (state, action) => {
      state.unAcknowledgedElectStageWarnings = action.payload;
    });
  }
);
