import { ElectionsLabels, FormCardHeader } from "common";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { selectElectCardTooltip } from "../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import { IElectStageFormProps } from "../ElectStage";
import { ReallocationForm } from "./ReallocationForm";

export const ReallocationCard = (props: IElectStageFormProps) => {
  const tooltip = useSelector((state: InvestorUIStore) =>
    selectElectCardTooltip(state)
  );

  const hasError = useMemo(() => {
    return props.error !== undefined;
  }, [props.error]);

  const [highlight, setHighlight] = useState<boolean>(false);

  return (
    <ElectionCard error={hasError} highlight={highlight}>
      <FormCardHeader
        title={ElectionsLabels.REALLOCATION}
        tooltip={tooltip}
        required={true}
        hasError={hasError}
      />
      <ReallocationForm control={props.control} setHighlight={setHighlight} />
    </ElectionCard>
  );
};
