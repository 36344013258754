import { Typography } from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  IBaseStore,
  reqBankAccountCountries,
  reqBankAccountCurrencies,
  useFetchDatasetWithoutId,
} from "common";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

import { ElectionCard } from "../../Shared/ElectionCard";
import { DirectDebitRequirementState } from "../BankAccountStage";
import styles from "./DirectDebitRequirementCard.module.scss";
import { DirectDebitRequirementForm } from "./DirectDebitRequirementForm";

interface IDirectDebitRequirementCardProps {
  form: UseFormReturn<DirectDebitRequirementState>;
  hasErrors: boolean;
}

export const DirectDebitRequirementCard: React.FC<
  IDirectDebitRequirementCardProps
> = ({ form, hasErrors }) => {
  const { countriesLoadStatus, currenciesLoadStatus } = useSelector(
    (state: IBaseStore) => state.bankAccounts
  );

  const retryCountries = useFetchDatasetWithoutId(
    reqBankAccountCountries,
    countriesLoadStatus
  );
  const retryCurrencies = useFetchDatasetWithoutId(
    reqBankAccountCurrencies,
    currenciesLoadStatus
  );

  return (
    <ElectionCard
      error={hasErrors}
      additionalDataLoadStatuses={[countriesLoadStatus, currenciesLoadStatus]}
      additionalRetryFunctions={[retryCountries, retryCurrencies]}
    >
      <FormCardHeader
        title={ElectionsLabels.DIRECT_DEBIT_REQUIREMENT}
        required={true}
        hasError={hasErrors}
      />
      <Typography className={styles.cardText}>
        {ElectionsLabels.DIRECT_DEBIT_CARD_TEXT}
      </Typography>

      <DirectDebitRequirementForm form={form} />
    </ElectionCard>
  );
};
