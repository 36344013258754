import LinkIcon from "@mui/icons-material/Link";
import { Box, FormControl, Grid, Link, Stack } from "@mui/material";
const AccountSampleImageSrc = "/assets/images/account-sample-image.png";

import {
  AccountNumberType,
  AccountType,
  BankAccountConstants,
  BankAccountTooltips,
  BankIdType,
  IBaseStore,
  ICountry,
  ICurrency,
  selectBankAccountCountries,
  selectBankAccountCurrencies,
} from "common";
import React, { useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  UseFormReturn,
} from "react-hook-form";
import { useSelector } from "react-redux";

import {
  AddBankAccountFormState,
  AddBankAccountState,
} from "../AddBankAccountDialog";
import styles from "./AddBankAccountForm.module.scss";
import { DropdownInput } from "./DropdownInput/DropdownInput";
import IntermediaryAccountForm from "./IntermediaryAccountForm/IntermediaryAccountForm";
import { AccountTypeRadioButtonInput } from "./RadioButtonInput/AccountTypeRadioButtonInput";
import { TextFieldInput } from "./TextFieldInput/TextFieldInput";

export interface IAddBankAccountFormProps {
  addBankAccountForm: UseFormReturn<AddBankAccountFormState>;
  isContribution: boolean;
}

const AccountTypeSampleImage = () => {
  return (
    <Box className={styles.AccountSampleImage}>
      <img src={AccountSampleImageSrc} />
    </Box>
  );
};

export const AddBankAccountForm = (props: IAddBankAccountFormProps) => {
  const [showIntermediaryAccForm, setShowIntermediaryAccForm] = useState(false);
  const { addBankAccountForm, isContribution } = props;

  const bankAccountCountries = useSelector((state: IBaseStore) =>
    selectBankAccountCountries(state)
  );

  const bankAccountCurrencies = useSelector((state: IBaseStore) =>
    selectBankAccountCurrencies(state)
  );

  const getCountryIdByName = (countryName: string): number => {
    const country: ICountry | undefined = bankAccountCountries.find(
      (country: ICountry) => country.name === countryName
    );
    return country ? country.id : -1;
  };

  const customOnCountryChange = (
    field: ControllerRenderProps<AddBankAccountFormState, "addBankAccount">,
    value: string
  ) => {
    const countryCode = getCountryIdByName(value);
    const accountNumberType: AccountNumberType =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? AccountNumberType.ACCOUNT_NUMBER
        : AccountNumberType.BBAN;
    const bankIdType: BankIdType =
      countryCode === BankAccountConstants.US_COUNTRY_ID_NUMBER
        ? BankIdType.ABA_ROUTING_NUMBER
        : BankIdType.SWIFT;
    const originalValue: AddBankAccountState = field.value;
    const updated: AddBankAccountState = {
      ...originalValue,
      country: value,
      bankIdType: bankIdType,
      accountNumberType: accountNumberType,
    };
    field.onChange(updated);
  };

  return (
    <>
      <FormControl className={styles.addBankAccountForm}>
        <Controller
          control={addBankAccountForm.control}
          name={"addBankAccount"}
          render={({ field }) => (
            <Box>
              <Grid container className={styles.FormColumnsContainer}>
                <Grid item xs={12} lg={6}>
                  <Stack className={styles.inputRow}>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <TextFieldInput
                        field={field}
                        attributeName="accountHolderName"
                        label={BankAccountConstants.ACCOUNT_HOLDER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_HOLDER_PLACEHOLDER
                        }
                        tooltip={BankAccountTooltips.ACCOUNT_HOLDER}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <AccountTypeRadioButtonInput
                        field={field}
                        disabled={isContribution}
                        label={BankAccountConstants.ACCOUNT_TYPE}
                        tooltip={BankAccountTooltips.ACCOUNT_TYPE}
                        isIntermediaryAccount={false}
                        options={[AccountType.CHECKING, AccountType.SAVINGS]}
                      />
                    </Grid>
                  </Stack>
                  <Stack className={styles.inputRow}>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <DropdownInput
                        field={field}
                        attributeName="accountNumberType"
                        label={BankAccountConstants.ACCOUNT_ID_TYPE}
                        options={Object.values(AccountNumberType)}
                        disabled={false}
                        tooltip={<AccountTypeSampleImage />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <TextFieldInput
                        field={field}
                        attributeName="accountNumber"
                        label={BankAccountConstants.ACCOUNT_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                        }
                      />
                    </Grid>
                  </Stack>
                  <Stack className={styles.inputRow}>
                    <TextFieldInput
                      field={field}
                      attributeName="confirmAccountId"
                      label={BankAccountConstants.CONFIRM_ACCOUNT_ID}
                      placeHolderText={
                        BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
                      }
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Stack className={styles.inputRow}>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <DropdownInput
                        field={field}
                        attributeName="country"
                        label={BankAccountConstants.COUNTRY}
                        options={bankAccountCountries.map(
                          (country: ICountry) => country.name
                        )}
                        customOnChange={customOnCountryChange}
                        disabled={isContribution}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <DropdownInput
                        field={field}
                        attributeName="currency"
                        label={BankAccountConstants.CURRENCY}
                        options={bankAccountCurrencies.map(
                          (currency: ICurrency) => currency.currencyCode
                        )}
                        disabled={isContribution}
                      />
                    </Grid>
                  </Stack>
                  <Stack className={styles.inputRow}>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <DropdownInput
                        field={field}
                        attributeName="bankIdType"
                        label={BankAccountConstants.BANK_ID_TYPE}
                        options={Object.values(BankIdType)}
                        disabled={false}
                        tooltip={<AccountTypeSampleImage />}
                      />
                    </Grid>
                    <Grid item xs={12} md={6} className={styles.rowItems}>
                      <TextFieldInput
                        field={field}
                        attributeName="bankId"
                        label={BankAccountConstants.BANK_ID_NUMBER}
                        placeHolderText={
                          BankAccountConstants.BANK_ID_NUMBER_PLACEHOLDER
                        }
                      />
                    </Grid>
                  </Stack>
                  <Stack className={styles.inputRow}>
                    <TextFieldInput
                      field={field}
                      attributeName="bankName"
                      label={BankAccountConstants.BANK_NAME}
                      placeHolderText={BankAccountConstants.ENTER_BANK_NAME}
                    />
                  </Stack>
                </Grid>
              </Grid>
              <Stack direction="row" className={styles.LinkAccountsContainer}>
                {isContribution ? null : (
                  <Link
                    className={styles.link}
                    component="button"
                    onClick={() => {
                      setShowIntermediaryAccForm((prevState) => {
                        const originalValue: AddBankAccountState = field.value;
                        field.onChange({
                          ...originalValue,
                          hasIntermediaryAccount: !prevState,
                        });
                        return !prevState;
                      });
                    }}
                  >
                    <LinkIcon />
                    {showIntermediaryAccForm
                      ? BankAccountConstants.UNLINK_ACCOUNT
                      : BankAccountConstants.LINK_ACCOUNT}
                  </Link>
                )}
              </Stack>
              <IntermediaryAccountForm
                isCollapsed={!showIntermediaryAccForm}
                field={field}
              />
            </Box>
          )}
        />
      </FormControl>
    </>
  );
};
