import {
  EquityHeader,
  EquityTabsDisplayOrder,
  InvestmentsTabsDisplayOrder,
  IPage,
  Page,
  PageWithSubTabs,
  PathFragment,
  SectionHeader,
  StringConstants,
} from "common";
import React from "react";

import InvestmentEntityDropdownWrapper from "../../common/components/InvestmentEntityDropdownWrapper/InvestmentEntityDropdownWrapper";
import CommitmentsWrapper from "../../features/Commitments/CommitmentsWrapper";
import DashboardWrapper from "../../features/Dashboard/DashboardWrapper";
import { Documents } from "../../features/Documents/Documents";
import { ElectionsList } from "../../features/Elections/ElectionsList/ElectionsList";
import { Review } from "../../features/Elections/ElectionsReview/ElectionsReview";
import { ElectionWorkflowPage } from "../../features/Elections/ElectionWorkflow/ElectionWorkflowPage";
import { EquityPages, InvestmentsPages } from "./SubTabPages";

export enum PageName {
  DASHBOARD = "Dashboard",
  INVESTMENTS_AND_CARRY = "InvestmentsandCarry",
  COMMITMENTS = "Commitments",
  EQUITY = "Equity",
  DOCUMENTS = "Documents",
  ELECTIONS_LIST = "ElectionsList",
  ELECTION_WORKFLOW = "ElectionWorkflow",
  REVIEW = "Review",
}

type Pages = {
  [key in PageName]: IPage;
};

export const Pages: Pages = {
  Dashboard: {
    name: SectionHeader.DASHBOARD,
    path: `/${PathFragment.DASHBOARD}`,
    hasError: false,
    element: DashboardWrapper,
    rootPath: `/${PathFragment.DASHBOARD}`,
  },
  Equity: {
    name: SectionHeader.EQUITY,
    path: `/${PathFragment.EQUITY}/*`,
    hasError: false,
    element: () => (
      <PageWithSubTabs
        pageHeader={<EquityHeader />}
        pages={EquityPages}
        tabDisplayOrder={EquityTabsDisplayOrder}
        defaultTab={`${PathFragment.EQUITY_VALUE}`}
      />
    ),
    rootPath: `/${PathFragment.EQUITY}`,
  },
  InvestmentsandCarry: {
    name: SectionHeader.INVESTMENTS_AND_CARRY_DETAILS,
    path: `/${PathFragment.INVESTMENTS_AND_CARRY}/*`,
    hasError: false,
    element: () => (
      <PageWithSubTabs
        pageMenu={<InvestmentEntityDropdownWrapper />}
        pageHeader={StringConstants.INVESTMENTS_AND_CARRY_DETAILS}
        pages={InvestmentsPages}
        tabDisplayOrder={InvestmentsTabsDisplayOrder}
        defaultTab={`${PathFragment.OVERVIEW}`}
      />
    ),
    rootPath: `/${PathFragment.INVESTMENTS_AND_CARRY}`,
    parentNavBarName: SectionHeader.INVESTMENTS_AND_CARRY,
  },
  Commitments: {
    name: SectionHeader.COMMITMENTS,
    path: `/${PathFragment.COMMITMENTS}`,
    hasError: false,
    element: () => (
      <Page
        pageMenu={<InvestmentEntityDropdownWrapper clientsOnly={true} />}
        pageHeader={SectionHeader.COMMITMENTS}
        content={<CommitmentsWrapper />}
      />
    ),
    rootPath: `/${PathFragment.COMMITMENTS}`,
    parentNavBarName: SectionHeader.INVESTMENTS_AND_CARRY,
  },
  Documents: {
    name: SectionHeader.DOCUMENTS,
    path: `/${PathFragment.DOCUMENTS}/*`,
    hasError: false,
    element: Documents,
    rootPath: `/${PathFragment.DOCUMENTS}`,
  },
  ElectionsList: {
    name: SectionHeader.ELECTIONS,
    path: `/${PathFragment.ELECTIONS}`,
    hasError: false,
    element: ElectionsList,
    rootPath: `/${PathFragment.ELECTIONS}`,
    parentNavBarName: SectionHeader.INVESTMENTS_AND_CARRY,
  },
  ElectionWorkflow: {
    name: SectionHeader.ELECTIONS,
    path: `/${PathFragment.ELECTION_WORKFLOW}`,
    hasError: false,
    element: ElectionWorkflowPage,
    rootPath: `/${PathFragment.ELECTIONS}`,
    parentNavBarName: SectionHeader.INVESTMENTS_AND_CARRY,
  },
  Review: {
    name: SectionHeader.REVIEW,
    path: `/${PathFragment.ELECTION_WORKFLOW}/${PathFragment.REVIEW}`,
    hasError: false,
    element: Review,
    rootPath: `/${PathFragment.REVIEW}`,
  },
};

export interface NavTab {
  headerName: string;
  isActiveRegEx: RegExp;
  isDisabledRegEx?: RegExp;
  subPages: PageName[];
}

export const NavbarDisplayOrder: NavTab[] = [
  {
    headerName: SectionHeader.DASHBOARD,
    isActiveRegEx: new RegExp(`^/${PathFragment.DASHBOARD}`, "i"),
    subPages: [PageName.DASHBOARD],
  },
  {
    headerName: SectionHeader.INVESTMENTS_AND_CARRY,
    isActiveRegEx: new RegExp(
      `^/${PathFragment.INVESTMENTS_AND_CARRY}|^/${PathFragment.COMMITMENTS}|^/${PathFragment.ELECTIONS}`,
      "i"
    ),
    subPages: [
      PageName.INVESTMENTS_AND_CARRY,
      PageName.COMMITMENTS,
      PageName.ELECTIONS_LIST,
    ],
  },
  {
    headerName: SectionHeader.EQUITY,
    isActiveRegEx: new RegExp(`^/${PathFragment.EQUITY}`, "i"),
    subPages: [PageName.EQUITY],
  },
  {
    headerName: SectionHeader.DOCUMENTS,
    isActiveRegEx: new RegExp(`^/${PathFragment.DOCUMENTS}`, "i"),
    isDisabledRegEx: new RegExp(`^/${PathFragment.DOCUMENTS}$`, "i"),
    subPages: [PageName.DOCUMENTS],
  },
];
