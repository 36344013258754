import { Close } from "@mui/icons-material";
import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import {
  ColumnStates,
  DefaultColumnDefinitions,
  DefaultColumnStates,
  DocumentColumnName,
  DocumentColumnSectionOrder,
  DocumentsLabel,
  ManageColumnsDocumentSections,
  PossibleDocumentColumns,
} from "common";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../../redux/store";
import styles from "./ManageColumnsPanel.module.scss";

export interface IManageColumnsPanelProps {
  // function to call when reset button is clicked
  handleResetColumnSelection: (colSelectorSettings: ColumnStates) => void;
  // function to call when apply button is clicked
  handleApplyColumnSelection: (colSelectorSettings: ColumnStates) => void;
  handleClose: () => void;
}

export const ManageColumnsPanel = (props: IManageColumnsPanelProps) => {
  const {
    handleResetColumnSelection,
    handleApplyColumnSelection,
    handleClose,
  } = props;

  const { columnStates } = useSelector(
    (store: InvestorUIStore) => store.documentSettings
  );

  // local store starting with user's latest column selection settings
  //    we only want to change redux store when a user hits reset or apply,
  //    so this local store ensure we track a user's selections and then update
  //    redux store when needed
  const [columnSelectorSettings, setColumnSelectorSettings] =
    useState<ColumnStates>(columnStates);

  const checkIfResetEnabled = useCallback(() => {
    for (const column of PossibleDocumentColumns) {
      const currentlyHidden = columnSelectorSettings[column]?.show;
      const hiddenByDefault = DefaultColumnStates[column]?.show;
      if (currentlyHidden !== hiddenByDefault) {
        return true;
      }
    }
    return false;
  }, [columnSelectorSettings]);

  const [isResetEnabled, setIsResetEnabled] = useState<boolean>(
    checkIfResetEnabled()
  );

  useEffect(() => {
    setIsResetEnabled(checkIfResetEnabled());
  }, [checkIfResetEnabled, setIsResetEnabled]);

  const handleRowSelectionChange = (
    columnName: DocumentColumnName,
    checked: boolean
  ) => {
    // set local store checked flag for the given column
    setColumnSelectorSettings({
      ...columnSelectorSettings,
      [columnName]: {
        ...columnSelectorSettings[columnName],
        show: checked,
      },
    });

    setIsResetEnabled(checkIfResetEnabled());
  };

  return (
    <div className={styles.columnPanel}>
      <div className={styles.header}>
        <div className={styles.label}>
          <ViewWeekOutlinedIcon />
          <Typography variant={"h5"}>
            {DocumentsLabel.MANAGE_COLUMNS}
          </Typography>
        </div>
        <IconButton
          className={styles.close}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </div>
      <div className={styles.panelContent}>
        {DocumentColumnSectionOrder.map((sectionHeader, sectionIndex) => {
          const sectionColumns = ManageColumnsDocumentSections[sectionHeader];
          return (
            <div className={styles.sectionContainer} key={sectionIndex}>
              <Typography variant={"h4"} className={styles.sectionHeader}>
                {sectionHeader.toUpperCase()}
              </Typography>
              <FormGroup className={styles.sectionCheckboxes}>
                {sectionColumns.map((columnName, columnIndex) => {
                  const contantColumn = DefaultColumnDefinitions[columnName];
                  const column = columnSelectorSettings[columnName];
                  return (
                    <FormControlLabel
                      key={columnIndex}
                      control={
                        <Checkbox
                          checked={column?.show}
                          onChange={(_, checked) =>
                            handleRowSelectionChange(columnName, checked)
                          }
                          disabled={column?.disabled}
                          className={styles.check}
                        />
                      }
                      label={contantColumn?.label}
                      className={styles.checkbox}
                      id={column?.disabled ? styles.disabled : styles.enabled}
                    />
                  );
                })}
              </FormGroup>
            </div>
          );
        })}
      </div>
      <div className={styles.buttons}>
        <Typography variant={"h5"}>
          <Link
            component={"button"}
            disabled={!isResetEnabled}
            className={isResetEnabled ? styles.text : styles.disabledText}
            onClick={() => handleResetColumnSelection(columnSelectorSettings)}
          >
            {DocumentsLabel.RESET}
          </Link>
        </Typography>
        <Typography variant={"h5"}>
          <Link
            component={"button"}
            className={styles.text}
            onClick={() => handleApplyColumnSelection(columnSelectorSettings)}
          >
            {DocumentsLabel.APPLY}
          </Link>
        </Typography>
      </div>
    </div>
  );
};
