import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { Link } from "@mui/material";
import { ErrorStringConstants, StringConstants } from "common";
import React from "react";

import styles from "./NoDocumentsAvailableError.module.scss";

/**
 * Custom no rows overlay component when there are no rows loaded on the grid.
 * There can be no rows for the following reasons:
 * 1. User doesn't have entitlements to view documents
 * 2. User has no documents
 * 3. No documents match the filter(s) that user has selected
 * @returns the no documents available component
 */
export const NoDocumentsWithSearchOrFilterError = () => {
  const noDocsFiltersActiveErrorMsg = [
    ErrorStringConstants.NO_DOCUMENTS,
    " ",
    ErrorStringConstants.CHANGE_FILTERS,
  ];

  const noFiltersContact = [
    ErrorStringConstants.CONTACT,
    " ",
    <Link
      key="first"
      className={styles.link}
      href={StringConstants.MAIL_TO_APP_SUPPORT}
    >
      appsupport@blackstone.com
    </Link>,
    " ",
    ErrorStringConstants.WITH_ANY_QUESTIONS,
  ];

  // if no filters then just show no docs error, but if filters are selected
  // return a different error advising user to change/remove filter(s)
  return (
    <div className={styles.noDocuments}>
      <span className={styles.message}>
        <ErrorOutlineOutlinedIcon className={styles.icon} />
        {noDocsFiltersActiveErrorMsg}
      </span>
      <div className={styles.contact}>{noFiltersContact}</div>
    </div>
  );
};
