import { CheckCircle, SaveAlt } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  isSomething,
  PathFragment,
  reqClearActiveElection,
  reqElectionsForClient,
  selectActiveElectionClient,
  setSubmissionRequested,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDownloadElectionAgreement } from "../../../../../hooks/pdfHooks";
import { TaxForms } from "../../../Shared/TaxForms/TaxForms";
import styles from "./SubmitDialog.module.scss";

interface SubmitDialogProps {
  open: boolean;
  investmentVehicleId: number;
  electionRoundId: string;
}

export const SubmitDialog = (props: SubmitDialogProps) => {
  const { open, investmentVehicleId, electionRoundId } = props;

  const dispatch = useDispatch();

  const activeElectionClient = useSelector(selectActiveElectionClient);

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const downloadPDF = async () =>
    await downloadElectionAgreement(electionRoundId, investmentVehicleId);

  const navigate = useNavigate();
  const handleReturnToAllElections = () => {
    if (isSomething(activeElectionClient)) {
      dispatch(reqElectionsForClient(activeElectionClient.value));
    }
    dispatch(reqClearActiveElection());
    dispatch(setSubmissionRequested(false));
    navigate(`/${PathFragment.ELECTIONS}`);
  };

  return (
    <Dialog open={open}>
      <DialogTitle variant="h3">
        {ElectionsLabels.CONGRATULATIONS_YOUVE_SUBMITTED}
      </DialogTitle>
      <DialogContent>
        <Stack direction="row" className={styles.actionItem}>
          <CheckCircle color="success" />
          <Stack alignItems="flex-start">
            <Typography>
              {ElectionsLabels.SUBMISSION_CONFIRMATION_MESSAGE}
            </Typography>
            <Button
              className={styles.downloadButton}
              endIcon={<SaveAlt />}
              onClick={downloadPDF}
            >
              {ElectionsLabels.DOWNLOAD_ELECTION_AGREEMENT}
            </Button>
          </Stack>
        </Stack>
        <TaxForms showInfoIcon={true} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReturnToAllElections}>
          {ElectionsLabels.RETURN_TO_ALL_ELECTIONS}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
