import { isSomething, IStageConfiguration } from "common";
import React from "react";

import styles from "./StageFootnote.module.scss";

export interface IStageFootnoteProps {
  stageConfiguration: IStageConfiguration;
}

export const StageFootnote = (props: IStageFootnoteProps) => {
  const { stageConfiguration } = props;

  return stageConfiguration.footnotes &&
    isSomething(stageConfiguration.footnotes) ? (
    <div className={styles.pageFooter}>
      <p>{stageConfiguration.footnotes.value}</p>
    </div>
  ) : (
    <></>
  );
};
