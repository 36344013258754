import { SelectChangeEvent } from "@mui/material";
import {
  DocumentsLabel,
  mapTaxDocumentStatusToString,
  nothing,
  Optional,
  SingleSelect,
  some,
  TaxDocumentStatus,
} from "common";
import React, { useState } from "react";

import { FilterComponentProps } from "../shared/FilterComponentProps";

export const TaxDocumentStatusFilter = (
  props: FilterComponentProps<"taxDocumentStatus", TaxDocumentStatus>
) => {
  const [selections, setSelections] = useState<string[]>(
    mapTaxDocumentStatusToString(props.columnState.filter.values)
  );

  const handleChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value;
    let taxDocumentStatus: Optional<TaxDocumentStatus> = nothing;
    if ((Object.values(TaxDocumentStatus) as string[]).includes(value)) {
      taxDocumentStatus = some(value as TaxDocumentStatus);
    }
    props.onChange(props.columnDefinition.type, taxDocumentStatus, [], 0);
    setSelections([value]);
  };

  const dropdownValues: string[] = [
    DocumentsLabel.ALL,
    ...mapTaxDocumentStatusToString(props.columnState.filter.options).sort(),
  ];

  return (
    <SingleSelect
      handleChange={handleChange}
      selection={selections.length > 0 ? selections[0] : DocumentsLabel.ALL}
      options={dropdownValues}
    />
  );
};
