import { Checkbox, Tooltip } from "@mui/material";
import {
  DocumentsLabel,
  IDocument,
  toggleVisibleDocumentSelection,
} from "common";
import React, { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../../redux/store";
import styles from "./Checkbox.module.scss";

export const DocumentGridHeaderCheckbox = () => {
  const dispatch = useDispatch();
  const { selectedDocuments, documentsForGrid } = useSelector(
    (store: InvestorUIStore) => store.documentSettings
  );

  const gridHasDocuments = useMemo(
    () => documentsForGrid.documents.length > 0,
    [documentsForGrid.documents.length]
  );
  const docIsSelected = useCallback(
    (doc: IDocument) => selectedDocuments[doc.documentOId] === true,
    [selectedDocuments]
  );

  const someSelected = useMemo(() => {
    return gridHasDocuments && documentsForGrid.documents.some(docIsSelected);
  }, [docIsSelected, documentsForGrid.documents, gridHasDocuments]);

  const allSelected = useMemo(() => {
    return gridHasDocuments && documentsForGrid.documents.every(docIsSelected);
  }, [docIsSelected, documentsForGrid.documents, gridHasDocuments]);

  return (
    <Tooltip
      title={DocumentsLabel.HEADER_CHECKBOX_TOOLTIP}
      PopperProps={{
        modifiers: [{ name: "offset", options: { offset: [50, -10] } }],
      }}
    >
      <Checkbox
        className={styles.documentCheckbox}
        checked={allSelected}
        indeterminate={someSelected && !allSelected}
        onClick={() => dispatch(toggleVisibleDocumentSelection(!allSelected))}
      />
    </Tooltip>
  );
};
