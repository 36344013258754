import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import {
  ElectionAccordionObject,
  isSomething,
  IStageConfiguration,
  Markdown,
} from "common";
import React from "react";

import {
  GetIsExpanded,
  useHandleExpand,
} from "../../../../../common/hooks/electionHooks";
import styles from "./StageBanner.module.scss";

export interface IStageBannerProps {
  accordionId: ElectionAccordionObject;
  stageConfiguration: IStageConfiguration;
}

export const StageBanner = (props: IStageBannerProps) => {
  const { accordionId, stageConfiguration } = props;

  const isExpanded = GetIsExpanded(accordionId);

  const handleExpand = useHandleExpand(accordionId, isExpanded);

  return stageConfiguration.helpBanner &&
    isSomething(stageConfiguration.helpBanner) ? (
    <Accordion
      className={styles.accordion}
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary expandIcon={<ExpandMore className={styles.icon} />}>
        <Typography className={styles.summary}>{"Welcome!"}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Markdown text={stageConfiguration.helpBanner.value} />
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};
