import { SelectChangeEvent } from "@mui/material";
import {
  BaseEntityDropdown,
  ClientSelectLabels,
  IClientDropdownItem,
  IDropdownItemData,
  IPermittedClient,
  isSomething,
  reqElectionsForClient,
  selectActiveElectionClient,
  setActiveElectionClient,
  some,
} from "common";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import styles from "./ClientDropdown.module.scss";

export const ClientDropdown = () => {
  const activeElectionClient = useSelector(selectActiveElectionClient);

  const { permittedElectionClients } = useSelector(
    (state: InvestorUIStore) => state.entitlements
  );

  const clientDropdownItems: IClientDropdownItem[] = useMemo(() => {
    return permittedElectionClients.map((permittedClient: IPermittedClient) => {
      return {
        id: permittedClient.clientId,
        name: permittedClient.clientName,
      };
    });
  }, [permittedElectionClients]);

  const dispatch = useDispatch();

  const onClientChange = (event: SelectChangeEvent) => {
    const client = parseInt(event.target.value as string, 10);
    const selectedClient: IPermittedClient | undefined =
      permittedElectionClients.find((x) => x.clientId === client);
    if (
      selectedClient !== undefined &&
      isSomething(activeElectionClient) &&
      selectedClient !== activeElectionClient.value
    ) {
      dispatch(setActiveElectionClient(some(selectedClient)));
      dispatch(reqElectionsForClient(selectedClient));
    }
  };

  const getMenuItemTextClass = (
    clientDropdownItem: IClientDropdownItem
  ): string => {
    if (
      isSomething(activeElectionClient) &&
      clientDropdownItem.id === activeElectionClient.value.clientId
    ) {
      return styles.selectedClientText;
    }
    return styles.clientText;
  };

  const getActiveItemLabel = (id: string): string => {
    const label =
      permittedElectionClients.find((x) => x.clientId.toString() === id)
        ?.clientName || "";
    return label;
  };

  const getMenuItemData = (item: IClientDropdownItem): IDropdownItemData => {
    const label = item.name;
    const classes = getMenuItemTextClass(item);
    return { label, classes };
  };

  return isSomething(activeElectionClient) ? (
    <BaseEntityDropdown
      items={clientDropdownItems}
      getActiveItemLabel={getActiveItemLabel}
      getMenuItemData={getMenuItemData}
      onSelectionChange={onClientChange}
      selectedValue={activeElectionClient.value.clientId}
      subheaderLabel={ClientSelectLabels.DEFAULT_TEXT}
    />
  ) : (
    <></>
  );
};
