import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import ViewWeekOutlinedIcon from "@mui/icons-material/ViewWeekOutlined";
import { Popover } from "@mui/material";
import {
  ColumnStates,
  copyColumnStates,
  DocumentColumnName,
  DocumentsLabel,
  IconButtonWithTooltip,
  PossibleDocumentColumns,
  resetAllColumnVisibility,
  updateDocumentColumnSettings,
} from "common";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import styles from "./ManageColumns.module.scss";
import { ManageColumnsPanel } from "./ManageColumnsPanel/ManageColumnsPanel";

export interface IManageColumnsProps {
  setColumnVisibility: (
    visibleCols: DocumentColumnName[],
    hiddenCols: DocumentColumnName[]
  ) => void;
  disabled: boolean;
}

export const ManageColumns = (props: IManageColumnsProps) => {
  const { setColumnVisibility } = props;

  const dispatch = useDispatch();

  const [manageColumnsAnchor, setManageColumnsAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleManageColumnsClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setManageColumnsAnchor(e.currentTarget);
  };

  const handleManageColumnsClose = () => {
    setManageColumnsAnchor(null);
  };

  /*
    function that updates redux store with the user's selections, updates the ag grid 
      display columns, and closes the manage cols panel
  */
  const persistGridColSelectionAndClose = (
    colSelectorSettings: ColumnStates
  ) => {
    // persist user's column selection settings
    dispatch(updateDocumentColumnSettings(colSelectorSettings));

    // based on settings, get list of columns that should be visible and list that should be hidden
    const visibleCols: DocumentColumnName[] = [];
    const hiddenCols: DocumentColumnName[] = [];

    PossibleDocumentColumns.map((columnName) => {
      const column = colSelectorSettings[columnName];
      if (column?.show) {
        visibleCols.push(columnName);
      } else {
        hiddenCols.push(columnName);
      }
    });

    // set ag grid cols accordingly
    setColumnVisibility(visibleCols, hiddenCols);

    // close manage columns panel
    handleManageColumnsClose();
  };

  const handleResetColumnSelection = (colSelectorSettings: ColumnStates) => {
    const newSelections = copyColumnStates(colSelectorSettings);
    resetAllColumnVisibility(newSelections);
    persistGridColSelectionAndClose(newSelections);
  };

  const handleApplyColumnSelection = (colSelectorSettings: ColumnStates) => {
    persistGridColSelectionAndClose(colSelectorSettings);
  };

  return (
    <div className={styles.manageCols}>
      <IconButtonWithTooltip
        onClickFunction={handleManageColumnsClick}
        buttonIcon={ViewWeekOutlinedIcon}
        hoverText={DocumentsLabel.MANAGE_COLUMNS}
        className={styles.manageIcon}
        disabled={props.disabled}
      />
      <Popover
        className={styles.popover}
        open={manageColumnsAnchor !== null}
        anchorEl={manageColumnsAnchor}
        onClose={handleManageColumnsClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <ManageColumnsPanel
          handleResetColumnSelection={handleResetColumnSelection}
          handleApplyColumnSelection={handleApplyColumnSelection}
          handleClose={handleManageColumnsClose}
        />
      </Popover>
    </div>
  );
};
