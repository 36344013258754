import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IHeaderGroupParams } from "ag-grid-community";
import React from "react";

import styles from "./DownloadStatusIcon.module.scss";

interface IDownloadStatusIconProps extends IHeaderGroupParams {
  showFilterIcon: boolean;
}

export const DownloadStatusIcon = (props: IDownloadStatusIconProps) => {
  const { showFilterIcon } = props;
  return (
    <div className={styles.columnHeader}>
      <div className={styles.downloadStatusIcon}>
        <div className={styles.download}>
          <FileDownloadOutlinedIcon />
        </div>
        <div className={styles.check}>
          <CheckCircleIcon />
        </div>
      </div>
      {showFilterIcon && (
        <div className={styles.filterIcon}>
          <FilterAltOutlinedIcon />
        </div>
      )}
    </div>
  );
};
