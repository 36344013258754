import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  ElectionsLabels,
  getCurrencyFormattedValueWithZeroDefault,
  IStrategyElection,
  zeroIfNothing,
} from "common";
import React from "react";

import styles from "./ExceededStrategiesTable.module.scss";

interface IExceededStrategiesTableProps {
  exceedsStrategyElections: IStrategyElection[];
}

/*
  Displays a table detailing the strategies that a user has elected more than
    the maximum allowed
  */
export const ExceededStrategiesTable = (
  props: IExceededStrategiesTableProps
) => {
  const { exceedsStrategyElections } = props;

  return (
    <TableContainer>
      <Table className={styles.table}>
        <TableHead>
          <TableRow>
            <TableCell>{ElectionsLabels.STRATEGY}</TableCell>
            <TableCell>{ElectionsLabels.STRATEGY_MAXIMUM}</TableCell>
            <TableCell>{ElectionsLabels.ELECTED}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {exceedsStrategyElections.map(
            (exceededStrategyElection: IStrategyElection, idx: number) => {
              return (
                <TableRow key={idx}>
                  <TableCell>{exceededStrategyElection.strategyName}</TableCell>
                  <TableCell>
                    {getCurrencyFormattedValueWithZeroDefault(
                      exceededStrategyElection.max
                    )}
                  </TableCell>
                  <TableCell>
                    {getCurrencyFormattedValueWithZeroDefault(
                      zeroIfNothing(exceededStrategyElection.electedAmount)
                    )}
                  </TableCell>
                </TableRow>
              );
            }
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
