import { Clear } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
} from "@mui/material";
import {
  ElectionsLabels,
  ElectStageWarningType,
  IElectStageWarning,
  nothing,
  setUnacknowledgedElectStageWarnings,
  some,
  StringConstants,
} from "common";
import React, { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { useMoveStages } from "../../../../../common/hooks/electionHooks";
import styles from "./ElectionWarningDialog.module.scss";
import { ExceededStrategyMaximumDialog } from "./WarningDialogContent/ExceededStrategyMaximumDialog";
import { ExceedsLoanLimitDialog } from "./WarningDialogContent/ExceedsLoanLimitDialog";
import { ExceededOfferAmountDialog } from "./WarningDialogContent/ExceedsOfferAmountDialog";

export interface IElectionWarningDialogProps {
  open: boolean;
  warningInfo: IElectStageWarning;
}

/*
Component which renders the proper Election Warning Dialog based on the type of warning provided.
*/
export const ElectionWarningDialog = (props: IElectionWarningDialogProps) => {
  const { open, warningInfo } = props;

  const moveStages = useMoveStages();

  const dispatch = useDispatch();

  const curWarning = useMemo(() => {
    return warningInfo.warningTypes[0];
  }, [warningInfo]);

  const handleContinue = useCallback(() => {
    const remainingWarnings = warningInfo.warningTypes.filter(
      (warning) => warning !== curWarning
    );
    if (remainingWarnings.length > 0) {
      dispatch(
        setUnacknowledgedElectStageWarnings(
          some({
            warningTypes: remainingWarnings,
            exceededStrategies: warningInfo.exceededStrategies,
          })
        )
      );
    } else {
      dispatch(setUnacknowledgedElectStageWarnings(nothing));
      moveStages();
    }
  }, [curWarning, moveStages, dispatch, warningInfo]);

  const handleCancel = useCallback(() => {
    dispatch(setUnacknowledgedElectStageWarnings(nothing));
  }, [dispatch]);

  const title = useMemo(() => {
    switch (curWarning) {
      case ElectStageWarningType.EXCEEDS_STRATEGY_MAX:
        return ElectionsLabels.EXCEEDS_STRATEGY_MAXIMUM;
      case ElectStageWarningType.EXCEEDS_OFFER_AMOUNT_AND_STRATEGY_MAX:
        return ElectionsLabels.EXCEEDS_OFFERED_AMOUNT_AND_STRATEGY_MAXIMUM;
      case ElectStageWarningType.EXCEEDS_OFFER_AMOUNT:
        return ElectionsLabels.EXCEEDS_OFFER_AMOUNT;
      case ElectStageWarningType.EXCEEDS_LOAN_LIMIT:
        return ElectionsLabels.EXCEEDS_LOAN_LIMIT;
      default:
        return "";
    }
  }, [curWarning]);

  const WarningDialogContent = useMemo(() => {
    switch (curWarning) {
      case ElectStageWarningType.EXCEEDS_STRATEGY_MAX:
      case ElectStageWarningType.EXCEEDS_OFFER_AMOUNT_AND_STRATEGY_MAX:
        return (
          <ExceededStrategyMaximumDialog
            warningType={curWarning}
            exceededStrategies={warningInfo.exceededStrategies}
          />
        );
      case ElectStageWarningType.EXCEEDS_OFFER_AMOUNT:
        return <ExceededOfferAmountDialog />;
      case ElectStageWarningType.EXCEEDS_LOAN_LIMIT:
        return <ExceedsLoanLimitDialog />;
      default:
        return <></>;
    }
  }, [curWarning, warningInfo.exceededStrategies]);

  return (
    <Dialog open={open}>
      <DialogTitle variant="h3">
        <Stack direction={"row"} justifyContent={"space-between"}>
          {title}
          <Clear className={styles.clear} onClick={handleCancel} />
        </Stack>
      </DialogTitle>
      {WarningDialogContent}
      <DialogActions>
        <Stack className={styles.actionButtons}>
          <Button className={styles.cancel} onClick={handleCancel}>
            {StringConstants.CANCEL}
          </Button>
          <Button onClick={handleContinue}>{StringConstants.CONTINUE}</Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
