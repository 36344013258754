import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Checkbox,
  Chip,
  createFilterOptions,
  FormControl,
  TextField,
} from "@mui/material";
import React from "react";

import styles from "./MultiSelect.module.scss";

interface IMultiSelectProps<T> {
  label?: string | undefined;
  options: T[];
  value: T[];
  disabled: boolean;
  onChange: (newOptions: T[]) => void;
  // function that gets the string to be searched for matches when a user types into the multi-select
  getStringToSearch: (option: T) => string;
  // function that gets the label to be displayed in the multi-select for an option
  getLabel: (option: T) => string;
  // function to get a unique id for the option in the multi select
  getId: (option: T) => number | string;
}

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

export const MultiSelect = <T,>({
  label,
  options,
  value,
  disabled,
  onChange,
  getStringToSearch,
  getLabel,
  getId,
}: IMultiSelectProps<T>) => {
  const getUniqueOptions = (options: T[]): T[] => {
    return [...new Set(options)];
  };
  const uniqueOptions = getUniqueOptions(options);

  // Filtering options setup
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option: T) => getStringToSearch(option),
  });

  const renderOption = (
    props: React.HTMLProps<HTMLLIElement>,
    option: T,
    { selected }: { selected: boolean }
  ): JSX.Element => (
    <li {...props} key={getId(option)} className={styles.option}>
      <Checkbox
        icon={icon}
        checkedIcon={checkedIcon}
        checked={selected}
        className={styles.checkbox}
      />
      {getLabel(option)}
    </li>
  );

  const renderInput = (
    params: AutocompleteRenderInputParams,
    value: T[]
  ): JSX.Element => (
    <TextField
      className={styles.inputContainer}
      {...params}
      placeholder={value.length === 0 ? "Select..." : ""}
    />
  );

  const renderTags = (
    value: readonly T[],
    getTagProps: (options: { index: number }) => Record<string, unknown>
  ): JSX.Element[] =>
    value.map((option, index) => (
      <Chip
        label={getLabel(option)}
        {...getTagProps({ index })}
        key={index}
        className={styles.chip}
        size="small"
      />
    ));

  const NoOptions = () => <li className={styles.option}>No options</li>;

  return (
    <FormControl className={styles.select}>
      {label ? <span className={styles.title}>{label}</span> : <></>}
      <Autocomplete
        data-testid="multiselect-autocomplete"
        multiple
        disableCloseOnSelect
        onChange={(_, newOptions) => onChange(newOptions)}
        disabled={disabled}
        limitTags={2}
        filterOptions={filterOptions}
        options={uniqueOptions}
        isOptionEqualToValue={(option: T, value: T) => {
          return getId(option) === getId(value);
        }}
        getOptionLabel={(option: T) => getLabel(option)}
        renderInput={(params) => renderInput(params, value)}
        renderTags={renderTags}
        renderOption={renderOption}
        value={value}
        size="small"
        className={styles.autocomplete}
        noOptionsText={<NoOptions />}
      />
    </FormControl>
  );
};
