import { Stack, Typography } from "@mui/material";
import React from "react";

import styles from "./ReviewAndSignFormQuestion.module.scss";

interface IQuestionAnswerPair {
  question: string;
  answer: string;
}

interface IReviewAndSignFormQuestionProps {
  title: string;
  questionAnswerPairs: IQuestionAnswerPair[];
}

export const ReviewAndSignFormQuestion = (
  props: IReviewAndSignFormQuestionProps
) => {
  const { title, questionAnswerPairs } = props;

  return (
    <Stack className={styles.section}>
      <Typography variant={"h3"} className={styles.title}>
        {title}
      </Typography>
      <Stack className={styles.content}>
        {questionAnswerPairs.map(
          (response: IQuestionAnswerPair, idx: number) => {
            return (
              <Stack key={idx} className={styles.response}>
                <Typography>{response.question}</Typography>
                <Typography className={styles.answer}>
                  {response.answer}
                </Typography>
              </Stack>
            );
          }
        )}
      </Stack>
    </Stack>
  );
};
