import { resetStageValidation } from "common";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../common/hooks/electionHooks";
import { InvestorUIStore } from "../../../../redux/store";
import { ElectionCard } from "../Shared/ElectionCard";
import { StrategyOverviewContent } from "../Shared/StrategyOverview/StrategyOverviewContent/StrategyOverviewContent";
import { StrategyOverviewHeader } from "../Shared/StrategyOverview/StrategyOverviewHeader/StrategyOverviewHeader";

export const StrategyStage = () => {
  const dispatch = useDispatch();

  const { stageValidationRequested } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const moveStages = useMoveStages();

  useEffect(() => {
    // apply the detected change only when next button is clicked that means,
    // avoiding triggering form validations when rendering the component on side effects solved by having stageValidationRequested as 0
    if (stageValidationRequested) {
      dispatch(resetStageValidation());
      moveStages();
    }
  }, [dispatch, stageValidationRequested, moveStages]);

  return (
    <ElectionCard>
      <StrategyOverviewHeader />
      <StrategyOverviewContent />
    </ElectionCard>
  );
};
