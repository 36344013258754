import { createSelector } from "@reduxjs/toolkit";
import {
  ElectionDocumentType,
  isSomething,
  selectElectionRoundConfiguration,
} from "common";

export const selectStrategyOverviewDocumentId = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.documents.find(
      (doc) => doc.type === ElectionDocumentType.STRATEGY_OVERVIEW
    )?.id;
  }
);

export const selectStrategyOverviewTooltip = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.strategies.overviewTooltip;
  }
);

export const selectStrategyFootnotes = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.stages.strategies.footnotes;
  }
);

export const selectFundSortedStrategies = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return [...electionRoundConfiguration.strategies]
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((strategy) => ({
        ...strategy,
        funds: [...strategy.funds].sort((a, b) =>
          a.fundShortName?.localeCompare(b.fundShortName ?? "")
        ),
      }));
  }
);

export const selectHasForecastedDeployment = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => {
    return electionRoundConfiguration.strategies.some((strategy) =>
      strategy.funds.some(
        (fund) =>
          isSomething(fund.forecastedDeployment) &&
          fund.forecastedDeployment.value > 0
      )
    );
  }
);

export const selectPPMDocuments = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => electionRoundConfiguration.ppmDocuments
);

export const selectStrategiesStageConfig = createSelector(
  selectElectionRoundConfiguration,
  (electionRoundConfiguration) => electionRoundConfiguration.stages.strategies
);
