import {
  DocumentsLabel,
  EntityColumns,
  Flatten,
  getEntityLabel,
  IEntity,
  isSomething,
  MultiSelect,
  Optional,
} from "common";
import React, { useEffect, useState } from "react";

import {
  FilterComponentProps,
  mapOptionalToEntity,
} from "../shared/FilterComponentProps";

export const EntityFilter = <K extends EntityColumns>(
  props: FilterComponentProps<K, IEntity[]>
) => {
  const [selections, setSelections] = useState<IEntity[]>(
    mapOptionalToEntity(
      props.columnState.filter.values.filter(
        (value) =>
          isSomething(value) &&
          props.columnState.filter.options.find(
            (option) =>
              isSomething(option) &&
              option.value.name === value.value.name &&
              option.value.oId === value.value.oId
          )
      )
    )
  );

  useEffect(() => {
    setSelections(
      mapOptionalToEntity(
        props.columnState.filter.values.filter(
          (value) =>
            isSomething(value) &&
            props.columnState.filter.options.find(
              (option) =>
                isSomething(option) &&
                option.value.name === value.value.name &&
                option.value.oId === value.value.oId
            )
        )
      )
    );
  }, [props.columnState.filter.options, props.columnState.filter.values]);

  const onChange = (newValuesSelected: IEntity[]) => {
    let newValuesNames = newValuesSelected.map((val: IEntity) => val.name);
    if (newValuesNames.includes(DocumentsLabel.ALL)) {
      // if "All" was checked and something else is checked, uncheck all
      if (selections.length === 0) {
        newValuesNames = newValuesNames.filter(
          (valueName) => valueName !== DocumentsLabel.ALL
        );
        // if "All" gets checked, clear values so that everything else is unchecked
      } else {
        newValuesNames = [];
      }
    }

    const newValues = props.columnState.filter.options.filter(
      (option: Optional<IEntity>) =>
        isSomething(option) && newValuesNames.includes(option.value.name)
    );

    props.onChange(
      props.columnDefinition.type,
      newValues as Optional<NonNullable<Flatten<IEntity[]>>>[],
      []
    );

    setSelections(newValuesSelected);
  };

  const dropdownOptions: IEntity[] = [
    {
      oId: 0,
      name: DocumentsLabel.ALL,
    },
    ...mapOptionalToEntity(props.columnState.filter.options),
  ];

  const getId = (option: IEntity): number => {
    return option.oId;
  };

  const getStringToSearch = (option: IEntity): string => {
    return `${getEntityLabel(option)} ${option.oId}`;
  };

  return (
    <MultiSelect
      options={dropdownOptions}
      value={
        selections.length === 0
          ? [{ oId: 0, name: DocumentsLabel.ALL }]
          : selections
      }
      disabled={false}
      onChange={onChange}
      getStringToSearch={getStringToSearch}
      getLabel={getEntityLabel}
      getId={getId}
    />
  );
};
