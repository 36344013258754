import { Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import {
  DateColumns,
  DocumentsLabel,
  ErrorStringConstants,
  isSomething,
  nothing,
  Optional,
  some,
} from "common";
import dayjs, { Dayjs } from "dayjs";
import React, { useMemo } from "react";

import { FilterComponentProps } from "../shared/FilterComponentProps";
import styles from "./DateRangeFilter.module.scss";

export const DateRangeFilter = <K extends DateColumns>(
  props: FilterComponentProps<K, Date>
) => {
  const startDate: Optional<Date> = props.columnState.filter.values[0];
  const endDate: Optional<Date> = props.columnState.filter.values[1];

  type DateType = "start" | "end";
  const EARLIEST_VALID_YEAR = 1970;
  const ERROR_MESSAGE_SPLIT = ";";

  const parsedErrors = useMemo(() => {
    const splitErrors = (errorString: string | undefined) => {
      if (errorString && errorString.length) {
        return errorString.split(ERROR_MESSAGE_SPLIT);
      }
      return [];
    };

    // Errors for Start Field are at errors[0], errors for End field are at errors[1]
    return {
      start: splitErrors(props.columnState.filter.errors[0]),
      end: splitErrors(props.columnState.filter.errors[1]),
    };
  }, [props.columnState.filter.errors]);

  const handleChange = (value: Dayjs | null, type: DateType) => {
    const index = type === "start" ? 0 : 1;
    let errors: string[] = [];
    let date: Optional<Date> = nothing;
    if (value !== null) {
      date = some(value.toDate());
      if (type === "end") {
        // adjust end dates, so we search up until the end of day
        date.value.setUTCHours(23, 59, 59, 999);
      }
    }
    errors = validateForErrors(date, type);
    props.onChange(props.columnDefinition.type, date, errors, index);
  };

  const validateForErrors = (
    changedDate: Optional<Date>,
    dateType: DateType
  ): string[] => {
    const otherDate: Optional<Date> =
      dateType === "start" ? endDate : startDate;
    const otherDateType: DateType = dateType === "start" ? "end" : "start";

    const errors: { [key in DateType]: string[] } = {
      start: [],
      end: [],
    };

    const isValidDate = (date: Date) =>
      isNaN(date.valueOf()) || date.getUTCFullYear() < EARLIEST_VALID_YEAR;

    // User has selected a value before 1970
    if (isSomething(changedDate) && isValidDate(changedDate.value)) {
      errors[dateType].push(ErrorStringConstants.EARLIEST_DATE_ERROR);
    }
    if (isSomething(otherDate) && isValidDate(otherDate.value)) {
      errors[otherDateType].push(ErrorStringConstants.EARLIEST_DATE_ERROR);
    }

    if (isSomething(changedDate) && isSomething(otherDate)) {
      if (
        (dateType === "start" &&
          changedDate.value.valueOf() > otherDate.value.valueOf()) || // User updated the start date to after the end date
        (dateType === "end" &&
          changedDate.value.valueOf() < otherDate.value.valueOf()) // User updated the end date to after the start date
      ) {
        errors.start.push(ErrorStringConstants.START_DATE_AFTER_END_DATE);
        errors.end.push(ErrorStringConstants.START_DATE_AFTER_END_DATE);
      }
    }

    if (errors.start.length || errors.end.length) {
      return [
        errors.start.join(ERROR_MESSAGE_SPLIT),
        errors.end.join(ERROR_MESSAGE_SPLIT),
      ];
    }

    // no errors!
    return [];
  };

  return (
    <Stack direction="column">
      <Stack direction="row" alignItems="center">
        <DatePicker
          slotProps={{
            actionBar: {
              actions: ["clear", "today"],
            },
            textField: {
              size: "small",
              error: parsedErrors.start.length > 0,
              placeholder: DocumentsLabel.START_DATE,
            },
            toolbar: {
              hidden: true,
            },
          }}
          value={isSomething(startDate) ? dayjs(startDate.value) : null}
          onChange={(value) => handleChange(value, "start")}
          timezone="UTC"
          closeOnSelect={true}
        />
        <Typography className={styles.separator}> - </Typography>
        <DatePicker
          slotProps={{
            actionBar: {
              actions: ["clear", "today"],
            },
            textField: {
              size: "small",
              error: parsedErrors.end.length > 0,
              placeholder: DocumentsLabel.END_DATE,
            },
            toolbar: {
              hidden: true,
            },
          }}
          value={isSomething(endDate) ? dayjs(endDate.value) : null}
          onChange={(value) => handleChange(value, "end")}
          timezone="UTC"
          closeOnSelect={true}
        />
      </Stack>
      <Typography variant="subtitle1" className={styles.error}>
        {/* Only showing a single error message for now, could be updated later */}
        {
          Object.values(parsedErrors).find(
            (errorList) => errorList.length > 0
          )?.[0]
        }
      </Typography>
    </Stack>
  );
};
