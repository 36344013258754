import { Card, Typography } from "@mui/material";
import {
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  Font,
  isSomething,
  openAlert,
  PathFragment,
  reqPutElectionWorkflowState,
  resetStageValidation,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useMoveStages } from "../../../../common/hooks/electionHooks";
import {
  selectEligibilityStatuses,
  selectResidentialInformation,
} from "../../../../redux/selectors";
import { InvestorUIStore } from "../../../../redux/store";
import { ElectionDialog } from "../Shared/ElectionDialog/ElectionDialog";
import styles from "./Eligibility.module.scss";
import { EligibilityPopUpAccordion } from "./EligibilityPopUpAccordion/EligibilityPopUpAccordion";
import { EligibleStatusCard } from "./EligibleStatusCard/EligibleStatusCard";
import { ResidentialInformationCard } from "./ResidentialInformationCard/ResidentialInformationCard";

export interface FlattenedEligibilityStatus {
  accreditedInvestorStatus_hasIndividualNetWorth: boolean;
  accreditedInvestorStatus_hasIndividualIncome: boolean;
  accreditedInvestorStatus_isProfessional: boolean;
  qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds: boolean;
  qualifiedPurchaserStatus_isCompany: boolean;
  qualifiedPurchaserStatus_isTrust: boolean;
  qualifiedPurchaserStatus_isIndividualInvestment: boolean;
  isNotEligible: boolean;
}

export interface ResidentialInformationState {
  isUsResident: string | null;
  isNyResident: string | null;
}

export const Eligibility = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { stageValidationRequested } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const moveStages = useMoveStages();
  const eligibilityStatuses = useSelector((state: InvestorUIStore) =>
    selectEligibilityStatuses(state)
  );

  const { electionWorkflowStateLocal } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const residentialInformationState = useSelector((state: InvestorUIStore) =>
    selectResidentialInformation(state)
  );

  const [open, setOpen] = useState<boolean>(false);

  const eligibilityDefaultValues = {
    accreditedInvestorStatus_hasIndividualNetWorth:
      eligibilityStatuses.accreditedInvestorStatus.hasIndividualNetWorth,
    accreditedInvestorStatus_hasIndividualIncome:
      eligibilityStatuses.accreditedInvestorStatus.hasIndividualIncome,
    accreditedInvestorStatus_isProfessional:
      eligibilityStatuses.accreditedInvestorStatus.isProfessional,
    qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds:
      eligibilityStatuses.qualifiedPurchaserStatus
        .hasInvestmentsInBlackstoneFunds,
    qualifiedPurchaserStatus_isCompany:
      eligibilityStatuses.qualifiedPurchaserStatus.isCompany,
    qualifiedPurchaserStatus_isTrust:
      eligibilityStatuses.qualifiedPurchaserStatus.isTrust,
    qualifiedPurchaserStatus_isIndividualInvestment:
      eligibilityStatuses.qualifiedPurchaserStatus.isIndividualInvestment,
    isNotEligible: eligibilityStatuses.isNotEligible,
  };

  const residentialDefaultValues = {
    isUsResident: isSomething(residentialInformationState.isUSResident)
      ? residentialInformationState.isUSResident.value.toString()
      : null,
    isNyResident: isSomething(residentialInformationState.isNYResident)
      ? residentialInformationState.isNYResident.value.toString()
      : null,
  };

  const residentialInformationForm = useForm<ResidentialInformationState>({
    mode: "onChange",
    defaultValues: residentialDefaultValues,
  });
  const eligibleStatusForm = useForm<FlattenedEligibilityStatus>({
    mode: "onChange",
    defaultValues: eligibilityDefaultValues,
  });

  const residentialRef = useRef<HTMLFormElement>(null);
  const eligibleStatusRef = useRef<HTMLFormElement>(null);

  const [hasResidentialErrors, setHasResidentialErrors] =
    useState<boolean>(false);
  const [hasEligibleStatusErrors, setHasEligibleStatusErrors] =
    useState<boolean>(false);

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const eligibilityStatusValidated = await eligibleStatusForm.trigger();
        const residentialInformationValidated =
          await residentialInformationForm.trigger();

        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (eligibilityStatusValidated && residentialInformationValidated) {
          //this will be done after saving to the api successfully later
          setOpen(true);
        } else {
          //scroll to the bottom where the error was thrown
          if (!residentialInformationValidated) {
            setHasResidentialErrors(true);
          } else {
            setHasResidentialErrors(false);
          }
          if (!eligibilityStatusValidated) {
            setHasEligibleStatusErrors(true);
          } else {
            setHasEligibleStatusErrors(false);
          }
          const ref = !residentialInformationValidated
            ? residentialRef
            : eligibleStatusRef;
          setErrorToast(ElectionsLabels.PLEASE_COMPLETE_REQUIRED_FIELDS);
          ref.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      dispatch(resetStageValidation());
      triggerFormValidations();
    }
  }, [
    dispatch,
    eligibleStatusForm,
    stageValidationRequested,
    residentialInformationForm,
    setErrorToast,
    moveStages,
  ]);

  const [isAccreditedExpanded, setIsAccreditedExpanded] =
    useState<boolean>(false);
  const [isQualifiedExpanded, setIsQualifiedExpanded] =
    useState<boolean>(false);

  const accreditedAccordionDetails: JSX.Element = (
    <div>
      {ElectionsLabels.ACCREDITED_INVESTOR_DEFINITION.map((k) => (
        <Typography variant={"subtitle2"} key={k}>
          {k}
        </Typography>
      ))}
    </div>
  );

  const qualifiedAccordionDetails: JSX.Element = (
    <div>
      {ElectionsLabels.QUALIFIED_PURCHASER_DEFINITION.map((k) => (
        <Typography variant={"subtitle2"} key={k}>
          {k}
        </Typography>
      ))}
    </div>
  );

  const isNotEligibile: boolean = eligibleStatusForm
    .getValues("isNotEligible")
    .valueOf();

  const isAccreditedInvestor: boolean =
    eligibleStatusForm
      .getValues("accreditedInvestorStatus_hasIndividualIncome")
      .valueOf() ||
    eligibleStatusForm
      .getValues("accreditedInvestorStatus_hasIndividualNetWorth")
      .valueOf() ||
    eligibleStatusForm
      .getValues("accreditedInvestorStatus_isProfessional")
      .valueOf();

  const isQualifiedPurchaser: boolean =
    eligibleStatusForm
      .getValues("qualifiedPurchaserStatus_hasInvestmentsInBlackstoneFunds")
      .valueOf() ||
    eligibleStatusForm
      .getValues("qualifiedPurchaserStatus_isCompany")
      .valueOf() ||
    eligibleStatusForm
      .getValues("qualifiedPurchaserStatus_isIndividualInvestment")
      .valueOf() ||
    eligibleStatusForm.getValues("qualifiedPurchaserStatus_isTrust").valueOf();

  let dialogTitle: string = useMemo<string>(() => "", []);
  let dialogContentLines: JSX.Element = useMemo<JSX.Element>(() => <></>, []);
  let dialogContentAdditionalLines: JSX.Element = useMemo<JSX.Element>(
    () => <></>,
    []
  );

  const handleNotEligibleConfirmation = () => {
    if (isSomething(electionWorkflowStateLocal)) {
      const writeElectionWorkflowStatePayload = {
        investmentVehicleId:
          electionWorkflowStateLocal.value.investmentVehicleId,
        electionRoundId: electionWorkflowStateLocal.value.electionRoundId,
        targetState: convertElectionWorkflowStateToUpdateSource(
          electionWorkflowStateLocal.value
        ),
      };
      dispatch(reqPutElectionWorkflowState(writeElectionWorkflowStatePayload));
    }
    navigate(`/${PathFragment.ELECTIONS}`);
  };

  if (isNotEligibile) {
    dialogTitle = ElectionsLabels.NOT_ELIGIBLE;
    dialogContentLines = (
      <Typography variant={"subtitle2"}>
        {ElectionsLabels.NOT_ELIGIBLE_LINES}
      </Typography>
    );
  } else if (isAccreditedInvestor) {
    dialogContentLines = (
      <Typography variant={"subtitle2"}>
        {ElectionsLabels.BY_SELECTING_LINE_STARTING} an{" "}
        <Typography
          component={"span"}
          fontWeight={Font.Weight.MEDIUM}
          variant={"subtitle2"}
        >
          {ElectionsLabels.ACCREDITED_INVESTOR}
        </Typography>{" "}
        {ElectionsLabels.WITHIN_THE_MEANING_OF}{" "}
        {ElectionsLabels.RULE_501_OF_REGULATION_D_UNDER} the{" "}
        <Typography
          component={"span"}
          fontWeight={Font.Weight.MEDIUM}
          variant={"subtitle2"}
        >
          {ElectionsLabels.US_SECURITIES_ACT}
        </Typography>
        {ElectionsLabels.AS_AMENDED_1933_LINE_ENDING}
      </Typography>
    );
    if (isQualifiedPurchaser) {
      dialogTitle =
        ElectionsLabels.ACCREDITED_INVESTOR +
        "/" +
        ElectionsLabels.QUALIFIED_PURCHASER +
        " " +
        ElectionsLabels.CONFIRMATION;
      dialogContentAdditionalLines = (
        <Typography variant={"subtitle2"}>
          {ElectionsLabels.ADDITIONALLY_LINE_STARTING} a{" "}
          <Typography
            component={"span"}
            fontWeight={Font.Weight.MEDIUM}
            variant={"subtitle2"}
          >
            {ElectionsLabels.QUALIFIED_PURCHASER}
          </Typography>{" "}
          {ElectionsLabels.WITHIN_THE_MEANING_OF} the{" "}
          <Typography
            component={"span"}
            fontWeight={Font.Weight.MEDIUM}
            variant={"subtitle2"}
          >
            {ElectionsLabels.INVESTMENT_COMPANY_ACT}
          </Typography>
          {ElectionsLabels.AS_AMENDED_1940_LINE_ENDING}
        </Typography>
      );
    } else {
      dialogTitle =
        ElectionsLabels.ACCREDITED_INVESTOR +
        " " +
        ElectionsLabels.CONFIRMATION;
    }
  } else {
    dialogTitle =
      ElectionsLabels.QUALIFIED_PURCHASER + " " + ElectionsLabels.CONFIRMATION;
    dialogContentLines = (
      <Typography variant={"subtitle2"}>
        {ElectionsLabels.BY_SELECTING_LINE_STARTING} a{" "}
        <Typography
          component={"span"}
          fontWeight={Font.Weight.MEDIUM}
          variant={"subtitle2"}
        >
          {ElectionsLabels.QUALIFIED_PURCHASER}
        </Typography>{" "}
        {ElectionsLabels.WITHIN_THE_MEANING_OF} the{" "}
        <Typography
          component={"span"}
          fontWeight={Font.Weight.MEDIUM}
          variant={"subtitle2"}
        >
          {ElectionsLabels.INVESTMENT_COMPANY_ACT}
        </Typography>
        {ElectionsLabels.AS_AMENDED_1940_LINE_ENDING}
      </Typography>
    );
  }

  return (
    <>
      <ElectionDialog
        id={styles.eligibilityDialog}
        open={open}
        setOpen={setOpen}
        handleNext={
          isNotEligibile ? () => handleNotEligibleConfirmation() : moveStages
        }
        title={dialogTitle}
        nextButtonLabel={
          isNotEligibile ? ElectionsLabels.OK : ElectionsLabels.I_AGREE
        }
        content={
          <>
            <div>
              {dialogContentLines}
              {dialogContentAdditionalLines}
            </div>
            {isAccreditedInvestor || isQualifiedPurchaser ? (
              <Card id={styles.eligibilityPopupCard} className={styles.card}>
                <div id={styles.eligibilityPopupTitle}>
                  {isAccreditedInvestor && isQualifiedPurchaser
                    ? ElectionsLabels.DEFINITIONS
                    : ElectionsLabels.DEFINITION}
                </div>
                {isAccreditedInvestor ? (
                  <EligibilityPopUpAccordion
                    isExpanded={isAccreditedExpanded}
                    setIsExpanded={setIsAccreditedExpanded}
                    accordionSummary={
                      ElectionsLabels.ACCREDITED_INVESTOR +
                      " " +
                      ElectionsLabels.DEFINITION +
                      " - " +
                      ElectionsLabels.US_SECURITIES_ACT
                    }
                    accordionDetails={accreditedAccordionDetails}
                  />
                ) : (
                  <></>
                )}
                {isQualifiedPurchaser ? (
                  <EligibilityPopUpAccordion
                    isExpanded={isQualifiedExpanded}
                    setIsExpanded={setIsQualifiedExpanded}
                    accordionSummary={
                      ElectionsLabels.QUALIFIED_PURCHASER +
                      " " +
                      ElectionsLabels.DEFINITION +
                      " - " +
                      ElectionsLabels.INVESTMENT_COMPANY_ACT
                    }
                    accordionDetails={qualifiedAccordionDetails}
                  />
                ) : (
                  <></>
                )}
              </Card>
            ) : (
              <></>
            )}
          </>
        }
      />
      <form ref={residentialRef}>
        <ResidentialInformationCard
          form={residentialInformationForm}
          hasErrors={hasResidentialErrors}
        />
      </form>
      <form ref={eligibleStatusRef}>
        <EligibleStatusCard
          form={eligibleStatusForm}
          hasErrors={hasEligibleStatusErrors}
        />
      </form>
    </>
  );
};
