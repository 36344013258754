import { Stack } from "@mui/material";
import React from "react";

import { Markdown } from "../..";
import { isSomething } from "../../types/typeGuards";
import { Optional } from "../../types/typeUtils";
import { InfoTooltip } from "../InfoTooltip/InfoTooltip";
import styles from "./TableHeaderWithTooltip.module.scss";

interface TableHeaderWithTooltipProps {
  headerName: string;
  tooltipText: Optional<string>;
  isRichContent?: boolean;
}

export const TableHeaderWithTooltip = (props: TableHeaderWithTooltipProps) => {
  return (
    <Stack direction="row" alignItems="flex-start" justifyContent="flex-end">
      {props.headerName}
      {isSomething(props.tooltipText) && (
        <InfoTooltip
          className={styles.tooltip}
          tooltipContent={
            props.isRichContent ? (
              <Markdown text={props.tooltipText.value} />
            ) : (
              [props.tooltipText.value]
            )
          }
        />
      )}
    </Stack>
  );
};
