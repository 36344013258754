import { ExpandLess, KeyboardArrowRight } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import React from "react";

import styles from "./EligibilityPopUpAccordion.module.scss";

export interface IEligibilityPopUpAccordionProps {
  accordionSummary: string;
  accordionDetails: JSX.Element;
  isExpanded: boolean;
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EligibilityPopUpAccordion = (
  props: IEligibilityPopUpAccordionProps
) => {
  const { accordionSummary, accordionDetails, isExpanded, setIsExpanded } =
    props;

  return (
    <Accordion
      className={`${styles.accordion} ${styles.eligibilityPopup} ${styles.accordionContainer}`}
      expanded={isExpanded}
      onChange={() => setIsExpanded(!isExpanded)}
      disableGutters={true}
    >
      <AccordionSummary
        className={styles.summary}
        expandIcon={
          isExpanded ? (
            <ExpandLess className={styles.icon} />
          ) : (
            <KeyboardArrowRight className={styles.icon} />
          )
        }
      >
        <div>{accordionSummary}</div>
      </AccordionSummary>
      <AccordionDetails className={styles.details}>
        <div>{accordionDetails}</div>
      </AccordionDetails>
    </Accordion>
  );
};
