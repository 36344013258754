import { DialogContent, Link, Stack, Typography } from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

export const ExceedsLoanLimitDialog = () => {
  return (
    <DialogContent>
      <Stack alignItems="flex-start">
        <Typography>{ElectionsLabels.EXCEEDS_LOAN_LIMIT_MESSAGE}</Typography>
        <br />
        <Typography>{ElectionsLabels.LOAN_LIMIT_INCREMENT_MESSAGE}</Typography>
        <br />
        <Typography>
          {ElectionsLabels.IF_QUESTIONS_MESSAGE}
          <Link href={`mailto:${ElectionsLabels.LOAN_LIMIT_CONTACT}`}>
            {ElectionsLabels.LOAN_LIMIT_CONTACT}
          </Link>
          .
        </Typography>
      </Stack>
    </DialogContent>
  );
};
