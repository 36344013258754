import { IHeaderGroupParams } from "ag-grid-community";
import React from "react";

import styles from "./OperatorHeader.module.scss";

interface IOperatorHeaderProps extends IHeaderGroupParams {
  line: string;
}

export const OperatorHeader = (props: IOperatorHeaderProps) => {
  const { line } = props;
  return <div className={`ag-header-cell-text ${styles.header}`}>{line}</div>;
};
