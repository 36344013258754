import "ag-grid-enterprise";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { GroupCellRendererParams } from "ag-grid-enterprise";
import React from "react";

import { DocumentsLabel } from "../../constants/LabelAndTooltipConstants";
import { IconWithTooltip } from "../IconWithTooltip/IconWithTooltip";
import styles from "./DownloadStatusCellRenderer.module.scss";

export const DownloadStatusCellRenderer = (props: GroupCellRendererParams) => {
  const downloadedDateTime = props.value;

  return (
    <div
      className={`${styles.downloadStatus} ${
        downloadedDateTime ? styles.downloaded : ""
      }`}
    >
      {/* add circle icon so check mark gets filled in with white background */}
      <circle></circle>
      {downloadedDateTime ? (
        <IconWithTooltip
          icon={CheckCircleIcon}
          hoverText={DocumentsLabel.DOWNLOADED_TOOLTIP(downloadedDateTime)}
          id={DocumentsLabel.DOWNLOAD_STATUS_ICON_ID}
        />
      ) : (
        <CheckCircleIcon />
      )}
    </div>
  );
};
