import { Stack, Typography } from "@mui/material";
import {
  DataLoadStatus,
  ElectionAccordionObject,
  ElectionsLabels,
  IReviewAndSignStage,
  isSomething,
  openAlert,
  resetStageValidation,
  selectElectCardData,
  selectReviewAndSignState,
  setSubmissionRequested,
} from "common";
import React, { useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../common/hooks/electionHooks";
import { selectCanReadBankAccountsForActiveClient } from "../../../../redux/selectors/entitlementSelectors";
import { InvestorUIStore } from "../../../../redux/store";
import { AccordionElectionCard } from "../Shared/AccordionElectionCard/AccordionElectionCard";
import { ElectionsCard } from "./ElectionsCard/ElectionsCard";
import { EligibilityCard } from "./EligibilityCard/EligibilityCard";
import styles from "./ReviewAndSignStage.module.scss";
import { SignAndSubmitCard } from "./SignAndSubmitCard/SignAndSubmitCard";
import { SubmitDialog } from "./SubmitDialog/SubmitDialog";

export const ReviewAndSignStage = () => {
  const dispatch = useDispatch();
  const electionsData = useSelector(selectElectCardData);
  const reviewAndSignState = useSelector(selectReviewAndSignState);
  const canReadBankAccounts = useSelector((state: InvestorUIStore) =>
    selectCanReadBankAccountsForActiveClient(state)
  );
  const { stageValidationRequested } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );
  const { activeElection, electionWorkflowStateUpdateStatus } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const { submissionRequested } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const reviewAndSignFormRef = useRef<HTMLFormElement>(null);

  const reviewAndSignFormMethods = useForm<IReviewAndSignStage>({
    defaultValues: reviewAndSignState,
    shouldFocusError: true,
  });

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  const moveStages = useMoveStages();

  useEffect(() => {
    // apply the detected change only when next button is clicked that means,
    // avoiding triggering form validations when rendering the component on side effects solved by having stageValidationRequested as 0
    if (stageValidationRequested) {
      dispatch(resetStageValidation());
    }
  }, [dispatch, stageValidationRequested, moveStages]);

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const result = await reviewAndSignFormMethods.trigger();
        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (result) {
          //this will be done after saving to the api successfully later
          dispatch(setSubmissionRequested(true));
          moveStages();
        } else {
          setErrorToast(ElectionsLabels.PLEASE_COMPLETE_SIGN_AND_SUBMIT);
          reviewAndSignFormRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      triggerFormValidations().finally(() => dispatch(resetStageValidation()));
    }
  }, [
    dispatch,
    reviewAndSignFormMethods,
    setErrorToast,
    stageValidationRequested,
    moveStages,
  ]);

  return (
    <Stack>
      <AccordionElectionCard
        headerComponent={
          <Typography className={styles.title}>
            {ElectionsLabels.ELIGIBILITY}
          </Typography>
        }
        cardContentComponent={<EligibilityCard />}
        accordionId={ElectionAccordionObject.REVIEW_AND_SIGN_ELIGIBILITY}
      />
      {electionsData && (
        <AccordionElectionCard
          headerComponent={
            <Typography className={styles.title}>
              {ElectionsLabels.ELECTIONS}
            </Typography>
          }
          cardContentComponent={<ElectionsCard />}
          accordionId={ElectionAccordionObject.REVIEW_AND_SIGN_ELECT}
        />
      )}
      {canReadBankAccounts && (
        <AccordionElectionCard
          headerComponent={
            <Typography className={styles.title}>
              {"Bank Account Card Placeholder"}
            </Typography>
          }
          cardContentComponent={<></>}
          accordionId={ElectionAccordionObject.REVIEW_AND_SIGN_BANK_ACCOUNT}
        />
      )}
      <SignAndSubmitCard
        control={reviewAndSignFormMethods.control}
        ref={reviewAndSignFormRef}
      />
      {isSomething(activeElection) && (
        <SubmitDialog
          open={
            submissionRequested &&
            electionWorkflowStateUpdateStatus === DataLoadStatus.SUCCESSFUL
          }
          investmentVehicleId={activeElection.value.investmentVehicleId}
          electionRoundId={activeElection.value.electionRoundId}
        />
      )}
    </Stack>
  );
};
