import { DialogContent, Stack, Typography } from "@mui/material";
import {
  ElectionsLabels,
  ElectStageWarningType,
  IStrategyElection,
} from "common";
import React from "react";

import styles from "../ElectionWarningDialog.module.scss";
import { ExceededStrategiesTable } from "../ExceededStrategiesTable/ExceededStrategiesTable";

interface IExceededStrategyMaximumDialogProps {
  warningType: ElectStageWarningType;
  exceededStrategies: IStrategyElection[];
}

export const ExceededStrategyMaximumDialog = (
  props: IExceededStrategyMaximumDialogProps
) => {
  const { warningType, exceededStrategies } = props;

  return (
    <DialogContent>
      <Stack alignItems="flex-start">
        {warningType ===
          ElectStageWarningType.EXCEEDS_OFFER_AMOUNT_AND_STRATEGY_MAX && (
          <Stack direction={"row"} className={styles.offeredAmountMessage}>
            <Typography>
              {ElectionsLabels.YOU_ARE_EXCEEDING}{" "}
              <strong>{ElectionsLabels.TOTAL_OFFERED_AMOUNT}</strong>
            </Typography>
          </Stack>
        )}
        <Stack direction={"row"} className={styles.stratMaxMessage}>
          <Typography>
            {ElectionsLabels.YOU_ARE_EXCEEDING}{" "}
            <strong>{ElectionsLabels.STRATEGY_MAXIMUM_AMOUNT}</strong>
          </Typography>
        </Stack>
        <ExceededStrategiesTable
          exceedsStrategyElections={exceededStrategies}
        />
        <Typography className={styles.postMessage}>
          {ElectionsLabels.EXCEEDS_STRATEGY_WARNING_MESSAGE}
        </Typography>
        <Typography>{ElectionsLabels.CLICK_CANCEL}</Typography>
      </Stack>
    </DialogContent>
  );
};
