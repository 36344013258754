import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useSelector } from "react-redux";

import {
  IEligibilityTooltips,
  selectEligibilityTooltips,
} from "../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import { FlattenedEligibilityStatus } from "../Eligibility";
import { EligibleStatusForm } from "./EligibleStatusForm/EligibleStatusForm";

interface IEligibleStatusCardProps {
  form: UseFormReturn<FlattenedEligibilityStatus>;
  hasErrors: boolean;
}

export const EligibleStatusCard: React.FC<IEligibleStatusCardProps> = ({
  form,
  hasErrors,
}) => {
  const tooltips: IEligibilityTooltips = useSelector((state: InvestorUIStore) =>
    selectEligibilityTooltips(state)
  );

  return (
    <ElectionCard error={hasErrors}>
      <EligibleStatusForm
        tooltips={tooltips}
        hasErrors={hasErrors}
        form={form}
      />
    </ElectionCard>
  );
};
