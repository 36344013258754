import { IconButton, Popover } from "@mui/material";
import {
  DocumentDownloadIcon,
  ElectionsLabels,
  IconWithTooltip,
  IElectionDocument,
  TooltipPlacement,
} from "common";
import React from "react";

import { DownloadDocumentPanel } from "../DownloadDocumentPanel/DownloadDocumentPanel";
import styles from "./DownloadDocumentButton.module.scss";

export interface IDownloadDocumentButtonProps {
  outline?: boolean;
  documents: IElectionDocument[];
  electionRoundId: string;
}

export const DownloadDocumentButton = (props: IDownloadDocumentButtonProps) => {
  const { outline = false, documents, electionRoundId } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const popupOpen = Boolean(anchorEl);

  const id = popupOpen ? "simple-popover" : undefined;

  if (documents.length === 0) {
    return (
      <IconWithTooltip
        className={outline ? styles.disabledOutlineIcon : ""}
        icon={DocumentDownloadIcon}
        iconClassName={styles.disabledIcon}
        hoverText={ElectionsLabels.ELECTION_LIST_NO_DOCUMENTS}
        placement={
          outline ? TooltipPlacement.RIGHT : TooltipPlacement.BOTTOM_START
        }
      />
    );
  }

  return (
    <div
      className={
        outline ? styles.downloadDocumentOutline : styles.downloadDocument
      }
    >
      <IconButton
        disableRipple={true}
        aria-label={ElectionsLabels.ELECTION_LIST_TITLE}
        onClick={handleClick}
      >
        <DocumentDownloadIcon />
      </IconButton>
      <Popover
        id={id}
        open={popupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <DownloadDocumentPanel
          documents={documents}
          electionRoundId={electionRoundId}
        />
      </Popover>
    </div>
  );
};
