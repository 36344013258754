import { ISubmissionResponse } from "../types/electionDataTypes";
import { Json, Maybe } from "../types/typeUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleResponse } from "./utils";

export type ISubmissionResponseSource = Json<ISubmissionResponse>;

export const getSubmission = async (
  electionRoundId: string,
  investmentVehicleId: number
): Promise<Maybe<ISubmissionResponseSource>> => {
  const user = getUser();
  const url = `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/submissions/election-rounds/${electionRoundId}/investment-vehicles/${investmentVehicleId}`;
  const request = {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const response = await fetch(url, request);
  return await handleResponse(response);
};
