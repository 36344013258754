import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { DocumentsGridPage } from "./DocumentsGridPage/DocumentsGridPage";
import { SingleDocumentDownloadPage } from "./SingleDocumentDownloadPage/SingleDocumentDownloadPage";

export const Documents = () => {
  return (
    <Routes>
      <Route path="" element={<DocumentsGridPage />} />
      <Route path="download" element={<SingleDocumentDownloadPage />} />
      {/* if someone goes to no a non-sense URL reroute them to the main doc page */}
      <Route path="*" element={<Navigate to="." replace />} />
    </Routes>
  );
};
