import { Stack } from "@mui/material";
import {
  BankAccountAssignment,
  ElectionsLabels,
  GPBankAccount,
  isSomething,
  openAlert,
  resetStageValidation,
  selectHasUSBankAccount,
} from "common";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { useMoveStages } from "../../../../common/hooks/electionHooks";
import { InvestorUIStore } from "../../../../redux/store";
import { BankInfoCard } from "./BankInfoCard/BankInfoCard";
import { DirectDebitRequirementCard } from "./DirectDebitRequirementCard/DirectDebitRequirementCard";

export interface DirectDebitRequirementState {
  hasUSBankAccount: string | null;
}

export const BankAccountStage = () => {
  const dispatch = useDispatch();

  const { stageValidationRequested } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const { bankAccounts } = useSelector(
    (state: InvestorUIStore) => state.bankAccounts.serverData
  );

  const showDirectDebitRequirementCard: boolean = useMemo(() => {
    // show this card when there are no approved Distribution bank accounts
    return (
      bankAccounts.filter((bankAccount: GPBankAccount) => {
        return bankAccount.bankAccount.assignments.some(
          (assignment) => assignment === BankAccountAssignment.DISTRIBUTION
        );
      }).length === 0
    );
  }, [bankAccounts]);

  const moveStages = useMoveStages();

  const hasUSBankAccount = useSelector((state: InvestorUIStore) =>
    selectHasUSBankAccount(state)
  );

  const directDebitRequirementDefaultValues = {
    hasUSBankAccount: isSomething(hasUSBankAccount)
      ? hasUSBankAccount.value.toString()
      : null,
  };

  const directDebitRequirmentForm = useForm<DirectDebitRequirementState>({
    mode: "onChange",
    defaultValues: directDebitRequirementDefaultValues,
  });

  const directDebitRef = useRef<HTMLFormElement>(null);

  const [hasDirectDebitErrors, setHasDirectDebitErrors] =
    useState<boolean>(false);

  const setErrorToast = useCallback(
    (message: string) =>
      dispatch(
        openAlert({
          severity: "error",
          message,
          hideDuration: 5000,
        })
      ),
    [dispatch]
  );

  useEffect(() => {
    // condition to make useEffect hook not run on initial render
    // we must run the validation only when next button/tab was clicked
    // so, stageValidationRequested must be set to 0 after switching to next stage again
    if (stageValidationRequested) {
      // declaring the fn inside the hook since we would'nt have it as dependency
      const triggerFormValidations = async () => {
        // triggers the form validation
        const directDebitFormValidated = showDirectDebitRequirementCard
          ? await directDebitRequirmentForm.trigger()
          : true;

        // checks if the form has a valid state so that data can be saved, then allowed to go to next stage
        // there could be more than one form to be validated in other stages
        if (directDebitFormValidated) {
          //this will be done after saving to the api successfully later
          moveStages();
        } else {
          setHasDirectDebitErrors(true);
          setErrorToast(ElectionsLabels.PLEASE_COMPLETE_REQUIRED_FIELDS);
          //scroll to the bottom where the error was thrown
          directDebitRef.current?.scrollIntoView({
            behavior: "smooth",
          });
        }
      };
      dispatch(resetStageValidation());
      triggerFormValidations();
    }
  }, [
    dispatch,
    stageValidationRequested,
    directDebitRequirmentForm,
    showDirectDebitRequirementCard,
    setErrorToast,
    moveStages,
  ]);

  return (
    <Stack>
      {showDirectDebitRequirementCard && (
        <form ref={directDebitRef}>
          <DirectDebitRequirementCard
            form={directDebitRequirmentForm}
            hasErrors={hasDirectDebitErrors}
          />
        </form>
      )}
      <BankInfoCard />
    </Stack>
  );
};
