import { DialogContent, Stack, Typography } from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

import styles from "../ElectionWarningDialog.module.scss";

export const ExceededOfferAmountDialog = () => {
  return (
    <DialogContent>
      <Stack alignItems="flex-start">
        <Typography>{ElectionsLabels.EXCEEDS_OFFER_AMOUNT_MESSAGE}</Typography>
        <Typography className={styles.clickSaveOrCancel}>
          {ElectionsLabels.CLICK_CANCEL}
        </Typography>
      </Stack>
    </DialogContent>
  );
};
