import { Button } from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  isSomething,
  useDownloadSingleDocument,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectStrategyOverviewDocumentId,
  selectStrategyOverviewTooltip,
} from "../../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../../redux/store";

interface IStrategyOverviewProps {
  isAccordion?: boolean;
}

export const StrategyOverviewHeader = (props: IStrategyOverviewProps) => {
  const { isAccordion = false } = props;

  const { activeElection } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const strategyOverviewDocumentId = useSelector((state: InvestorUIStore) =>
    selectStrategyOverviewDocumentId(state)
  );

  const { downloadElectionSingleDocument } = useDownloadSingleDocument();

  const handleDocumentDownload = async (
    electionRoundId: string,
    documentId: number
  ) => await downloadElectionSingleDocument(electionRoundId, documentId);

  const tooltip = useSelector((state: InvestorUIStore) =>
    selectStrategyOverviewTooltip(state)
  );

  return (
    <FormCardHeader
      title={ElectionsLabels.STRATEGY_OVERVIEW}
      tooltip={tooltip}
      actionElement={
        isSomething(activeElection) &&
        strategyOverviewDocumentId !== undefined ? (
          <Button
            size="medium"
            variant="text"
            onClick={() =>
              handleDocumentDownload(
                activeElection.value.electionRoundId,
                strategyOverviewDocumentId
              )
            }
          >
            {ElectionsLabels.VIEW_STRATEGY_OVERVIEW_PDF}
          </Button>
        ) : (
          <></>
        )
      }
      isAccordionCard={isAccordion}
    />
  );
};
