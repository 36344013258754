import { Grid, Stack } from "@mui/material";
import clsx from "clsx";
import {
  AccountIntermediaryType,
  AccountNumberType,
  BankAccountConstants,
  BankAccountTooltips,
  BankIdType,
} from "common";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import { AddBankAccountFormState } from "../../AddBankAccountDialog";
import styles from "../AddBankAccountForm.module.scss";
import { DropdownInput } from "../DropdownInput/DropdownInput";
import { AccountTypeRadioButtonInput } from "../RadioButtonInput/AccountTypeRadioButtonInput";
import { TextFieldInput } from "../TextFieldInput/TextFieldInput";

type IntermediaryAccountFormProps = {
  field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>;
  isCollapsed: boolean;
};

const INTERMEDIARY_ACC_PREFIX = "intermediaryAccount";

function IntermediaryAccountForm({
  field,
  isCollapsed,
}: IntermediaryAccountFormProps) {
  return (
    <Grid
      container
      className={clsx({
        [styles.FormColumnsContainer]: true,
        [styles.HideIntermediaryAccountForm]: isCollapsed,
      })}
    >
      <Grid item xs={12} lg={6}>
        <Stack className={styles.inputRow}>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <TextFieldInput
              field={field}
              prefix={INTERMEDIARY_ACC_PREFIX}
              attributeName="accountHolderName"
              label={BankAccountConstants.ACCOUNT_HOLDER}
              placeHolderText={BankAccountConstants.ACCOUNT_HOLDER_PLACEHOLDER}
              tooltip={BankAccountTooltips.ACCOUNT_HOLDER}
            />
          </Grid>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <AccountTypeRadioButtonInput
              field={field}
              disabled={false}
              label={BankAccountConstants.ACCOUNT_TYPE}
              tooltip={BankAccountTooltips.INTERMEDIARY_ACCOUNT_TYPE}
              isIntermediaryAccount={true}
              options={[
                AccountIntermediaryType.INTERMEDIARY,
                AccountIntermediaryType.CORRESPONDENT,
              ]}
            />
          </Grid>
        </Stack>
        <Stack className={styles.inputRow}>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <DropdownInput
              prefix={INTERMEDIARY_ACC_PREFIX}
              field={field}
              attributeName="accountNumberType"
              label={BankAccountConstants.ACCOUNT_ID_TYPE}
              options={Object.values(AccountNumberType)}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <TextFieldInput
              field={field}
              prefix={INTERMEDIARY_ACC_PREFIX}
              attributeName="accountNumber"
              label={BankAccountConstants.ACCOUNT_ID_NUMBER}
              placeHolderText={
                BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER
              }
            />
          </Grid>
        </Stack>
        <Stack className={styles.inputRow}>
          <TextFieldInput
            field={field}
            prefix={INTERMEDIARY_ACC_PREFIX}
            attributeName="confirmAccountId"
            label={BankAccountConstants.CONFIRM_ACCOUNT_ID}
            placeHolderText={BankAccountConstants.ACCOUNT_ID_NUMBER_PLACEHOLDER}
          />
        </Stack>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack className={styles.inputRow}>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <DropdownInput
              field={field}
              prefix={INTERMEDIARY_ACC_PREFIX}
              attributeName="bankIdType"
              label={BankAccountConstants.BANK_ID_TYPE}
              options={Object.values(BankIdType)}
              disabled={false}
            />
          </Grid>
          <Grid item xs={12} md={6} className={styles.rowItems}>
            <TextFieldInput
              field={field}
              prefix={INTERMEDIARY_ACC_PREFIX}
              attributeName="bankId"
              label={BankAccountConstants.BANK_ID_NUMBER}
              placeHolderText={BankAccountConstants.BANK_ID_NUMBER_PLACEHOLDER}
            />
          </Grid>
        </Stack>
        <Stack className={styles.inputRow}>
          <TextFieldInput
            field={field}
            prefix={INTERMEDIARY_ACC_PREFIX}
            attributeName="bankName"
            label={BankAccountConstants.BANK_NAME}
            placeHolderText={BankAccountConstants.ENTER_BANK_NAME}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}

export default IntermediaryAccountForm;
