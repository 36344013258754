import { IHeaderGroupParams } from "ag-grid-community";
import React from "react";

import styles from "./MultiLineHeader.module.scss";

interface IMultiLineHeaderProps extends IHeaderGroupParams {
  lines: string[];
}

export const MultiLineHeader = (props: IMultiLineHeaderProps) => {
  const { lines } = props;
  return (
    <div className={`ag-header-cell-text ${styles.header}`}>
      {lines.map((line: string, idx: number) => {
        return <div key={idx}>{line}</div>;
      })}
    </div>
  );
};
