import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { ColDef, ICellRendererParams, RowClassParams } from "ag-grid-community";
import { AgGridReact, CustomCellRendererProps } from "ag-grid-react";
import {
  BankAccountAssignment,
  BankAccountConstants,
  Banner,
  BannerType,
  changeContributionAccount,
  changeDistributionAccount,
  extractable,
  getEligibleContributionAccounts,
  getEligibleDistributionAccounts,
  hasPendingContribution,
  hasPendingDistribution,
  hasUnsubmittedChangesContribution,
  hasUnsubmittedChangesDistribution,
  IBankAccount,
  IBaseStore,
  isSomething,
  Optional,
  optionalDateDashForNullFormatter,
  optionalStringDashForNullFormatter,
  some,
  useGridExtensions,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AddBankAccountDialog } from "../AddBankAccountDialog/AddBankAccountDialog";
import styles from "./AccountAssignmentGrid.module.scss";
import { AddAccountCell } from "./Renderers/AddAccountCell";
import { AddAccountHeader } from "./Renderers/AddAccountHeader";

interface AccountAssignmentRow {
  purpose: BankAccountAssignment;
  bankName: Optional<string>;
  bankAccount: Optional<string>;
  eligibleAccounts: IBankAccount[];
  lastSubmitted: Optional<Date>;
  disabled: boolean;
  unsubmitted: boolean;
}

export interface IAddAccounCellProps
  extends ICellRendererParams<AccountAssignmentRow> {
  openBankAccountDialog: (isContribution: boolean) => void;
}
const SubmitChangesPrompt = () => {
  return (
    <Banner bannerType={BannerType.WARNING}>
      {BankAccountConstants.SUBMIT_CHANGES_PROMPT}
    </Banner>
  );
};

export const AccountAssignmentGrid = () => {
  const { onGridReady, resizeColumns } = useGridExtensions();
  const dispatch = useDispatch();

  const {
    selectedContributionAccount,
    selectedDistributionAccount,
    unsubmittedChanges,
  } = useSelector((store: IBaseStore) => store.bankAccounts);

  const eligibleContributionAccounts = useSelector(
    getEligibleContributionAccounts
  );
  const eligibleDistributionAccounts = useSelector(
    getEligibleDistributionAccounts
  );
  const unsubmittedContribution = useSelector(
    hasUnsubmittedChangesContribution
  );
  const unsubmittedDistribution = useSelector(
    hasUnsubmittedChangesDistribution
  );
  const hasPendingContributionAccount = useSelector(hasPendingContribution);
  const hasPendingDistributionAccount = useSelector(hasPendingDistribution);
  const hasUnsubmittedChanges = Object.values(unsubmittedChanges).some(Boolean);
  const [isAddBankAccountDialogOpen, setIsAddBankAccountDialogOpen] =
    useState<boolean>(false);

  const [isContribution, setIsContribution] = useState<boolean>(false);

  const openBankAccountDialog = useCallback(
    (isContrbution: boolean) => {
      setIsContribution(isContrbution);
      setIsAddBankAccountDialogOpen(true);
    },
    [setIsContribution, setIsAddBankAccountDialogOpen]
  );

  const topPinnedRowData: AccountAssignmentRow[] = useMemo(
    () => [
      {
        purpose: BankAccountAssignment._,
        bankName: some("_"),
        bankAccount: some("alert"),
        eligibleAccounts: [],
        lastSubmitted: some(new Date()),
        disabled: true,
        unsubmitted: false,
      },
    ],
    []
  );

  const columnDefs: ColDef<AccountAssignmentRow>[] = useMemo(() => {
    return [
      {
        field: "purpose",
        cellRenderer: (
          props: CustomCellRendererProps<
            AccountAssignmentRow,
            BankAccountAssignment
          >
        ) => {
          const { bankAccount, purpose } = props.data ?? {};
          return bankAccount &&
            isSomething<string>(bankAccount) &&
            bankAccount.value === "alert" ? (
            <SubmitChangesPrompt />
          ) : (
            purpose
          );
        },
      },
      {
        field: "bankName",
        valueFormatter: optionalStringDashForNullFormatter,
      },
      {
        field: "bankAccount",
        valueFormatter: optionalStringDashForNullFormatter,
        cellRenderer: (
          props: CustomCellRendererProps<AccountAssignmentRow, Optional<string>>
        ) => {
          const options = (props.data?.eligibleAccounts ?? []).map(
            (account) => account.accountNumber
          );
          options.push(BankAccountConstants.NO_ACCOUNT_SELECTED);
          const { purpose } = props.data ?? {};
          const dispatcherFn =
            purpose === BankAccountAssignment.CONTRIBUTION
              ? changeContributionAccount
              : changeDistributionAccount;

          const accountNumber =
            props.value && isSomething(props.value)
              ? props.value.value
              : BankAccountConstants.NO_ACCOUNT_SELECTED;

          return (
            <Select
              value={accountNumber}
              fullWidth
              disabled={props.data?.disabled}
              onChange={(e: SelectChangeEvent) => {
                dispatch(dispatcherFn(e.target.value));
              }}
            >
              {options.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        field: "lastSubmitted",
        valueFormatter: optionalDateDashForNullFormatter,
      },
      {
        headerComponent: () => (
          <AddAccountHeader disabled={!hasUnsubmittedChanges} />
        ),
        cellRenderer: AddAccountCell,
        cellRendererParams: {
          openBankAccountDialog: openBankAccountDialog,
        } as IAddAccounCellProps,
      },
    ];
  }, [openBankAccountDialog, dispatch, hasUnsubmittedChanges]);

  const rowData: AccountAssignmentRow[] = useMemo(() => {
    const extractableContribution = extractable(selectedContributionAccount);
    const extractableDistribution = extractable(selectedDistributionAccount);
    return [
      {
        purpose: BankAccountAssignment.CONTRIBUTION,
        bankName: extractableContribution.extractByKey("bankName"),
        bankAccount: extractableContribution.extractByKey("accountNumber"),
        eligibleAccounts: eligibleContributionAccounts,
        lastSubmitted: extractableContribution.extractByKey("lastUpdated"),
        disabled: hasPendingContributionAccount,
        unsubmitted: unsubmittedContribution,
      },
      {
        purpose: BankAccountAssignment.DISTRIBUTION,
        bankName: extractableDistribution.extractByKey("bankName"),
        bankAccount: extractableDistribution.extractByKey("accountNumber"),
        eligibleAccounts: eligibleDistributionAccounts,
        lastSubmitted: extractableDistribution.extractByKey("lastUpdated"),
        disabled: hasPendingDistributionAccount,
        unsubmitted: unsubmittedDistribution,
      },
    ];
  }, [
    eligibleContributionAccounts,
    eligibleDistributionAccounts,
    hasPendingContributionAccount,
    hasPendingDistributionAccount,
    selectedContributionAccount,
    selectedDistributionAccount,
    unsubmittedContribution,
    unsubmittedDistribution,
  ]);

  return (
    <Box className={`ag-theme-alpine`} id={styles.accountGrid} width="100%">
      <AgGridReact<AccountAssignmentRow>
        defaultColDef={{
          resizable: false,
          sortable: false,
          suppressHeaderMenuButton: true,
          suppressMovable: true,
        }}
        pinnedTopRowData={hasUnsubmittedChanges ? topPinnedRowData : []}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout="autoHeight"
        onGridReady={onGridReady}
        onRowDataUpdated={resizeColumns}
        onGridSizeChanged={resizeColumns}
        suppressRowDrag
        suppressCellFocus
        gridOptions={{
          getRowClass: (params: RowClassParams<AccountAssignmentRow>) => {
            if (params.node.isRowPinned()) {
              return styles.CustomRow;
            }
            if (params.data?.unsubmitted) {
              return styles.UnsubmittedChanges;
            }
          },
        }}
      />
      <AddBankAccountDialog
        open={isAddBankAccountDialogOpen}
        setOpen={setIsAddBankAccountDialogOpen}
        isContribution={isContribution}
      />
    </Box>
  );
};
