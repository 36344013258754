import { Card } from "@mui/material";
import {
  ElectionAccordionObject,
  ElectionsLabels,
  FormCardHeader,
  nothing,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectElectionProcessAccordionStages } from "../../../../../redux/selectors";
import { selectCanReadBankAccountsForActiveClient } from "../../../../../redux/selectors/entitlementSelectors";
import { InvestorUIStore } from "../../../../../redux/store";
import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./ElectionProcessCard.module.scss";
import { ElectionProcessStagesAccordion } from "./ElectionProcessStagesAccordion/ElectionProcessStagesAccordion";

export const ElectionProcessCard = () => {
  const accordionStages = useSelector((state: InvestorUIStore) =>
    selectElectionProcessAccordionStages(state)
  );
  const canReadBankAccounts = useSelector((state: InvestorUIStore) =>
    selectCanReadBankAccountsForActiveClient(state)
  );
  const showStages: boolean = accordionStages.length > 0;

  return (
    <ElectionCard>
      <FormCardHeader
        title={ElectionsLabels.ELECTION_PROCESS}
        tooltip={nothing}
      />
      {showStages && (
        <Card id={styles.stagesCard} className={styles.card}>
          {accordionStages.map((stage) => {
            if (
              stage.accordionId ===
                ElectionAccordionObject.OVERVIEW_PROCESS_BANK_ACCOUNT &&
              !canReadBankAccounts
            ) {
              return;
            }
            return (
              <ElectionProcessStagesAccordion
                key={stage.accordionSummary}
                accordionId={stage.accordionId}
                accordionSummary={stage.accordionSummary}
                accordionDetails={stage.accordionDetails}
              />
            );
          })}
        </Card>
      )}
    </ElectionCard>
  );
};
