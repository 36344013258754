import { Tooltip } from "@mui/material";
import { Column, ColumnGroup, ICellRendererParams } from "ag-grid-community";
import {
  ElectionsLabels,
  ElectionsListGridKeys,
  getCurrencyFormattedValue,
  Markdown,
} from "common";
import React, { useEffect, useState } from "react";

export const ElectionTooltip = (props: ICellRendererParams) => {
  const [tooltipDisplayValue, setTooltipDisplayValue] = useState<string>("");
  const [columnName, setColumnName] = useState<string>("");

  useEffect(() => {
    if (props && props.column) {
      const currentColumn: ColumnGroup | Column<ElectionsListGridKeys> =
        props.column;
      setColumnName(currentColumn.getColId());
    }
    if (columnName == ElectionsListGridKeys.NAME) {
      return setTooltipDisplayValue(props.data.name);
    }
    if (columnName == ElectionsListGridKeys.INVESTMENT_VEHICLE) {
      return setTooltipDisplayValue(props.data.investmentVehicle.name);
    }
    if (columnName == ElectionsListGridKeys.ELECTED_AMOUNT) {
      return setTooltipDisplayValue(
        getCurrencyFormattedValue(props.data.electedAmount)
      );
    }
    if (columnName == ElectionsListGridKeys.SUBMISSION_DEADLINE) {
      return setTooltipDisplayValue(
        ElectionsLabels.SUBMISSION_DEADLINE(
          props.data.electionSubmissionDeadline
        )
      );
    }
  }, [columnName, props]);

  return (
    <Tooltip
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -20],
            },
          },
        ],
      }}
      placement={"bottom-start"}
      title={tooltipDisplayValue}
    >
      <span>
        <Markdown text={props.valueFormatted ?? tooltipDisplayValue} />
      </span>
    </Tooltip>
  );
};
