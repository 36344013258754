import { IPage, PageLoadError } from "common";
import React, { ErrorInfo, ReactNode } from "react";

export interface IUIErrorBoundaryProps {
  page: IPage;
  children?: ReactNode;
}

interface ErrorState {
  hasError: boolean;
}

/**
 * Generic class implementing a React Error Boundary to be wrapped around each ocmponent
 *  Ref. https://reactjs.org/docs/error-boundaries.html
 */
export class UIErrorBoundary extends React.Component<IUIErrorBoundaryProps> {
  public state: ErrorState = {
    hasError: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static getDerivedStateFromError(_: Error): ErrorState {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // update page specific error marker, so pages with no errors can still render
    this.props.page.hasError = true;
  }

  render() {
    if (this.state.hasError && this.props.page.hasError) {
      // show page load error if an error exists AND it's on the current page
      return <PageLoadError errorCode={500} />;
    }

    return this.props.children;
  }
}
