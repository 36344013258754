import { AccountCircleRounded } from "@mui/icons-material";
import { Avatar, Link, Stack, Typography } from "@mui/material";
import {
  ElectionsLabels,
  EnvironmentResolver,
  FormCardHeader,
  IElectionTeamMember,
  isSomething,
  nothing,
  Optional,
  some,
} from "common";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { selectElectionRoundConfiguration } from "../../../../../redux/selectors";
import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./ElectionsContactTeamCard.module.scss";

export const ElectionContactTeamCard = () => {
  const { electionTeam } = useSelector(selectElectionRoundConfiguration);

  return (
    <ElectionCard>
      <FormCardHeader title={ElectionsLabels.ELECTION_TEAM} bottomBorder />
      {electionTeam.map((contact) => (
        <ContactCard contact={contact} key={contact.employeeId} />
      ))}
      <Typography>
        {ElectionsLabels.ELECTION_CONTACT_CARD_FOOTER}
        <Link href={`mailto:${ElectionsLabels.ELECTION_CONTACT_EMAIL}`}>
          {ElectionsLabels.ELECTION_CONTACT_EMAIL}
        </Link>
      </Typography>
    </ElectionCard>
  );
};

interface ContactCardProps {
  contact: IElectionTeamMember;
}
const ContactCard = (props: ContactCardProps) => {
  const { contact } = props;

  const [profileImageSource, setProfileImageSource] =
    useState<Optional<string>>(nothing);

  // First check if we can successfully load an image for the user
  // so that we can we can render a placeholder if not.
  useEffect(() => {
    const image = new Image();
    image.src = `${EnvironmentResolver.ENV.REACT_APP_PROFILE_IMAGES_URL_BASE}/${contact.employeeId}`;

    if (image.complete) {
      setProfileImageSource(some(image.src));
    } else {
      image.onload = () => setProfileImageSource(some(image.src));
    }
  }, [contact]);

  return (
    <Stack direction="row" className={styles.contact}>
      {isSomething(profileImageSource) ? (
        <Avatar src={profileImageSource.value} />
      ) : (
        <AccountCircleRounded className={styles.placeholderImage} />
      )}

      <Stack className={styles.content}>
        <Typography className={styles.nameAndPhone}>
          {`${contact.firstName} ${contact.lastName} | ${contact.phoneNumber}`}
        </Typography>
        <Link className={styles.link} href={`mailto:${contact.emailAddress}`}>
          {contact.emailAddress}
        </Link>
      </Stack>
    </Stack>
  );
};
