import React from "react";

import {
  IQuestionResponseProps,
  QuestionResponse,
} from "./QuestionResponse/QuestionResponse";

interface IQuestionResponsesProps {
  questionResponses: IQuestionResponseProps[];
}

export const QuestionResponses = (props: IQuestionResponsesProps) => {
  const { questionResponses } = props;
  return (
    <>
      {questionResponses &&
        questionResponses.map(
          (questionResponse: IQuestionResponseProps, index: number) => {
            return (
              <QuestionResponse
                key={index}
                question={questionResponse.question}
                response={questionResponse.response}
              />
            );
          }
        )}
    </>
  );
};
