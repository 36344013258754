import { TaxDocumentStatus } from "../constants/enums";
import {
  IDocumentClients,
  IDocumentFilters,
  IDocumentResponse,
  IDocumentSortOptions,
  IEntity,
} from "../types/dataTypes";
import { ColumnStates } from "../types/documentSettingTypes";
import { isSomething } from "../types/typeGuards";
import { Json, Maybe, Optional } from "../types/typeUtils";
import { getDocDownloadTimeZoneQueryParameters } from "../utils/documentUtils";
import { getUser } from "./auth";
import { EnvironmentResolver } from "./environmentResolver";
import { handleBlobResponse, handleResponse } from "./utils";

export type IDocumentClientsSource = Json<IDocumentClients>;
export type IDocumentFiltersSource = Json<IDocumentFilters>;
export type IDocumentResponseSource = Json<IDocumentResponse>;

export const getDocumentClients = async (): Promise<
  Maybe<IDocumentClientsSource>
> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_DOCUMENTS_URL_BASE +
    "/my/clients?clientType=documents";

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await handleResponse(response);
};

export const getDocumentFilters = async (
  clientOId: string
): Promise<Maybe<IDocumentFiltersSource>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_DOCUMENTS_URL_BASE +
    "/clients/" +
    clientOId +
    "/documents/filters";

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  return await handleResponse(response);
};

export type DocumentServerResponse = Json<IDocumentResponse>;

type DocumentRequestDateFilter = {
  start?: string;
  end?: string;
};

type DocumentRequestFilters = {
  documentTypeIds?: number[];
  periods?: string[];
  fundOIds?: number[];
  publishedDates?: DocumentRequestDateFilter;
  documentTypeCategoryIds?: number[];
  investmentVehicleOIds?: number[];
  taxDocumentStatus?: string;
  jurisdictions?: string[];
  partnershipOIds?: number[];
  effectiveDates?: DocumentRequestDateFilter;
};

type DocumentRequestPostBody = {
  offset: number;
  limit: number;
  filters: DocumentRequestFilters;
  searchTerm?: string | undefined;
  sort?: IDocumentSortOptions | undefined;
};

export type DocumentRequest = {
  clientOId: string;
  body: DocumentRequestPostBody;
};

export const createDocumentFilterRequest = (
  columns: ColumnStates
): DocumentRequestFilters => {
  return {
    publishedDates: createDateRangeFilter(columns.publishedDate.filter.values),
    effectiveDates: createDateRangeFilter(columns.effectiveDate.filter.values),
    documentTypeCategoryIds: columns.documentTypeCategory.filter.values.reduce(
      (acc, value) => {
        if (isSomething(value)) {
          acc.push(value.value.id);
        }
        return acc;
      },
      [] as number[]
    ),
    fundOIds: createEntityFilter(columns.funds.filter.values),
    taxDocumentStatus: createTaxDocumentStatusFilter(
      columns.taxDocumentStatus.filter.values
    ),
    documentTypeIds: columns.documentType.filter.values.reduce((acc, value) => {
      if (isSomething(value)) {
        acc.push(value.value.id);
      }
      return acc;
    }, [] as number[]),
    periods: columns.period.filter.values.reduce((acc, value) => {
      if (isSomething(value)) {
        acc.push(value.value);
      }
      return acc;
    }, [] as string[]),
    partnershipOIds: createEntityFilter(columns.partnerships.filter.values),
  };
};

const createDateRangeFilter = (filterSelections: Optional<Date>[]) => {
  const dateFilter: DocumentRequestDateFilter = {};
  if (isSomething(filterSelections[0])) {
    dateFilter.start = filterSelections[0].value.toISOString();
  }
  if (isSomething(filterSelections[1])) {
    dateFilter.end = filterSelections[1].value.toISOString();
  }
  return dateFilter;
};

const createTaxDocumentStatusFilter = (
  filterSelections: Optional<TaxDocumentStatus>[]
): string => {
  if (filterSelections.length > 0 && isSomething(filterSelections[0])) {
    return filterSelections[0].value;
  }
  return "";
};

const createEntityFilter = (
  filterSelections: Optional<IEntity>[]
): number[] => {
  return filterSelections.reduce((acc, value) => {
    if (isSomething(value)) {
      acc.push(value.value.oId);
    }
    return acc;
  }, [] as number[]);
};

export const getDocumentsForClient = async (
  request: DocumentRequest
): Promise<Maybe<DocumentServerResponse>> => {
  const user = getUser();
  const url =
    EnvironmentResolver.ENV.REACT_APP_DOCUMENTS_URL_BASE +
    "/clients/" +
    request.clientOId +
    "/documents";

  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify(request.body),
  });

  return await handleResponse(response);
};

export const getBulkDocumentBlob = async (
  documentOIds: string[]
): Promise<Maybe<Blob>> => {
  const user = getUser();

  const searchParams = getDocDownloadTimeZoneQueryParameters();
  searchParams.append("documentOIds", documentOIds.join(","));
  const url = `${
    EnvironmentResolver.ENV.REACT_APP_DOCUMENTS_URL_BASE
  }/documents?${searchParams.toString()}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${user?.access_token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

  const fileBlob: Maybe<Blob> = await handleBlobResponse(response);

  return fileBlob;
};
