import { Checkbox, FormControlLabel } from "@mui/material";
import {
  ElectionsLabels,
  IOverviewStage,
  setHasAcceptedLegalAttestation,
} from "common";
import React, { forwardRef, Ref } from "react";
import { Control, Controller, ControllerRenderProps } from "react-hook-form";
import { useDispatch } from "react-redux";

import styles from "./LegalAttestationForm.module.scss";

interface ILegalAttestationFormProps {
  control: Control<IOverviewStage>;
}

const LegalAttestationFormBase = (
  props: ILegalAttestationFormProps,
  ref: Ref<HTMLFormElement>
) => {
  const { control } = props;
  const dispatch = useDispatch();

  /**
   * Validates the legal attestation form
   * @param hasAcceptedLegalAttestation A boolean value
   * @returns A string message
   */
  const validate = (hasAcceptedLegalAttestation: boolean) => {
    if (!hasAcceptedLegalAttestation) {
      return ElectionsLabels.LEGAL_ATTESTATION_HEADER_LINE;
    }
  };

  /**
   * Sets the value of the legal attestation checkbox as well as updating the store
   * @param event A change event
   */
  const onChange = (
    field: ControllerRenderProps<IOverviewStage, "hasAcceptedLegalAttestation">
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      field.onChange(event.target.checked);
      dispatch(setHasAcceptedLegalAttestation(event.target.checked));
    };
  };
  return (
    <form className={styles.legalAttestationForm} ref={ref} noValidate>
      <FormControlLabel
        control={
          <Controller
            name={"hasAcceptedLegalAttestation"}
            control={control}
            rules={{ validate }}
            render={({ field }) => (
              <Checkbox
                className={styles.legalAttestationCheckbox}
                {...field}
                onChange={onChange(field)}
                checked={field.value}
                value={field.value}
              />
            )}
          />
        }
        label={
          <span className={styles.checkboxLabel}>
            {ElectionsLabels.LEGAL_ATTESTATION_CHECKBOX_LINE}
          </span>
        }
      />
    </form>
  );
};

export const LegalAttestationForm = forwardRef(LegalAttestationFormBase);
