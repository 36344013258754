import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  ElectionsLabels,
  getCurrencyFormattedValueWithDashDefault,
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardGridRow,
  isSomething,
  zeroIfNothing,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectElectCardData,
  selectElectCardGridTooltips,
  selectHasIVMoreThanOneStrategy,
  selectReallocationState,
} from "../../../../../redux/selectors";
import { selectCanUseFinancing } from "../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../redux/store";
import { ReviewAndSignFormQuestion } from "../ReviewAndSignFormQuestion/ReviewAndSignFormQuestion";
import styles from "./ElectionsCard.module.scss";
import { FinancingRows } from "./FinancingRows/FinancingRows";

export const ElectionsCard = () => {
  const hasForecastedInvestment = true;
  const electionData = useSelector(selectElectCardData);
  const tooltips = useSelector((state: InvestorUIStore) =>
    selectElectCardGridTooltips(state)
  );

  let nColsInTable = 3;
  nColsInTable += zeroIfNothing(electionData.totalRow.strategyMax) > 0 ? 1 : 0;
  nColsInTable += electionData.totalRow.remainingCommitment > 0 ? 1 : 0;
  nColsInTable += electionData.totalRow.mandatoryCommitment > 0 ? 1 : 0;
  nColsInTable +=
    zeroIfNothing(electionData.totalRow.optionalElection) > 0 ? 1 : 0;

  const useReallocation = useSelector(selectReallocationState);
  const canUseFinancing = useSelector(selectCanUseFinancing);
  const hasIVMoreThanOneStrategy = useSelector((state: InvestorUIStore) =>
    selectHasIVMoreThanOneStrategy(state)
  );

  return (
    <Stack>
      <TableContainer id={styles.electionsOverviewTable}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{ElectionsLabels.STRATEGY}</TableCell>
              {isSomething(electionData.totalRow.strategyMax) &&
                electionData.totalRow.strategyMax.value > 0 && (
                  <TableCell align={"right"}>
                    {ElectionsLabels.STRATEGY_MAXIMUM}
                  </TableCell>
                )}
              {electionData.totalRow.remainingCommitment > 0 && (
                <TableCell align={"right"}>
                  {ElectionsLabels.ANTICIPATED_PRIOR_PERIODS_CAPITAL_CALLS}
                </TableCell>
              )}
              {electionData.totalRow.mandatoryCommitment > 0 && (
                <TableCell align={"right"}>
                  <Typography className={styles.textWithSign}>
                    <span className={styles.sign}>
                      {electionData.totalRow.remainingCommitment > 0 &&
                        ElectionsLabels.PLUS_SIGN}
                    </span>
                    <b>
                      {tooltips.mandatoryCommitmentYear}{" "}
                      {ElectionsLabels.MANDATORY_COMMITMENT}
                    </b>
                  </Typography>
                </TableCell>
              )}
              {zeroIfNothing(electionData.totalRow.optionalElection) > 0 && (
                <TableCell align={"right"}>
                  <Typography
                    id={styles.optionalHeader}
                    className={styles.textWithSign}
                  >
                    <span className={styles.sign}>
                      {electionData.totalRow.mandatoryCommitment > 0 &&
                        ElectionsLabels.PLUS_SIGN}
                    </span>
                    <b>
                      {ElectionsLabels.OPT_ELECTION_HEADER_LINES(tooltips)[0]}
                      <br />
                      {ElectionsLabels.OPT_ELECTION_HEADER_LINES(tooltips)[1]}
                    </b>
                  </Typography>
                </TableCell>
              )}
              <TableCell align={"right"}>
                <Typography className={styles.textWithSign}>
                  <span className={styles.sign}>
                    {(electionData.totalRow.remainingCommitment > 0 ||
                      electionData.totalRow.mandatoryCommitment > 0 ||
                      zeroIfNothing(electionData.totalRow.optionalElection) >
                        0) &&
                      ElectionsLabels.EQUAL_SIGN}
                  </span>
                  <b>{ElectionsLabels.TOTAL_COMMITMENT}</b>
                </Typography>
              </TableCell>
              <TableCell align={"right"}>
                {ElectionsLabels.TOTAL_FORECASTED_INVESTMENT}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {electionData.electCardData.map(
              (electCardGridRow: IElectCardGridRow, idx: number) => {
                return (
                  <TableRow key={idx}>
                    <TableCell>{electCardGridRow.name}</TableCell>
                    {isSomething(electionData.totalRow.strategyMax) &&
                      electionData.totalRow.strategyMax.value > 0 && (
                        <TableCell align={"right"}>
                          {getCurrencyFormattedValueWithDashDefault(
                            electCardGridRow.strategyMax
                          )}
                        </TableCell>
                      )}
                    {electionData.totalRow.remainingCommitment > 0 && (
                      <TableCell align={"right"}>
                        {getCurrencyFormattedValueWithZeroDefault(
                          electCardGridRow.remainingCommitment
                        )}
                      </TableCell>
                    )}
                    {electionData.totalRow.mandatoryCommitment > 0 && (
                      <TableCell align={"right"}>
                        {getCurrencyFormattedValueWithZeroDefault(
                          electCardGridRow.mandatoryCommitment
                        )}
                      </TableCell>
                    )}
                    {isSomething(electionData.totalRow.optionalElection) &&
                      electionData.totalRow.optionalElection.value > 0 && (
                        <TableCell align={"right"}>
                          {getCurrencyFormattedValueWithZeroDefault(
                            zeroIfNothing(electCardGridRow.optionalElection)
                          )}
                        </TableCell>
                      )}
                    <TableCell align={"right"}>
                      {getCurrencyFormattedValueWithZeroDefault(
                        electCardGridRow.totalCommitment
                      )}
                    </TableCell>
                    <TableCell align={"right"}>
                      {getCurrencyFormattedValueWithZeroDefault(
                        electCardGridRow.totalForecastInvestment
                      )}
                    </TableCell>
                  </TableRow>
                );
              }
            )}
          </TableBody>
          <TableFooter>
            <TableRow className={styles.total}>
              <TableCell>{electionData.totalRow.name}</TableCell>
              {isSomething(electionData.totalRow.strategyMax) &&
                electionData.totalRow.strategyMax.value > 0 && (
                  <TableCell align={"right"}>
                    {getCurrencyFormattedValueWithZeroDefault(
                      electionData.totalRow.strategyMax.value
                    )}
                  </TableCell>
                )}
              {electionData.totalRow.remainingCommitment > 0 && (
                <TableCell align={"right"}>
                  {getCurrencyFormattedValueWithZeroDefault(
                    electionData.totalRow.remainingCommitment
                  )}
                </TableCell>
              )}
              {electionData.totalRow.mandatoryCommitment > 0 && (
                <TableCell align={"right"}>
                  {getCurrencyFormattedValueWithZeroDefault(
                    electionData.totalRow.mandatoryCommitment
                  )}
                </TableCell>
              )}
              {isSomething(electionData.totalRow.optionalElection) &&
                electionData.totalRow.optionalElection.value > 0 && (
                  <TableCell align={"right"}>
                    {getCurrencyFormattedValueWithZeroDefault(
                      electionData.totalRow.optionalElection.value
                    )}
                  </TableCell>
                )}
              <TableCell align={"right"}>
                {getCurrencyFormattedValueWithZeroDefault(
                  electionData.totalRow.totalCommitment
                )}
              </TableCell>
              <TableCell align={"right"}>
                {getCurrencyFormattedValueWithZeroDefault(
                  electionData.totalRow.totalForecastInvestment
                )}
              </TableCell>
            </TableRow>
            {canUseFinancing && (
              <FinancingRows
                financing={electionData.financing}
                hasForecastedInvestment={hasForecastedInvestment}
                nColsInTable={nColsInTable}
              />
            )}
          </TableFooter>
        </Table>
      </TableContainer>
      {hasIVMoreThanOneStrategy && (
        <ReviewAndSignFormQuestion
          title={ElectionsLabels.REALLOCATION}
          questionAnswerPairs={[
            {
              question: ElectionsLabels.REALLOCATION_QUESTION,
              answer:
                isSomething(useReallocation) && useReallocation.value
                  ? ElectionsLabels.YES
                  : ElectionsLabels.NO,
            },
          ]}
        />
      )}
    </Stack>
  );
};
