import { SvgIconTypeMap, Tooltip } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import React from "react";

import { TooltipPlacement } from "../../constants/enums";
import styles from "./IconWithTooltip.module.scss";

export interface IIconWithTooltipProps {
  icon: OverridableComponent<SvgIconTypeMap<unknown, "svg">> & {
    muiName: string;
  };
  hoverText: string;
  id?: string;
  className?: string;
  iconClassName?: string;
  placement?: TooltipPlacement;
}

export const IconWithTooltip = (props: IIconWithTooltipProps) => {
  const className = props.className
    ? `${styles.tooltip} ${props.className}`
    : styles.icon;
  const { placement = TooltipPlacement.BOTTOM_START } = props;

  return (
    <Tooltip
      id={props.id}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      title={props.hoverText}
      className={className}
      PopperProps={{
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -10],
            },
          },
        ],
      }}
      placement={placement}
    >
      <props.icon className={props.iconClassName} />
    </Tooltip>
  );
};
