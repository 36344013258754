import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    width: 531,
    alignItems: "center",
  },
  strategy: {
    width: 132,
  },
  remainingCommitment: {
    width: 70,
  },
  mandatoryCommitment: {
    width: 74,
  },
  election: {
    width: 92,
  },
  totalCommitment: {
    width: 97,
  },
  forecastedInvestment: {
    width: 84,
  },
});
