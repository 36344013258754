import { useCallback } from "react";
import { useDispatch } from "react-redux";

import { ErrorStringConstants } from "../constants/ErrorStringConstants";
import { markDocumentsAsDownloaded } from "../redux/actions/documentActions";
import { openAlert } from "../redux/actions/entitlementActions";
import { EnvironmentResolver } from "../services/environmentResolver";
import {
  buildDownloadableLink,
  getDocDownloadTimeZoneQueryParameters,
} from "../utils/documentUtils";

export const useDownloadSingleDocument = () => {
  const dispatch = useDispatch();

  const downloadDocument = useCallback(
    async (downloadUrl: string, documentName: string) => {
      // First do a JavaScript controlled HEAD request.
      // The server should return 200 if the file is available for download.
      const res = await fetch(downloadUrl, {
        method: "HEAD",
      });

      // If the HEAD request wasn't successful show the user an error message, and skip the actual request
      if (res.status !== 200) {
        dispatch(
          openAlert({
            severity: "error",
            message: ErrorStringConstants.DOWNLOAD_FAILED,
          })
        );
      } else {
        // otherwise create a hidden link and do a programmatic click to trigger real download
        // This is not a fetch request because we want the browser to handle downloads as it sees fit.
        // We hope that the HEAD request above caught any errors in advance 🤞
        buildDownloadableLink(downloadUrl, documentName);
      }

      return res.status;
    },
    [dispatch]
  );

  const downloadSingleDocument = useCallback(
    async (documentId: number, documentName = "BXWealth_document") => {
      const timeZoneQueryParams =
        getDocDownloadTimeZoneQueryParameters().toString();
      const downloadUrl = `${EnvironmentResolver.ENV.REACT_APP_DOCUMENTS_URL_BASE}/documents/${documentId}?${timeZoneQueryParams}`;

      const statusCode = await downloadDocument(downloadUrl, documentName);
      if (statusCode === 200) {
        // update document download date to reflect new download without triggered re-fetch from the API,
        // which causes problems on Safari because Safari suspends JS execution on downloading a document
        dispatch(markDocumentsAsDownloaded([documentId]));
      }

      return statusCode;
    },
    [dispatch, downloadDocument]
  );

  const downloadElectionSingleDocument = useCallback(
    async (
      electionRoundId: string,
      documentOId: number,
      documentName = "SBS_Election_Document"
    ) => {
      const timeZoneQueryParams =
        getDocDownloadTimeZoneQueryParameters().toString();
      const downloadUrl = `${EnvironmentResolver.ENV.REACT_APP_ELECTIONS_URL_BASE}/documents/${electionRoundId}/single/${documentOId}?${timeZoneQueryParams}`;
      return await downloadDocument(downloadUrl, documentName);
    },
    [downloadDocument]
  );

  return {
    downloadSingleDocument,
    downloadElectionSingleDocument,
  };
};
