import { Close } from "@mui/icons-material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IconButton, Link, Stack, Typography } from "@mui/material";
import {
  ColumnDefinition,
  ColumnState,
  ColumnStates,
  copyColumnStates,
  createDocumentRequest,
  DefaultColumnDefinitions,
  deselectAllDocuments,
  DocumentColumnName,
  DocumentColumnSectionOrder,
  DocumentFilterComponents,
  DocumentGridColumnKeys,
  DocumentGridRow,
  DocumentsLabel,
  filterDocumentTypesForSelectedDocumentTypeCategories,
  FilterPanelDocumentSections,
  Flatten,
  isSomething,
  Optional,
  PossibleDocumentColumns,
  reqDocuments,
  resetAllColumnFilterValues,
  setColumnFilterValue,
  setColumnFilterValues,
  updateDocumentColumnSettings,
} from "common";
import React, { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../../redux/store";
import { DateRangeFilter } from "../Filters/DateRangeFilter/DateRangeFilter";
import { DocumentTypeCategoryFilter } from "../Filters/DocumentTypeCategoryFilter/DocumentTypeCategoryFilter";
import { DocumentTypeFilter } from "../Filters/DocumentTypeFilter/DocumentTypeFilter";
import { EntityFilter } from "../Filters/EntityFilter/EntityFilter";
import { PeriodFilter } from "../Filters/PeriodFilter/PeriodFilter";
import { TaxDocumentStatusFilter } from "../Filters/TaxDocumentStatusFilter/TaxDocumentStatusFilter";
import styles from "./DocumentsGridFilterPanel.module.scss";

export interface IDocumentsGridFilterPanel {
  handleClose: () => void;
}

export const DocumentsGridFilterPanel = (props: IDocumentsGridFilterPanel) => {
  const { handleClose } = props;

  const dispatch = useDispatch();
  const {
    activeDocumentClient,
    columnStates,
    selectedDocuments,
    pageSize,
    searchTerm,
    sortOptions,
  } = useSelector((store: InvestorUIStore) => store.documentSettings);

  // Store a copy of the current active filters
  const originalColumns = useMemo(() => {
    const copy = copyColumnStates(columnStates);
    return copy;
  }, [columnStates]);

  // initialize current selections based on the original filters
  const [currentSelections, setCurrentSelections] =
    useState<ColumnStates>(originalColumns);

  // update current selections to update what is displayed in the panel
  const handleChange = <
    K extends DocumentColumnName,
    T extends Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
  >(
    columnName: K,
    value: T | T[],
    errors: string[],
    index?: number
  ) => {
    const newSelections = copyColumnStates(currentSelections);

    if (index !== undefined && !Array.isArray(value)) {
      // Update value at a specific index
      setColumnFilterValue(newSelections[columnName], value, index);
    } else {
      // Update values
      const newValues = Array.isArray(value) ? value : [value];
      setColumnFilterValues(newSelections[columnName], newValues);
    }

    // Update errors
    newSelections[columnName].filter.errors = errors;

    // if there's an update to document type category values,
    if (columnName === DocumentGridColumnKeys.DOCUMENT_TYPE_CATEGORY) {
      // Filter document type column's selected values in case previously selected ones got filtered out
      setColumnFilterValues(
        newSelections.documentType,
        filterDocumentTypesForSelectedDocumentTypeCategories(
          newSelections.documentType.filter.values,
          newSelections.documentTypeCategory.filter.values
        )
      );
    }

    setCurrentSelections(newSelections);
  };

  const fetchDocuments = useCallback(
    (columnStates: ColumnStates) => {
      const request = createDocumentRequest(
        1,
        pageSize,
        columnStates,
        searchTerm,
        sortOptions,
        activeDocumentClient
      );
      if (isSomething(request)) {
        dispatch(reqDocuments(request.value));
      }
    },
    [dispatch, pageSize, searchTerm, sortOptions, activeDocumentClient]
  );

  const handleApplyFilters = () => {
    dispatch(updateDocumentColumnSettings(currentSelections));
    dispatch(deselectAllDocuments());
    fetchDocuments(currentSelections);
    handleClose();
  };
  const handleResetFilters = () => {
    const newSelections = copyColumnStates(currentSelections);
    resetAllColumnFilterValues(newSelections);
    setCurrentSelections(newSelections);
    dispatch(updateDocumentColumnSettings(newSelections));
    fetchDocuments(newSelections);
    handleClose();
  };

  // If any filter input has errors
  const hasErrors = useMemo(() => {
    return PossibleDocumentColumns.some(
      (column) => currentSelections[column].filter.errors.length > 0
    );
  }, [currentSelections]);

  const filteredDocumentTypeOptions = useMemo(() => {
    return filterDocumentTypesForSelectedDocumentTypeCategories(
      columnStates.documentType.filter.options,
      currentSelections.documentTypeCategory.filter.values
    );
  }, [
    columnStates.documentType.filter.options,
    currentSelections.documentTypeCategory.filter.values,
  ]);

  const DocumentFilters: DocumentFilterComponents = {
    publishedDate: (columnDefinition, columnState, onChange) =>
      DateRangeFilter({ columnDefinition, columnState, onChange }),
    effectiveDate: (columnDefinition, columnState, onChange) =>
      DateRangeFilter({ columnDefinition, columnState, onChange }),
    documentTypeCategory: (columnDefinition, columnState, onChange) =>
      DocumentTypeCategoryFilter({ columnDefinition, columnState, onChange }),
    documentType: (columnDefinition, columnState, onChange) =>
      // for document type, options should be filtered to user's selected document type category values
      DocumentTypeFilter({
        columnDefinition,
        columnState: {
          ...columnState,
          filter: {
            ...columnState.filter,
            options: filteredDocumentTypeOptions,
          },
        },
        onChange,
      }),
    funds: (columnDefinition, columnState, onChange) =>
      EntityFilter({ columnDefinition, columnState, onChange }),
    taxDocumentStatus: (columnDefinition, columnState, onChange) =>
      TaxDocumentStatusFilter({ columnDefinition, columnState, onChange }),
    period: (columnDefinition, columnState, onChange) =>
      PeriodFilter({ columnDefinition, columnState, onChange }),
    partnerships: (columnDefinition, columnState, onChange) =>
      EntityFilter({ columnDefinition, columnState, onChange }),
  };

  // typesafe retrievel of a filter that matches a column name.
  const getFilterComponent = <K extends DocumentColumnName>(
    columnDefinition: ColumnDefinition<K>,
    columnState: ColumnState<K>,
    onChange: (
      column: K,
      value:
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>
        | Optional<Flatten<NonNullable<DocumentGridRow[K]>>>[],
      errors: string[],
      index?: number
    ) => void
  ): JSX.Element | undefined => {
    const filter = DocumentFilters[columnDefinition.type];

    if (filter !== undefined) {
      return filter(columnDefinition, columnState, onChange);
    }
  };

  return (
    <div className={styles.filterPanel}>
      <div className={styles.header}>
        <div className={styles.label}>
          <FilterAltOutlinedIcon />
          <Typography variant={"h5"}>{DocumentsLabel.FILTERS}</Typography>
        </div>
        <IconButton
          className={styles.close}
          onClick={() => {
            handleClose();
          }}
        >
          <Close />
        </IconButton>
      </div>
      <div className={styles.panelContent}>
        {DocumentColumnSectionOrder.map((sectionHeader, sectionIndex) => {
          const sectionColumns = FilterPanelDocumentSections[sectionHeader];
          if (
            sectionColumns.some(
              (columnName) => !currentSelections[columnName].filter.disabled
            )
          ) {
            return (
              <div className={styles.sectionContainer} key={sectionIndex}>
                <Typography variant={"h4"} className={styles.sectionHeader}>
                  {sectionHeader.toUpperCase()}
                </Typography>
                {sectionColumns.map((columnName) => {
                  const columnDefinition = DefaultColumnDefinitions[columnName];
                  const columnState = currentSelections[columnName];
                  if (!columnState.filter.disabled) {
                    return (
                      <Stack
                        direction="column"
                        key={columnName}
                        className={styles.filterItem}
                      >
                        <Typography variant={"h6"}>
                          {columnDefinition.label}
                        </Typography>
                        {getFilterComponent(
                          columnDefinition,
                          columnState,
                          handleChange
                        )}
                      </Stack>
                    );
                  }
                })}
              </div>
            );
          }
        })}
      </div>
      <Stack direction="row" className={styles.buttons}>
        <Typography variant={"h5"} className={styles.reset}>
          <Link
            component={"button"}
            className={styles.text}
            onClick={handleResetFilters}
          >
            {DocumentsLabel.RESET_FILTERS}
          </Link>
        </Typography>
        <Stack
          direction="column"
          alignItems="flex-end"
          textAlign="right"
          className={styles.apply}
        >
          <Typography variant={"h5"}>
            <Link
              component={"button"}
              className={styles.text}
              onClick={handleApplyFilters}
              disabled={hasErrors}
              title={
                hasErrors ? DocumentsLabel.PLEASE_RESOLVE_ERRORS : undefined
              }
            >
              {DocumentsLabel.APPLY_FILTERS}
            </Link>
          </Typography>
          {Object.keys(selectedDocuments).length > 0 && (
            <Typography variant="subtitle1" className={styles.selectionWarning}>
              {DocumentsLabel.APPLY_FILTER_WARNING}
            </Typography>
          )}
        </Stack>
      </Stack>
    </div>
  );
};
