export const getTimeZoneOffset = () => {
  return new Date().getTimezoneOffset();
};

export const getTimeZoneShortName = () => {
  const formatter = new Intl.DateTimeFormat("en-US", {
    timeZoneName: "shortGeneric",
  });

  // Should never be null as we explicitly requested the generation
  // of a timeZoneName above.
  return (
    formatter
      .formatToParts(new Date())
      .find((part) => part.type === "timeZoneName")?.value ?? ""
  );
};
