import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { InputBaseComponentProps } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";

export const NumericFormatInput = React.forwardRef(function NumericFormatInput(
  props: InputBaseComponentProps,
  ref
) {
  const { ...other } = props;

  return (
    <NumericFormat
      {...other}
      defaultValue={undefined}
      getInputRef={ref}
      thousandSeparator
      valueIsNumericString
      allowNegative={false}
    />
  );
});
