import {
  hasOrIsLoadingCommitmentData,
  IInternalInvestmentDataStore,
  IPermittedClient,
  isSomething,
  Optional,
} from "common";

import { PageName } from "../../constants/Pages/Pages";

export const isPageVisible = (
  pageName: PageName,
  activeInternalInvestmentDataClientId: Optional<string>,
  activeEquityDataEntitlements: string[],
  hasViewDocumentsEntitlement: boolean,
  canViewCommitmentsPage: boolean,
  permittedElectionsClients: IPermittedClient[]
) => {
  switch (pageName) {
    case PageName.INVESTMENTS_AND_CARRY:
    case PageName.DASHBOARD:
      return isSomething(activeInternalInvestmentDataClientId);
    case PageName.COMMITMENTS:
      return canViewCommitmentsPage;
    case PageName.EQUITY:
      return activeEquityDataEntitlements.length > 0;
    case PageName.DOCUMENTS:
      return hasViewDocumentsEntitlement;
    case PageName.ELECTION_WORKFLOW:
    case PageName.ELECTIONS_LIST:
    case PageName.REVIEW:
      return permittedElectionsClients.length > 0;
  }
};

export const checkCanViewCommitmentsPage = (
  internalInvestmentData: IInternalInvestmentDataStore,
  activeInternalInvestmentDataClientId: Optional<string>,
  hasViewCommitmentsEntitlement: boolean
): boolean => {
  // to see commitments, user needs to have access to internal investments data + have commitments data
  // additionally, temporarily gate page by commitments feature flag on top of that.
  return (
    hasViewCommitmentsEntitlement &&
    isSomething(activeInternalInvestmentDataClientId) &&
    hasOrIsLoadingCommitmentData(internalInvestmentData)
  );
};
