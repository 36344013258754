import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Typography } from "@mui/material";
import {
  ElectionsLabels,
  getCurrencyFormattedValueWithZeroDefault,
  Optional,
  zeroIfNaN,
  zeroIfNothing,
} from "common";
import React from "react";

import styles from "./ElectionTotalRow.module.scss";

interface IElectionFinancingCellProps {
  value: Optional<number>;
  optElectionPct: number;
}

/*
Election column in the total row has three possible forms:
    1. when a user cannot use financing, just display the total optional election
    2. when a user can use financing display total optional election, cash, 
            financing, existing loan balance, projected loan balance, and
            loan limit
*/
export const ElectionTotalRow = (props: IElectionFinancingCellProps) => {
  const { value, optElectionPct } = props;

  const totalElection = zeroIfNothing(value);
  return (
    <div id={styles.total}>
      <div className={styles.election}>
        {getCurrencyFormattedValueWithZeroDefault(zeroIfNaN(totalElection))}
        <Typography id={styles.percent}>{`${optElectionPct}%`}</Typography>
      </div>
      <Typography id={styles.label}>{ElectionsLabels.CASH}</Typography>
      <Typography id={styles.label}>{ElectionsLabels.FINANCING}</Typography>
      <hr className={styles.line} />
      <Typography id={styles.label}>
        {ElectionsLabels.EXISTING_LOAN_BALANCE}
      </Typography>
      <Typography id={styles.label}>
        {ElectionsLabels.PROJECTED_LOAN_BALANCE}
      </Typography>
      <Typography id={styles.label}>{ElectionsLabels.LOAN_LIMIT}</Typography>
    </div>
  );
};
