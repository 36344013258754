import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { ElectionAccordionObject } from "common";
import React from "react";

import {
  GetIsExpanded,
  useHandleExpand,
} from "../../../../../common/hooks/electionHooks";
import { CardProperties, ElectionCard } from "../ElectionCard";
import styles from "./AccordionElectionCard.module.scss";

type AccordionElectionCardProps = Omit<CardProperties, "children"> & {
  headerComponent: JSX.Element;
  cardContentComponent: JSX.Element;
  accordionId: ElectionAccordionObject;
};

export const AccordionElectionCard = (props: AccordionElectionCardProps) => {
  const {
    headerComponent,
    cardContentComponent,
    accordionId,
    additionalDataLoadStatuses,
    additionalRetryFunctions,
    hide,
    hideIfNoData,
    failed,
    loading,
    noData,
    onReload,
  } = props;

  const isExpanded = GetIsExpanded(accordionId);

  const handleExpand = useHandleExpand(accordionId, isExpanded);

  return (
    <ElectionCard
      className={styles.accordionCard}
      additionalRetryFunctions={additionalRetryFunctions}
      additionalDataLoadStatuses={additionalDataLoadStatuses}
      failed={failed}
      loading={loading}
      noData={noData}
      onReload={onReload}
      hide={hide}
      hideIfNoData={hideIfNoData}
    >
      <Accordion
        className={styles.accordion}
        expanded={isExpanded}
        onChange={handleExpand}
      >
        <AccordionSummary expandIcon={<ExpandMore />}>
          {headerComponent}
        </AccordionSummary>
        <AccordionDetails>{cardContentComponent}</AccordionDetails>
      </Accordion>
    </ElectionCard>
  );
};
