import {
  PathFragment,
  SectionHeader,
  SubTabPageName,
  SubTabPages,
} from "common";
import React from "react";

import { DistributionsWrapper } from "../../features/Equity/DistributionsWrapper/DistributionsWrapper";
import { VestingEventsWrapper } from "../../features/Equity/VestingEventsWrapper/VestingEventsWrapper";
import { InvestmentsOverviewWrapper } from "../../features/InvestmentsAndCarry/InvestmentsOverviewWrapper/InvestmentsOverviewWrapper";
import { PerformanceDetailsPageWrapper } from "../../features/PerformanceDetailsPage/PerformanceDetailsPageWrapper";

export const InvestmentsPages: SubTabPages = {
  InvestmentsOverview: {
    name: SectionHeader.OVERVIEW,
    path: `${PathFragment.OVERVIEW}`,
    hasError: false,
    header: SectionHeader.OVERVIEW,
    element: InvestmentsOverviewWrapper,
  },
  InvestmentBreakdown: {
    name: SectionHeader.INVESTMENT_BREAKDOWN,
    path: `${PathFragment.INVESTMENT_BREAKDOWN}`,
    hasError: false,
    header: SectionHeader.INVESTMENT_BREAKDOWN,
    element: () => <PerformanceDetailsPageWrapper hideHeader={true} />,
  },
};

export const InvestmentsTabsDisplayOrder: SubTabPageName[] = [
  SubTabPageName.INVESTMENTS_OVERVIEW,
  SubTabPageName.INVESTMENT_BREAKDOWN,
];

export const EquityPages: SubTabPages = {
  VestingEvents: {
    name: SectionHeader.EQUITY_VALUE,
    path: `${PathFragment.EQUITY_VALUE}`,
    hasError: false,
    header: SectionHeader.EQUITY_VALUE,
    element: VestingEventsWrapper,
  },
  Distributions: {
    name: SectionHeader.DISTRIBUTIONS,
    path: `${PathFragment.DISTRIBUTIONS}`,
    hasError: false,
    header: SectionHeader.DISTRIBUTIONS,
    element: DistributionsWrapper,
  },
};

export const EquityTabsDisplayOrder: SubTabPageName[] = [
  SubTabPageName.VESTING_EVENTS,
  SubTabPageName.DISTRIBUTIONS,
];
