import { useLocation } from "react-router-dom";

// Returns a function which can use regEx or string matching to determine if
// a provided path matches the current location's path. Similar to react-router-doms
// useMatch hook, but allows for us to use RegEx
export const useDoesCurrentPathMatch = () => {
  const location = useLocation();

  return (pathToTest: RegExp | string) => {
    return location.pathname.match(pathToTest)?.length === 1;
  };
};
