import { Dashboard, IAuthorizedComponentProps } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../redux/store";

const DashboardWrapper = (props: IAuthorizedComponentProps) => {
  // components renders only if user is already authenticated
  const { user } = props;

  const entitlements = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );

  return (
    <Dashboard
      user={user}
      hasViewStockEntitlement={entitlements.hasViewStockEntitlement}
      hasViewRestrictedEquity={entitlements.hasViewRestrictedEquityEntitlement}
    />
  );
};

export default DashboardWrapper;
