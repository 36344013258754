import { Edit, FileDownloadOutlined } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import {
  buildIVByElectionRoundTitle,
  DataLoadStatus,
  ElectionsLabels,
  ElectionWorkflowStageId,
  IGetElectionDetailsRequestPayload,
  isSomething,
  setActiveElection,
} from "common";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useDownloadElectionAgreement } from "../../../hooks/pdfHooks";
import { selectElectionRoundDocuments } from "../../../redux/selectors";
import { InvestorUIStore } from "../../../redux/store";
import { ElectionsCard } from "../ElectionWorkflow/ReviewAndSignStage/ElectionsCard/ElectionsCard";
import { ElectionCard } from "../ElectionWorkflow/Shared/ElectionCard";
import { BackToElectionsButton } from "../Shared/BackToElectionsButton/BackToElectionsButton";
import { DownloadDocumentButton } from "../Shared/DownloadDocumentButton/DownloadDocumentButton";
import { TaxForms } from "../Shared/TaxForms/TaxForms";
import styles from "./ElectionReview.module.scss";
import { ReopenElectionModal } from "./ReopenElectionModal/ReopenElectionModal";

export const Review = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { electionRoundId, investmentVehicleId } = useParams();

  const {
    electionRoundConfiguration,
    activeElection,
    electionIVConfiguration,
    electionsForClientLoadStatus,
    electionWorkflowStateLocal,
  } = useSelector((state: InvestorUIStore) => state.electionsData);

  const documents = useSelector(selectElectionRoundDocuments);

  const [isReviseModalOpen, setIsReviseModalOpen] = useState<boolean>(false);

  const downloadElectionAgreement = useDownloadElectionAgreement();

  const handleClickDownloadPDF = async () => {
    if (isSomething(activeElection)) {
      await downloadElectionAgreement(
        activeElection.value.electionRoundId,
        activeElection.value.investmentVehicleId
      );
    }
  };

  useEffect(() => {
    if (
      electionRoundId !== undefined &&
      investmentVehicleId !== undefined &&
      !isNaN(parseInt(investmentVehicleId))
    ) {
      const ivId: number = parseInt(investmentVehicleId);
      const currentElection: IGetElectionDetailsRequestPayload = {
        electionRoundId,
        investmentVehicleId: ivId,
      };

      dispatch(setActiveElection(currentElection));
    }
  }, [dispatch, electionRoundId, investmentVehicleId]);

  useEffect(() => {
    if (isSomething(electionWorkflowStateLocal)) {
      if (
        electionWorkflowStateLocal.value.currentStage ===
          ElectionWorkflowStageId.UNKNOWN ||
        electionWorkflowStateLocal.value.currentStage ===
          ElectionWorkflowStageId.NOT_STARTED
      ) {
        return navigate(`/sbs-elections`);
      }
      if (
        electionWorkflowStateLocal.value.currentStage <
        ElectionWorkflowStageId.COMPLETED
      ) {
        return navigate(
          `/sbs-elections/${investmentVehicleId}/${electionRoundId}`
        );
      }
    }
  }, [
    electionWorkflowStateLocal,
    navigate,
    investmentVehicleId,
    electionRoundId,
  ]);

  const showReviseButton = useMemo(() => {
    if (electionsForClientLoadStatus !== DataLoadStatus.SUCCESSFUL) {
      return false;
    }

    if (isSomething(electionRoundConfiguration)) {
      const currentTime = new Date();
      return (
        currentTime.valueOf() <
        electionRoundConfiguration.value.electionSubmissionDeadline.valueOf()
      );
    }
  }, [electionRoundConfiguration, electionsForClientLoadStatus]);

  const handleClickReviseElection = () => {
    setIsReviseModalOpen(true);
  };

  return (
    <div className={styles.page}>
      <div className={styles.pageContent}>
        <BackToElectionsButton />
        <ElectionCard className={styles.electionCard}>
          <div className={styles.title}>
            <Stack direction={"row"} alignItems={"center"} spacing={"16px"}>
              <Typography variant="h1">
                {buildIVByElectionRoundTitle(
                  electionIVConfiguration,
                  electionRoundConfiguration
                )}
              </Typography>
              {electionRoundId !== undefined && (
                <DownloadDocumentButton
                  outline={true}
                  documents={documents}
                  electionRoundId={electionRoundId}
                />
              )}
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              spacing={"26px"}
            >
              <Button
                onClick={handleClickDownloadPDF}
                endIcon={<FileDownloadOutlined />}
              >
                {ElectionsLabels.DOWNLOAD_SIGNED_ELECTION_AGREEMENT}
              </Button>
              {showReviseButton && (
                <Button onClick={handleClickReviseElection} endIcon={<Edit />}>
                  {ElectionsLabels.REVISE_ELECTION}
                </Button>
              )}
            </Stack>
          </div>
          <ElectionsCard />
          <hr className={styles.footerLine} />
          <TaxForms />
        </ElectionCard>
        <ReopenElectionModal
          open={isReviseModalOpen}
          setOpen={setIsReviseModalOpen}
        />
      </div>
    </div>
  );
};
