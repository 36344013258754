import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@mui/material";
import {
  ElectionsLabels,
  returnOptionalBooleanFromFormValue,
  setIsSBSElectionSaveEnabled,
  updateResidentialInformation,
} from "common";
import React, { useCallback, useEffect, useState } from "react";
import { Control, Controller, FieldPath, UseFormReturn } from "react-hook-form";
import { useDispatch } from "react-redux";

import styles from "../../ElectionWorkflowPage.module.scss";
import { ResidentialInformationState } from "../Eligibility";

interface IResidentialInformationFormProps {
  form: UseFormReturn<ResidentialInformationState>;
}

export const ResidentialInformationForm: React.FC<
  IResidentialInformationFormProps
> = ({ form }) => {
  const [showStateResidence, setShowStateResidence] = useState(false);
  const dispatch = useDispatch();
  const handleChange = () => {
    dispatch(setIsSBSElectionSaveEnabled(true));
  };

  const validate = (
    _: string | null,
    value: ResidentialInformationState
  ): boolean | string => {
    if (value.isUsResident === null) {
      return "";
    } else if (
      value.isUsResident === true.toString() &&
      value.isNyResident === null
    ) {
      return "";
    }
    return true;
  };

  const ResidentialInformationQuestion = ({
    title,
    name,
    control,
  }: {
    title: string;
    name: FieldPath<ResidentialInformationState>;
    control: Control<ResidentialInformationState>;
  }) => {
    return (
      <FormControl className={styles.electionFormControl} required={true}>
        <FormLabel id={name + "-label"} className={styles.label}>
          <span>{title}</span>
        </FormLabel>
        <Controller
          control={control}
          name={name}
          rules={{ validate: validate }}
          render={({ field }) => (
            <RadioGroup {...field} row aria-labelledby={name + "-label"}>
              <FormControlLabel
                className="radioForm"
                value={true}
                control={<Radio />}
                label={ElectionsLabels.YES}
                onChange={handleChange}
              />
              <FormControlLabel
                className="radioForm"
                value={false}
                control={<Radio />}
                label={ElectionsLabels.NO}
                onChange={handleChange}
              />
            </RadioGroup>
          )}
        />
      </FormControl>
    );
  };

  const isUsResident = form.watch("isUsResident");
  const isNyResident = form.watch("isNyResident");

  useEffect(() => {
    if (isUsResident === false.toString()) {
      form.setValue("isNyResident", null);
    }
    setShowStateResidence(isUsResident === true.toString());
  }, [isUsResident, form]);

  const dispatchUpdateResidentialInformationFunc = useCallback(
    (residentialInformationValue: ResidentialInformationState) => {
      dispatch(
        updateResidentialInformation({
          isUSResident: returnOptionalBooleanFromFormValue(
            residentialInformationValue.isUsResident
          ),
          isNYResident: returnOptionalBooleanFromFormValue(
            residentialInformationValue.isNyResident
          ),
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatchUpdateResidentialInformationFunc({ isUsResident, isNyResident });
  }, [isUsResident, isNyResident, dispatchUpdateResidentialInformationFunc]);

  return (
    <Stack
      id="questionsForm"
      flexDirection="column"
      justifyContent="left"
      alignItems="left"
    >
      <ResidentialInformationQuestion
        title={ElectionsLabels.COUNTRY_RESIDENT_TEXT}
        name="isUsResident"
        control={form.control}
      />

      {showStateResidence && (
        <ResidentialInformationQuestion
          title={ElectionsLabels.STATE_RESIDENT_TEXT}
          name="isNyResident"
          control={form.control}
        />
      )}
    </Stack>
  );
};
