import { PayloadAction } from "@reduxjs/toolkit";
import { call, put } from "redux-saga/effects";

import { DataLoadStatus } from "../../constants/enums";
import {
  DocumentRequest,
  DocumentServerResponse,
  getDocumentClients,
  getDocumentFilters,
  getDocumentsForClient,
  IDocumentClientsSource,
  IDocumentFiltersSource,
} from "../../services/documentsService";
import {
  IDocument,
  IDocumentsClient,
  IDocumentType,
  IDocumentTypeCategory,
  IEntity,
} from "../../types/dataTypes";
import { EMPTY_DOCUMENT_RESPONSE } from "../../types/defaultTypes";
import { DocumentColumnFilterOptions } from "../../types/documentSettingTypes";
import {
  Json,
  LoadError,
  Maybe,
  nothing,
  Optional,
  some,
} from "../../types/typeUtils";
import {
  convertToDateObject,
  convertToShortDateObject,
} from "../../utils/converters";
import {
  errAvailableDocumentFilters,
  errDocumentClients,
  errDocuments,
  recvDocumentClients,
  recvDocuments,
  reqDocuments,
  setSelectedDocumentClient,
  updateDocumentAvailableFilterConfig,
} from "../actions/documentActions";

/*
helper function to search permitted clients list for one to match the preferredClient OId
  returns undefined if none found (i.e. user's preferred client is one they don't actually have permissions to)
*/
const getDefaultClient = (
  documentClients: IDocumentsClient[],
  preferredOId: string
): Optional<IDocumentsClient> => {
  // filter list by preferred client OId
  const defaultClientList = documentClients.filter(
    (client: IDocumentsClient) => client.oId === preferredOId
  );
  if (defaultClientList.length === 1) {
    // we expect exactly 1 match to be found
    return some(defaultClientList[0]);
  } else {
    return nothing;
  }
};

export function* fetchDocumentsClients(action: PayloadAction<boolean>) {
  try {
    if (action.payload) {
      const documentsClients: Maybe<IDocumentClientsSource> = yield call(
        getDocumentClients
      );
      if (
        documentsClients === undefined ||
        documentsClients.clients.length === 0
      ) {
        yield put(recvDocumentClients(nothing));
        return;
      } else {
        const convert = (
          documentsClient: Json<IDocumentsClient>
        ): IDocumentsClient => ({
          oId: documentsClient.oId,
          name: documentsClient.name,
        });

        const convertedDocumentsClients = documentsClients.clients
          .map((data) => convert(data))
          .sort();

        const defaultClientOId = documentsClients.preferredClientOId;

        yield put(
          recvDocumentClients(
            some({
              clients: convertedDocumentsClients,
              preferredClientOId: defaultClientOId,
            })
          )
        );

        yield put(
          setSelectedDocumentClient(
            getDefaultClient(convertedDocumentsClients, defaultClientOId)
          )
        );

        if (defaultClientOId !== null) {
          // we want to proactively fetch documents immediately upon load of entitlements!
          yield put(
            reqDocuments({
              clientOId: defaultClientOId,
              body: {
                offset: 0,
                limit: 50,
                filters: {},
                searchTerm: undefined,
                sort: undefined,
              },
            })
          );
        }
      }
    } else {
      yield put(setSelectedDocumentClient(nothing));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      // TODO: may want to revisit this if we get new error logic
      yield put(errDocumentClients(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

export function* fetchAvailableDocumentFilters(
  action: PayloadAction<IDocumentsClient>
) {
  try {
    const availableDocumentFilters: Maybe<IDocumentFiltersSource> = yield call(
      getDocumentFilters,
      action.payload.oId
    );

    if (availableDocumentFilters === undefined) {
      return;
    } else {
      /*
      helper function to de-duplicate list of document type categories
      */
      const deDupDocTypeCategory = (
        docTypeCategories: IDocumentTypeCategory[]
      ): Optional<IDocumentTypeCategory>[] => {
        return [
          ...new Map(
            docTypeCategories.map((docTypeCategory) => [
              docTypeCategory.id,
              some(docTypeCategory),
            ])
          ).values(),
        ];
      };

      /*
      helper function to convert json response object into DocumentColumnFilterOptions object
      */
      const convert = (
        docFilters: IDocumentFiltersSource
      ): DocumentColumnFilterOptions => {
        return {
          documentType: docFilters.documentTypes.map(
            (docType: Json<IDocumentType>) => {
              return some({
                name: docType.name,
                id: docType.id,
                category: {
                  name: docType.category.name,
                  id: docType.category.id,
                },
              });
            }
          ),
          documentTypeCategory: deDupDocTypeCategory(
            docFilters.documentTypes.map((docType: Json<IDocumentType>) => {
              return {
                name: docType.category.name,
                id: docType.category.id,
              };
            })
          ),
          period: docFilters.periods.map((value) => some(value)),
          investmentVehicles: docFilters.investmentVehicles.map(
            (docIV: Json<IEntity>) => {
              return some({
                name: docIV.name,
                oId: docIV.oId,
              });
            }
          ),
          funds: docFilters.funds.map((docFund: Json<IEntity>) => {
            return some({
              name: docFund.name,
              oId: docFund.oId,
            });
          }),
          taxDocumentStatus: docFilters.taxDocumentStatuses.map((value) =>
            some(value)
          ),
          partnerships: docFilters.partnerships.map(
            (docPartnership: Json<IEntity>) => {
              return some({
                name: docPartnership.name,
                oId: docPartnership.oId,
                ein: docPartnership.ein,
              });
            }
          ),
        };
      };

      const convertedDocFilters = convert(availableDocumentFilters);
      yield put(updateDocumentAvailableFilterConfig(convertedDocFilters));
    }
  } catch (e) {
    if (e instanceof LoadError) {
      // TODO: may want to revisit this if we get new error logic
      yield put(errAvailableDocumentFilters(DataLoadStatus.EMPTY_RESPONSE));
    }
  }
}

const parseAndSortEntityList = (entityList: Json<IEntity>[]): IEntity[] => {
  return entityList
    .map((entity: Json<IEntity>) => {
      return {
        name: entity.name,
        oId: entity.oId,
        ein: entity.ein,
      };
    })
    .sort((entityA: IEntity, entityB: IEntity) => {
      return entityA.name < entityB.name ? -1 : 1;
    });
};

const convertDocument = (serverDocument: Json<IDocument>): IDocument => {
  return {
    documentOId: serverDocument.documentOId,
    documentName: serverDocument.documentName,
    documentType: {
      name: serverDocument.documentType.name,
      id: serverDocument.documentType.id,
      category: {
        name: serverDocument.documentType.category.name,
        id: serverDocument.documentType.category.id,
      },
    },
    period: serverDocument.period,
    funds: parseAndSortEntityList(serverDocument.funds),
    publishedDate: convertToShortDateObject(serverDocument.publishedDate),
    investmentVehicles: parseAndSortEntityList(
      serverDocument.investmentVehicles
    ),
    taxDocumentStatus: serverDocument.taxDocumentStatus,
    jurisdictions: serverDocument.jurisdictions.sort(),
    partnerships: parseAndSortEntityList(serverDocument.partnerships),
    effectiveDate: serverDocument.effectiveDate
      ? convertToShortDateObject(serverDocument.effectiveDate)
      : null,
    downloadedDate: serverDocument.downloadedDate
      ? convertToDateObject(serverDocument.downloadedDate)
      : null,
  };
};

export function* fetchDocuments(action: PayloadAction<DocumentRequest>) {
  try {
    const documentsResponse: Maybe<DocumentServerResponse> = yield call(
      getDocumentsForClient,
      action.payload
    );

    if (documentsResponse === undefined) {
      put(recvDocuments(EMPTY_DOCUMENT_RESPONSE));
    } else {
      yield put(
        recvDocuments({
          ...documentsResponse,
          documents: documentsResponse.documents.map(convertDocument),
        })
      );
    }
  } catch (e) {
    if (e instanceof LoadError) {
      yield put(errDocuments());
    }
  }
}
