import { Select, SelectProps } from "@mui/material";
import React from "react";

import styles from "./DataFilterSelect.module.scss";

const MenuProps: SelectProps["MenuProps"] = {
  marginThreshold: 0,
  disableScrollLock: true,
};

export const DataFilterSelect = <T,>(props: SelectProps<T>) => {
  const { className, ...otherProps } = props;
  return (
    <Select
      className={`${styles.overriddenSelect} ${className}`}
      MenuProps={MenuProps}
      {...otherProps}
    />
  );
};

export default DataFilterSelect;
