import {
  DocumentsLabel,
  Flatten,
  IDocumentType,
  isSomething,
  MultiSelect,
  Optional,
} from "common";
import React, { useEffect, useState } from "react";

import {
  FilterComponentProps,
  stringIdentityFunc,
} from "../shared/FilterComponentProps";

export const DocumentTypeFilter = <
  K extends "documentType",
  T extends IDocumentType
>(
  props: FilterComponentProps<K, T>
) => {
  const mapDocumentTypeToString = (
    list: Optional<IDocumentType>[]
  ): string[] => {
    const filteredList: string[] = [];
    list.forEach((value: Optional<IDocumentType>) => {
      if (isSomething(value)) {
        filteredList.push(value.value.name);
      }
    });
    return filteredList;
  };
  const [selections, setSelections] = useState<string[]>(
    mapDocumentTypeToString(
      props.columnState.filter.values.filter(
        (value) =>
          isSomething(value) &&
          props.columnState.filter.options.find(
            (option) =>
              isSomething(option) && option.value.name == value.value.name
          )
      )
    )
  );

  useEffect(() => {
    setSelections(
      mapDocumentTypeToString(
        props.columnState.filter.values.filter(
          (value) =>
            isSomething(value) &&
            props.columnState.filter.options.find(
              (option) =>
                isSomething(option) && option.value.name == value.value.name
            )
        )
      )
    );
  }, [props.columnState.filter.options, props.columnState.filter.values]);

  const onChange = (newValuesNames: string[]) => {
    if (newValuesNames.includes(DocumentsLabel.ALL)) {
      // if "All" was checked and something else is checked, uncheck all
      if (selections.length === 0) {
        newValuesNames = newValuesNames.filter(
          (valueName) => valueName !== DocumentsLabel.ALL
        );
        // if "All" gets checked, clear values so that everything else is unchecked
      } else {
        newValuesNames = [];
      }
    }

    const newValues = props.columnState.filter.options.filter(
      (option: Optional<IDocumentType>) =>
        isSomething(option) && newValuesNames.includes(option.value.name)
    );

    props.onChange(
      props.columnDefinition.type,
      newValues as Optional<NonNullable<Flatten<T>>>[],
      []
    );

    setSelections(newValuesNames);
  };

  const dropdownOptions: string[] = [
    DocumentsLabel.ALL,
    ...mapDocumentTypeToString(props.columnState.filter.options).sort(),
  ];

  return (
    <MultiSelect
      options={dropdownOptions}
      value={selections.length === 0 ? [DocumentsLabel.ALL] : selections}
      disabled={false}
      onChange={onChange}
      getStringToSearch={stringIdentityFunc}
      getLabel={stringIdentityFunc}
      getId={stringIdentityFunc}
    />
  );
};
