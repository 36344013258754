import { ElectionsLabels, ElectionWorkflowStageId } from "common";

import { BankAccountStage } from "../../features/Elections/ElectionWorkflow/BankAccountStage/BankAccountStage";
import { ElectStage } from "../../features/Elections/ElectionWorkflow/ElectStage/ElectStage";
import { Eligibility } from "../../features/Elections/ElectionWorkflow/EligibilityStage/Eligibility";
import { OverviewStage } from "../../features/Elections/ElectionWorkflow/OverviewStage/OverviewStage";
import { ReviewAndSignStage } from "../../features/Elections/ElectionWorkflow/ReviewAndSignStage/ReviewAndSignStage";
import { StrategyStage } from "../../features/Elections/ElectionWorkflow/StrategyStage/StrategyStage";

export interface IElectionWorkflowPage {
  name: string;
  element: () => JSX.Element;
  hasError: boolean;
  // List of stages that can be reached by clicking in the Stage Stepper
  validStepperNavigationStages: ElectionWorkflowStageId[];
  nextButtonTextOverride?: string;
  suppressFooter?: boolean;
}

type ElectionWorkflowPages = {
  [key in ElectionWorkflowStageId]?: IElectionWorkflowPage;
};

export const ElectionWorkflowPages: ElectionWorkflowPages = {
  [ElectionWorkflowStageId.OVERVIEW]: {
    name: ElectionsLabels.OVERVIEW,
    hasError: false,
    validStepperNavigationStages: [],
    element: OverviewStage,
  },
  [ElectionWorkflowStageId.ELIGIBILITY]: {
    name: ElectionsLabels.ELIGIBILITY,
    hasError: false,
    validStepperNavigationStages: [ElectionWorkflowStageId.OVERVIEW],
    element: Eligibility,
  },
  [ElectionWorkflowStageId.STRATEGIES]: {
    name: ElectionsLabels.STRATEGIES,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.ELECT,
    ],
    element: StrategyStage,
    suppressFooter: true,
  },
  [ElectionWorkflowStageId.ELECT]: {
    name: ElectionsLabels.ELECT,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
    ],
    element: ElectStage,
  },
  [ElectionWorkflowStageId.BANK_ACCOUNT]: {
    name: ElectionsLabels.BANK_ACCOUNT,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
      ElectionWorkflowStageId.ELECT,
    ],
    element: BankAccountStage,
  },
  [ElectionWorkflowStageId.REVIEW_AND_SIGN]: {
    name: ElectionsLabels.REVIEW_AND_SIGN,
    hasError: false,
    validStepperNavigationStages: [
      ElectionWorkflowStageId.OVERVIEW,
      ElectionWorkflowStageId.ELIGIBILITY,
      ElectionWorkflowStageId.STRATEGIES,
      ElectionWorkflowStageId.ELECT,
      ElectionWorkflowStageId.BANK_ACCOUNT,
    ],
    nextButtonTextOverride: ElectionsLabels.AGREE_AND_SUBMIT,
    element: ReviewAndSignStage,
  },
};
