import { Stack, Typography } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import {
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardFinancingValues,
} from "common";
import React from "react";

import styles from "./FinancingColumnsCellRenderer.module.scss";

interface IFinancingColumnsCellRendererProps extends ICellRendererParams {
  financingValues: IElectCardFinancingValues;
}

export const FinancingColumnsCellRenderer = (
  props: IFinancingColumnsCellRendererProps
) => {
  const { financingValues } = props;

  return props.node.rowPinned ? (
    <Stack id={styles.financingRow}>
      <div className={styles.amount}>
        {getCurrencyFormattedValueWithZeroDefault(props.value)}
      </div>
      <Typography id={styles.label}>
        {getCurrencyFormattedValueWithZeroDefault(financingValues.cash)}
      </Typography>
      <Typography id={styles.label}>
        {getCurrencyFormattedValueWithZeroDefault(financingValues.financing)}
      </Typography>
      <hr className={styles.line} />
      <Typography id={styles.label}>
        {getCurrencyFormattedValueWithZeroDefault(
          financingValues.existingLoanBalance
        )}
      </Typography>
      <Typography id={styles.label}>
        {getCurrencyFormattedValueWithZeroDefault(
          financingValues.projectedLoanBalance
        )}
      </Typography>
      <Typography id={styles.label}>
        {getCurrencyFormattedValueWithZeroDefault(financingValues.loanLimit)}
      </Typography>
    </Stack>
  ) : (
    <div className={styles.amount}>
      {getCurrencyFormattedValueWithZeroDefault(props.value)}
    </div>
  );
};
