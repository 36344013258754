import { Stack, Typography } from "@mui/material";
import { ElectionsLabels, isSomething } from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectEligibilityStatuses,
  selectResidentialInformation,
} from "../../../../../redux/selectors";
import { ReviewAndSignFormQuestion } from "../ReviewAndSignFormQuestion/ReviewAndSignFormQuestion";

export const EligibilityCard = () => {
  const residentialInformation = useSelector(selectResidentialInformation);
  const eligibileStatuses = useSelector(selectEligibilityStatuses);

  // only show NY resident question if is US resident
  const residentialInfoQuestionAnswerPairs = useMemo(() => {
    const isUsResident =
      isSomething(residentialInformation.isUSResident) &&
      residentialInformation.isUSResident.value;
    const pairs = [
      {
        question: ElectionsLabels.COUNTRY_RESIDENT_TEXT,
        answer: isUsResident ? ElectionsLabels.YES : ElectionsLabels.NO,
      },
    ];
    if (isUsResident) {
      pairs.push({
        question: ElectionsLabels.STATE_RESIDENT_TEXT,
        answer:
          isSomething(residentialInformation.isNYResident) &&
          residentialInformation.isNYResident.value
            ? ElectionsLabels.YES
            : ElectionsLabels.NO,
      });
    }
    return pairs;
  }, [residentialInformation]);

  return (
    <Stack>
      <ReviewAndSignFormQuestion
        title={ElectionsLabels.RESIDENTIAL_INFORMATION}
        questionAnswerPairs={residentialInfoQuestionAnswerPairs}
      />
      <Typography variant={"h3"}>
        {ElectionsLabels.ELIGIBILITY_ATTESTATION}
      </Typography>
      <ReviewAndSignFormQuestion
        title={ElectionsLabels.ACCREDITED_INVESTOR_STATUS}
        questionAnswerPairs={[
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .INDIVIDUAL_NET_WORTH,
            answer: eligibileStatuses.accreditedInvestorStatus
              .hasIndividualNetWorth
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .INDIVIDUAL_INCOME,
            answer: eligibileStatuses.accreditedInvestorStatus
              .hasIndividualIncome
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.ACCREDITED_INVESTOR
                .PROFESSIONAL,
            answer: eligibileStatuses.accreditedInvestorStatus.isProfessional
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
        ]}
      />
      <ReviewAndSignFormQuestion
        title={ElectionsLabels.QUALIFIED_PURCHASER_STATUS}
        questionAnswerPairs={[
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
                .BLACKSTONE_FUND,
            answer: eligibileStatuses.qualifiedPurchaserStatus
              .hasInvestmentsInBlackstoneFunds
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.COMPANY,
            answer: eligibileStatuses.qualifiedPurchaserStatus.isCompany
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER.TRUST,
            answer: eligibileStatuses.qualifiedPurchaserStatus.isTrust
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
          {
            question:
              ElectionsLabels.ELIGIBILITY_CHECKBOX.QUALIFIED_PURCHASER
                .INDIVIDUAL_INVESTMENT,
            answer: eligibileStatuses.qualifiedPurchaserStatus
              .isIndividualInvestment
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
        ]}
      />
      <ReviewAndSignFormQuestion
        title={ElectionsLabels.NOT_ELIGIBLE}
        questionAnswerPairs={[
          {
            question: ElectionsLabels.ELIGIBILITY_CHECKBOX.NOT_ELIGIBLE,
            answer: eligibileStatuses.isNotEligible
              ? ElectionsLabels.YES
              : ElectionsLabels.NO,
          },
        ]}
      />
    </Stack>
  );
};
