export type PathArray = (string | number)[];
// TS version of set/get functions defined here: https://youmightnotneed.com/lodash

export const get = <T>(
  obj: Record<string, unknown>,
  path: string | PathArray,
  defValue?: T
): T | undefined => {
  if (!path) return undefined;
  const pathArray = Array.isArray(path)
    ? path
    : (path.match(/([^[.\]])+/g) as PathArray);
  const result = pathArray.reduce<Record<string, unknown> | unknown>(
    (prevObj, key) =>
      prevObj && typeof prevObj === "object"
        ? prevObj[key as keyof typeof prevObj]
        : undefined,
    obj
  );
  return result === undefined || result === null ? defValue : (result as T);
};

export const set = <T>(
  obj: Record<string | number, unknown>,
  path: string | PathArray,
  value?: T
): void => {
  const pathArray = Array.isArray(path)
    ? path
    : (path.match(/([^[.\]])+/g) as PathArray);
  pathArray.reduce<Record<string | number, unknown>>(
    (acc: Record<string | number, unknown>, key, i) => {
      if (acc[key as keyof typeof acc] === undefined) {
        acc[key as keyof typeof acc] = {};
      }
      if (i === pathArray.length - 1) {
        acc[key as keyof typeof acc] = value;
      }
      return acc[key as keyof typeof acc] as typeof acc;
    },
    obj
  );
};
