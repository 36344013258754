import { VestingEvents } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";

export const VestingEventsWrapper = () => {
  const entitlements = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );

  return (
    <VestingEvents
      hasViewRestrictedEquity={entitlements.hasViewRestrictedEquityEntitlement}
    />
  );
};
