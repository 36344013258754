import { Text, View } from "@react-pdf/renderer";
import {
  getCurrencyFormattedValueWithZeroDefault,
  IElectCardGridRow,
  zeroIfNothing,
} from "common";
import React from "react";

import { sharedStyles } from "../../SharedStyleSheet";
import { styles } from "./ElectionAmountTableRowsStyleSheet";
interface IElectionAmountTableRowsProps {
  items: IElectCardGridRow[];
}

export const ElectionAmountTableRows = (
  props: IElectionAmountTableRowsProps
) => {
  const { items } = props;

  return (
    <>
      {items &&
        items.map((item, index) => {
          const borderColor =
            index === items.length - 1
              ? sharedStyles.blackBorderBottom
              : sharedStyles.grayBorderBottom;
          return (
            <View style={[styles.row, borderColor]} key={index}>
              <Text style={[sharedStyles.textColumn, styles.strategy]}>
                {item.name}
              </Text>
              <Text
                style={[sharedStyles.textColumn, styles.remainingCommitment]}
              >
                {getCurrencyFormattedValueWithZeroDefault(
                  item.remainingCommitment
                )}
              </Text>
              <Text
                style={[sharedStyles.textColumn, styles.mandatoryCommitment]}
              >
                {getCurrencyFormattedValueWithZeroDefault(
                  item.mandatoryCommitment
                )}
              </Text>
              <Text style={[sharedStyles.textColumn, styles.election]}>
                {getCurrencyFormattedValueWithZeroDefault(
                  zeroIfNothing(item.optionalElection)
                )}
              </Text>
              <Text style={[sharedStyles.textColumn, styles.totalCommitment]}>
                {getCurrencyFormattedValueWithZeroDefault(item.totalCommitment)}
              </Text>
              <Text
                style={[
                  sharedStyles.textColumn,
                  styles.forecastedInvestment,
                  sharedStyles.grayBackgroundColor,
                ]}
              >
                {getCurrencyFormattedValueWithZeroDefault(
                  item.totalForecastInvestment
                )}
              </Text>
            </View>
          );
        })}
    </>
  );
};
