import { InvestmentEntityDropdown } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";

interface InvestmentEntityDropdownWrapperProps {
  clientsOnly?: boolean;
}

const InvestmentEntityDropdownWrapper = (
  props: InvestmentEntityDropdownWrapperProps
) => {
  const entitlements = useSelector(
    (store: InvestorUIStore) => store.entitlements
  );
  return (
    <InvestmentEntityDropdown
      viewInternalInvestmentDataPermittedEntities={
        entitlements.viewInternalInvestmentDataPermittedEntities
      }
      clientsOnly={props.clientsOnly}
    />
  );
};

export default InvestmentEntityDropdownWrapper;
