import {
  convertElectionWorkflowStateToUpdateSource,
  ElectionAccordionObject,
  IBaseStore,
  isSomething,
  reqPutElectionWorkflowState,
  selectElectionIVConfiguration,
  selectElectionRoundConfiguration,
  selectPossibleNextStageId,
  setAccordionStatus,
  setIsSBSElectionSaveEnabled,
} from "common";
import { useDispatch, useSelector, useStore } from "react-redux";

import { InvestorUIStore } from "../../redux/store";

export const GetIsExpanded = (accordionId: ElectionAccordionObject) => {
  const { electionAccordionCardStatuses } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  if (Object.hasOwn(electionAccordionCardStatuses, accordionId)) {
    return electionAccordionCardStatuses[accordionId];
  }
  return false;
};

export const useHandleExpand = (
  accordionId: ElectionAccordionObject,
  expandStatus: boolean
) => {
  const dispatch = useDispatch();

  const handleExpand = () => {
    dispatch(
      setAccordionStatus({
        accordionObject: accordionId,
        status: !expandStatus,
      })
    );
  };

  return handleExpand;
};

export const useMoveStages = () => {
  const dispatch = useDispatch();
  const store = useStore<IBaseStore>();
  const nextStageId = useSelector(selectPossibleNextStageId);
  const { version: electionRoundConfigurationVersion } = useSelector(
    selectElectionRoundConfiguration
  );
  const { version: ivConfigurationVersion } = useSelector(
    selectElectionIVConfiguration
  );

  const handleMoveStages = () => {
    const { electionWorkflowStateLocal } = store.getState().electionsData;
    if (isSomething(electionWorkflowStateLocal)) {
      dispatch(setIsSBSElectionSaveEnabled(false));
      dispatch(
        reqPutElectionWorkflowState({
          investmentVehicleId:
            electionWorkflowStateLocal.value.investmentVehicleId,
          electionRoundId: electionWorkflowStateLocal.value.electionRoundId,
          targetState: convertElectionWorkflowStateToUpdateSource({
            ...electionWorkflowStateLocal.value,
            currentStage: nextStageId,
            electionRoundConfigurationVersion,
            ivConfigurationVersion,
          }),
        })
      );
    }
    window.scrollTo(0, 0);
  };

  return handleMoveStages;
};
