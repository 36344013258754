import { Link } from "@mui/material";
import { IElectionDocument, useDownloadSingleDocument } from "common";
import React from "react";

interface IDownloadDocumentLinkProps {
  document: IElectionDocument;
  electionRoundId: string;
}
export function DownloadDocumentLink(props: IDownloadDocumentLinkProps) {
  const { document, electionRoundId } = props;

  const { downloadElectionSingleDocument } = useDownloadSingleDocument();

  const handleDocumentDownload = async (
    electionRoundId: string,
    document: IElectionDocument
  ) => await downloadElectionSingleDocument(electionRoundId, document.id);

  return (
    <Link
      component={"button"}
      onClick={() => handleDocumentDownload(electionRoundId, document)}
    >
      {document.name}
    </Link>
  );
}
