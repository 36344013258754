import { Info } from "@mui/icons-material";
import { Alert, Stack, Typography } from "@mui/material";
import {
  BankAccountConstants,
  DataCard,
  FormCardHeader,
  IBaseStore,
  isEmptyResponse,
  isInProgress,
  isUnsuccessful,
  nothing,
  reqBankAccounts,
  selectCanUseFinancing,
  some,
  useFetchDatasetIfIdDefined,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { AccountAssignmentGrid } from "../../../../BankAccounts/AccountAssignmentGrid/AccountAssignmentGrid";
import styles from "./BankInfoCard.module.scss";

export const BankInfoCard = () => {
  const { investmentVehicleId } = useParams();
  const investmentVehicleIdNumber = useMemo(() => {
    if (investmentVehicleId && !isNaN(parseInt(investmentVehicleId))) {
      return some(parseInt(investmentVehicleId));
    }
    return nothing;
  }, [investmentVehicleId]);

  const bankAccountsLoadStatus = useSelector(
    (store: IBaseStore) => store.bankAccounts.bankAccountsLoadStatus
  );
  const { pendingBankAccounts } = useSelector(
    (store: IBaseStore) => store.bankAccounts.serverData
  );

  const canUseFinancing = useSelector(selectCanUseFinancing);

  const retryFunction = useFetchDatasetIfIdDefined(
    reqBankAccounts,
    investmentVehicleIdNumber,
    bankAccountsLoadStatus
  );

  return (
    <DataCard
      loading={isInProgress(bankAccountsLoadStatus)}
      noData={isEmptyResponse(bankAccountsLoadStatus)}
      failed={isUnsuccessful(bankAccountsLoadStatus)}
      onReload={retryFunction}
    >
      <FormCardHeader title="Bank Information" required />
      <Stack id={styles.disclaimers}>
        {canUseFinancing ? (
          <Typography className={styles.legalText}>
            {BankAccountConstants.THIRD_PARTY_FINANCING_WARNING}
          </Typography>
        ) : null}
        <Typography className={styles.legalText}>
          {BankAccountConstants.AUTHORIZATION}
        </Typography>

        {pendingBankAccounts.length > 0 && (
          <Alert
            variant="standard"
            icon={<Info />}
            severity="info"
            id={styles.pendingAccountsAlert}
          >
            {BankAccountConstants.UNDER_REVIEW_WARNING}
          </Alert>
        )}
      </Stack>
      <AccountAssignmentGrid />
    </DataCard>
  );
};
