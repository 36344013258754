import { Grid } from "@mui/material";
import {
  CardFooter,
  DataCard,
  getAsOfLine,
  HoverableChartLegends,
  isEmptyResponse,
  isSomething,
  mapUnrealizedValuesToChartLegends,
  MUIConstants,
  selectElectionInvestmentPortfolioLoadStatus,
  selectUnrealizedValues,
  UnrealizedValue,
  UnrealizedValueLabels,
  UnrealizedValues,
} from "common";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { TitleWithDownloadButton } from "./TitleWithDownloadButton/TitleWithDownloadButton";
import styles from "./UnrealizedValueCard.module.scss";
import { UnrealizedValueChart } from "./UnrealizedValueChart/UnrealizedValueChart";

export const UnrealizedValueCard = () => {
  const unrealizedValues = useSelector(selectUnrealizedValues);
  const electionInvestmentPortfolioLoadStatus = useSelector(
    selectElectionInvestmentPortfolioLoadStatus
  );

  return (
    <DataCard
      id={styles.unrealizedValue}
      noData={
        isEmptyResponse(electionInvestmentPortfolioLoadStatus) ||
        !isSomething(unrealizedValues)
      }
      hideIfNoData
    >
      {isSomething(unrealizedValues) && (
        <UnrealizedValueCardContent unrealizedValues={unrealizedValues.value} />
      )}
    </DataCard>
  );
};

interface IUnrealizedValueCardContentProps {
  unrealizedValues: UnrealizedValues;
}
const UnrealizedValueCardContent = (
  props: IUnrealizedValueCardContentProps
) => {
  const { unrealizedValues } = props;

  const [
    strategiesFilteredByPositiveUnrealizedValue,
    setStrategiesFilteredByPositiveUnrealizedValue,
  ] = useState<UnrealizedValue[]>([]);
  const [stategiesTotalUnrealizedValue, setStategiesTotalUnrealizedValue] =
    useState<number>(0);
  const [showFooter, setShowFooter] = useState<boolean>(false);

  /**
   * Compares if the total o an unrealized value is greater than 0
   * @param unrealizedValue - An unrealized value object
   * @returns True if the total of the unrealized value is greater than 0 otherwise False
   */
  const isUnrealizedValueGreaterThanZero = (
    unrealizedValue: UnrealizedValue
  ): boolean => unrealizedValue.total > 0;

  /**
   * sum up the total of strategies unrealized values
   * @param total - The total of the strategies unrealized values
   * @param unrealizedValue - An unrealized value object
   * @returns True if the total of the unrealized value is greater than 0 otherwise False
   */
  const sumUpStrategiesTotal = (
    total: number,
    unrealizedValue: UnrealizedValue
  ) => {
    return total + unrealizedValue.total;
  };

  useMemo(() => {
    const filteredStrategies = unrealizedValues.unrealizedValues.filter(
      isUnrealizedValueGreaterThanZero
    );
    // if there is any strategy with negative unrealized value a foot note must be shown
    const hasAnyNegativeValue =
      filteredStrategies.length !== unrealizedValues.unrealizedValues.length;
    // calculate strategies total unrealized value
    const strategiesTotal = filteredStrategies.reduce(sumUpStrategiesTotal, 0);

    setStrategiesFilteredByPositiveUnrealizedValue(filteredStrategies);
    setStategiesTotalUnrealizedValue(strategiesTotal);
    setShowFooter(hasAnyNegativeValue);
  }, [unrealizedValues.unrealizedValues]);

  return (
    <>
      <TitleWithDownloadButton
        sectionHeader={UnrealizedValueLabels.UNREALIZED_VALUE}
        sectionSubHeader={getAsOfLine(unrealizedValues.asOfDate)}
        data={unrealizedValues}
      />
      <Grid
        container
        columnSpacing={MUIConstants.ELECTION_WIDGET_COLUMN_SPACING}
        className={styles.content}
      >
        <Grid
          item
          xs={12}
          md={12}
          lg={MUIConstants.ELECTION_WIDGET_LEFT_ITEM_SIZE}
          className={styles.chartLegends}
        >
          {strategiesFilteredByPositiveUnrealizedValue.length > 0 && (
            <HoverableChartLegends
              legends={mapUnrealizedValuesToChartLegends(
                strategiesFilteredByPositiveUnrealizedValue
              )}
            />
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          lg={MUIConstants.ELECTION_WIDGET_RIGHT_ITEM_SIZE}
          className={styles.chartContainer}
        >
          <UnrealizedValueChart
            total={stategiesTotalUnrealizedValue}
            unrealizedValues={strategiesFilteredByPositiveUnrealizedValue}
          />
        </Grid>
        {showFooter && (
          <CardFooter
            footerLines={[UnrealizedValueLabels.EXCLUDE_NEGATIVE_FUNDS]}
          />
        )}
      </Grid>
    </>
  );
};
