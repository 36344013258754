import { Close } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { ElectionsLabels } from "common";
import React from "react";

import styles from "./UnsavedChangesModal.module.scss";

interface IUnsavedChangesModalProps {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  handleGoWithoutSave: () => void;
  handleGoWithSave: () => void;
}

export const UnsavedChangesModal = (props: IUnsavedChangesModalProps) => {
  const handleClose = () => {
    props.setOpen(false);
  };

  const handleGoWithoutSaveClick = () => {
    props.handleGoWithoutSave();
    handleClose();
  };

  const handleGoWithSaveClick = () => {
    props.handleGoWithSave();
    handleClose();
  };

  return (
    <Dialog open={props.open} className={styles.dialog}>
      <DialogContent>
        <Stack direction="row">
          <Typography>{ElectionsLabels.YOU_HAVE_UNSAVED_CHANGES}</Typography>
          <IconButton className={styles.close} onClick={handleClose}>
            <Close />
          </IconButton>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleGoWithoutSaveClick}>
          {ElectionsLabels.NO}
        </Button>
        <Button id={styles.saveChangesButton} onClick={handleGoWithSaveClick}>
          {ElectionsLabels.YES_SAVE_CHANGES}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
