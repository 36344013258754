import { Avatar } from "@mui/material";
import React from "react";

import styles from "./StepIconComponent.module.scss";

interface IStepIconComponentProps {
  value: number;
  isComplete: boolean;
  isActive: boolean;
  isDisabled: boolean;
}

export const StepIconComponent = (props: IStepIconComponentProps) => {
  const { value, isComplete, isActive, isDisabled } = props;

  // based on the state given, determine how to style the icon
  //  a step can be multiple of these, so build up class name
  const generateClassName = () => {
    let baseClass = `${styles.stepNumber}`;
    if (isComplete) {
      baseClass += ` ${styles.complete}`;
    }
    if (isActive) {
      baseClass += ` ${styles.active}`;
    }
    if (isDisabled) {
      baseClass += ` ${styles.disabled}`;
    }
    return baseClass;
  };

  return (
    <div className={styles.wrapper}>
      <Avatar className={generateClassName()}>{value}</Avatar>
    </div>
  );
};
