import { SaveAlt } from "@mui/icons-material";
import { Link, TableCell, TableRow } from "@mui/material";
import clsx from "clsx";
import {
  get2DPNumberFormattedWithZeroDefaultForOptional,
  getCurrencyFormattedValueWithZeroDefault,
  getCurrencyFormattedValueWithZeroDefaultForOptional,
  getPreComputedPercentageFormattedValue,
  isSomething,
  IStrategyModel,
  Optional,
  useDownloadSingleDocument,
} from "common";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";

import {
  selectPPMDocuments,
  selectStrategiesStageConfig,
} from "../../../../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../../../../redux/store";
import styles from "./StrategyAndFundRows.module.scss";

interface StrategyAndFundRowsProps {
  strategy: IStrategyModel;
  hasForecastedDeployment: boolean;
}

export const StrategyAndFundRows = (props: StrategyAndFundRowsProps) => {
  const { strategy, hasForecastedDeployment } = props;

  const { activeElection } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const stageConfig = useSelector((state: InvestorUIStore) =>
    selectStrategiesStageConfig(state)
  );

  const ppmDocuments = useSelector((state: InvestorUIStore) =>
    selectPPMDocuments(state)
  );

  const { downloadElectionSingleDocument } = useDownloadSingleDocument();

  const handleDocumentDownload = async (
    electionRoundId: string,
    documentId: number
  ) => await downloadElectionSingleDocument(electionRoundId, documentId);

  const getTooltipClass = (tooltip: Optional<string>) => {
    return isSomething(tooltip) ? styles.tooltipColumnCell : null;
  };

  const getPPMDocumentCell = (fundId: number): ReactNode => {
    const ppmDoc = ppmDocuments.find((doc) => doc.fundId === fundId);
    if (isSomething(activeElection) && ppmDoc) {
      return (
        <Link
          onClick={() =>
            handleDocumentDownload(
              activeElection.value.electionRoundId,
              ppmDoc.id
            )
          }
        >
          <SaveAlt className={styles.ppmDownloadIcon} />
        </Link>
      );
    }
    return null;
  };

  return (
    <React.Fragment>
      <TableRow className={styles.strategyRow}>
        <TableCell className={styles.nameCell}>{strategy.name}</TableCell>
        {/* Empty cells for data not provided on strategy level */}
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        {hasForecastedDeployment && (
          <>
            <TableCell></TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(stageConfig.totalSubjectToElectionTooltip)
              )}
            >
              {strategy.funds.length > 1
                ? getCurrencyFormattedValueWithZeroDefaultForOptional(
                    strategy.totalSubjectToElection
                  )
                : ""}
            </TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(
                  stageConfig.forecastedDeploymentPercentageTooltip
                ),
                styles.greyCell
              )}
            >
              {strategy.funds.length > 1
                ? getPreComputedPercentageFormattedValue(
                    strategy.forecastedDeploymentPercentage * 100
                  )
                : ""}
            </TableCell>
            <TableCell
              align="right"
              className={clsx(
                getTooltipClass(stageConfig.forecastedDeploymentTooltip),
                styles.greyCell
              )}
            >
              {strategy.funds.length > 1
                ? getCurrencyFormattedValueWithZeroDefaultForOptional(
                    strategy.forecastedDeployment
                  )
                : ""}
            </TableCell>
          </>
        )}
      </TableRow>
      {strategy.funds.map((fund) => (
        <TableRow key={"fund" + fund.fundId} className={styles.fundRow}>
          <TableCell className={styles.fundNameCell}>
            {fund.fundShortName}
          </TableCell>
          <TableCell className={styles.ppmDownloadCell}>
            {getPPMDocumentCell(fund.fundId)}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(getTooltipClass(stageConfig.lpCommitmentTooltip))}
          >
            {getCurrencyFormattedValueWithZeroDefault(
              fund.limitedPartnerCommitment
            )}
          </TableCell>
          <TableCell
            align="right"
            className={clsx(
              getTooltipClass(stageConfig.blackstoneCommitmentTooltip)
            )}
          >
            {getCurrencyFormattedValueWithZeroDefault(
              fund.blackstoneCommitment
            )}
          </TableCell>
          <TableCell align="right">
            {getCurrencyFormattedValueWithZeroDefault(fund.totalFundSize)}
          </TableCell>
          {hasForecastedDeployment && (
            <>
              <TableCell align="right">
                {get2DPNumberFormattedWithZeroDefaultForOptional(
                  fund.investmentPeriodYears
                )}
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  getTooltipClass(stageConfig.totalSubjectToElectionTooltip)
                )}
              >
                {getCurrencyFormattedValueWithZeroDefaultForOptional(
                  fund.totalSubjectToElection
                )}
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  styles.greyCell,
                  getTooltipClass(
                    stageConfig.forecastedDeploymentPercentageTooltip
                  )
                )}
              >
                {getPreComputedPercentageFormattedValue(
                  fund.forecastedDeploymentPercentage * 100
                )}
              </TableCell>
              <TableCell
                align="right"
                className={clsx(
                  styles.greyCell,
                  getTooltipClass(stageConfig.forecastedDeploymentTooltip)
                )}
              >
                {getCurrencyFormattedValueWithZeroDefaultForOptional(
                  fund.forecastedDeployment
                )}
              </TableCell>
            </>
          )}
        </TableRow>
      ))}
    </React.Fragment>
  );
};
