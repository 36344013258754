import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import { Grid } from "@mui/material";
import { ColDef } from "ag-grid-enterprise";
import { AgGridReact } from "ag-grid-react";
import {
  AllocationGridKeys,
  DEFAULT_COLUMN_DEF,
  ElectionsLabels,
  hideTooltips,
  IStrategyForecastedDeploymentPercentage,
  toPercentWithOneDecimal,
  useGridExtensions,
} from "common";
import React, { useMemo, useRef } from "react";

import styles from "./AllocationGrid.module.scss";

interface IAllocationGridProps {
  allocationStrategies: IStrategyForecastedDeploymentPercentage[];
}

export const AllocationGrid: React.FC<IAllocationGridProps> = ({
  allocationStrategies,
}) => {
  const gridRef = useRef<AgGridReact>(null);
  const { setHeaderHeight, resizeColumns, onGridReady } = useGridExtensions();

  const allocationColumnDefs: ColDef<IStrategyForecastedDeploymentPercentage>[] =
    useMemo(
      () => [
        {
          field: AllocationGridKeys.STRATEGY_ID,
          hide: true,
        },
        {
          field: AllocationGridKeys.STRATEGY_NAME,
          headerName: ElectionsLabels.STRATEGY,
          sortable: false,
        },
        {
          field: AllocationGridKeys.PERCENTAGE,
          headerName: ElectionsLabels.ALLOCATION_PERCENTAGE,
          type: "rightAligned",
          valueFormatter: toPercentWithOneDecimal,
          sortable: false,
        },
      ],
      []
    );

  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} className={`ag-theme-alpine`} id={styles.dataGrid}>
        <AgGridReact<IStrategyForecastedDeploymentPercentage>
          ref={gridRef}
          rowData={allocationStrategies}
          pinnedBottomRowData={[
            {
              strategyId: 0,
              strategyName: ElectionsLabels.TOTAL,
              percentage: 100,
            },
          ]}
          defaultColDef={DEFAULT_COLUMN_DEF}
          columnDefs={allocationColumnDefs}
          domLayout="autoHeight"
          onFirstDataRendered={setHeaderHeight}
          onColumnResized={setHeaderHeight}
          cacheQuickFilter={true}
          suppressAggFuncInHeader={true}
          suppressContextMenu={true}
          suppressCellFocus={true}
          onRowDataUpdated={resizeColumns}
          onGridSizeChanged={resizeColumns}
          onDisplayedColumnsChanged={resizeColumns}
          onBodyScroll={hideTooltips}
          suppressMenuHide={true}
          suppressDragLeaveHidesColumns={true}
          suppressMovableColumns={true}
          tabIndex={-1} // disable focus on grid
          onGridReady={onGridReady}
        />
      </Grid>
    </Grid>
  );
};
