import { Checkbox } from "@mui/material";
import { ICellRendererParams } from "ag-grid-enterprise";
import {
  IDocument,
  isSomething,
  shiftSelectDocuments,
  toggleDocumentSelection,
} from "common";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../../redux/store";
import styles from "./Checkbox.module.scss";

export const DocumentGridCheckboxCell = (
  props: ICellRendererParams<IDocument>
) => {
  const dispatch = useDispatch();
  const { lastSelectedIndex, selectedDocuments } = useSelector(
    (store: InvestorUIStore) => store.documentSettings
  );
  const documentOId = props.data?.documentOId;

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    documentOId: number
  ) => {
    if (e.shiftKey && isSomething(lastSelectedIndex)) {
      dispatch(shiftSelectDocuments(documentOId));
    } else {
      dispatch(toggleDocumentSelection(documentOId));
    }
  };

  if (documentOId) {
    return (
      <Checkbox
        className={styles.documentCheckbox}
        checked={selectedDocuments[documentOId] === true}
        onClick={(e) => handleClick(e, documentOId)}
      />
    );
  } else {
    return null;
  }
};
