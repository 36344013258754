import { List, ListItem, Typography } from "@mui/material";
import { ElectionsLabels, FormCardHeader, isSomething } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectElectionRoundDocuments } from "../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../redux/store";
import { DownloadDocumentLink } from "../../../Shared/DownloadDocumentPanel/DownloadDocumentLink";
import { ElectionCard } from "../../Shared/ElectionCard";
import styles from "./ElectionDocumentsCard.module.scss";

export const ElectionDocumentsCard = () => {
  const { activeElection } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const documents = useSelector((state: InvestorUIStore) =>
    selectElectionRoundDocuments(state)
  );

  return (
    <ElectionCard className={styles.cardContent}>
      <FormCardHeader
        title={ElectionsLabels.ELECTION_DOCUMENTS_CARD_HEADER}
        bottomBorder
      />
      <Typography>
        {ElectionsLabels.ELECTION_DOCUMENTS_CARD_SUBTITLE}
      </Typography>
      {isSomething(activeElection) && (
        <List>
          {documents.map((document) => (
            <ListItem key={document.id}>
              <DownloadDocumentLink
                document={document}
                electionRoundId={activeElection.value.electionRoundId}
              />
            </ListItem>
          ))}
        </List>
      )}
    </ElectionCard>
  );
};
