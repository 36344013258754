import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS

import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { Badge, Popover } from "@mui/material";
import {
  DocumentsLabel,
  getNumFiltersApplied,
  IconButtonWithTooltip,
} from "common";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import styles from "./DocumentsGridFilter.module.scss";
import { DocumentsGridFilterPanel } from "./DocumentsGridFilterPanel/DocumentsGridFilterPanel";

export interface IDocumentsGridFilterProps {
  disabled: boolean;
}

export const DocumentsGridFilter = (props: IDocumentsGridFilterProps) => {
  const { columnStates } = useSelector(
    (store: InvestorUIStore) => store.documentSettings
  );

  const countActiveFilters = useMemo(() => {
    return getNumFiltersApplied(columnStates);
  }, [columnStates]);

  const [filterPanelAnchor, setFilterPanelAnchor] =
    useState<HTMLButtonElement | null>(null);

  const handleFilterPanelClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setFilterPanelAnchor(e.currentTarget);
  };

  const handleFilterPanelClose = () => {
    setFilterPanelAnchor(null);
  };

  return (
    <Badge
      overlap={"circular"}
      badgeContent={countActiveFilters}
      max={999}
      color="info"
    >
      <IconButtonWithTooltip
        onClickFunction={handleFilterPanelClick}
        buttonIcon={FilterAltOutlinedIcon}
        hoverText={DocumentsLabel.FILTERS}
        className={styles.filterIconButton}
        disabled={props.disabled}
      />
      <Popover
        className={styles.popover}
        open={filterPanelAnchor !== null}
        anchorEl={filterPanelAnchor}
        onClose={handleFilterPanelClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <DocumentsGridFilterPanel handleClose={handleFilterPanelClose} />
      </Popover>
    </Badge>
  );
};
