import { Clear } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import {
  convertElectionInputToRoundedNumber,
  ElectionsLabels,
  getCurrencyFormattedValueWithZeroDefaultForOptional,
  getStrategyAllocationPercentages,
  IElectionDecision,
  IElectStage,
  IStrategyForecastedDeploymentPercentage,
  selectElectionMinimum,
  selectIVStrategyConfigurations,
  selectOfferAmount,
  selectStrategyConfigurations,
  setIsSBSElectionSaveEnabled,
  some,
  TextFieldWithClear,
  updateAllElectionDecisions,
  zeroIfNothing,
} from "common";
import React, { useMemo, useRef, useState } from "react";
import { Control, useFieldArray } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import { NumericFormatInput } from "../NumericFormatInput/NumericFormatInput";
import { AllocationGrid } from "./AllocationGrid/AllocationGrid";
import styles from "./AutoAllocateDialog.module.scss";

interface IAutoAllocateDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  control: Control<IElectStage>;
}

/*
  Component which renders the proper Election Warning Dialog based on the type of warning provided.
  */
export const AutoAllocateDialog = (props: IAutoAllocateDialogProps) => {
  const { open, setOpen, control } = props;

  const [electionAmount, setElectionAmount] = useState<string>("");

  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);

  const [allocateDisabled, setAllocateDisabled] = useState<boolean>(true);

  const offerAmount = useSelector(selectOfferAmount);

  const electionMinimum = useSelector(selectElectionMinimum);

  const strategyIds = useSelector(selectIVStrategyConfigurations).flatMap(
    (k) => k.strategyId
  );
  const strategyConfigurations = useSelector(
    selectStrategyConfigurations
  ).filter((s) => strategyIds.includes(s.strategyId));

  const manualElectionButton = useRef<HTMLButtonElement>(null);
  const autoAllocateButton = useRef<HTMLButtonElement>(null);

  const allocationStrategies = useMemo(() => {
    return getStrategyAllocationPercentages(strategyConfigurations);
  }, [strategyConfigurations]);

  const { fields, replace } = useFieldArray({
    control: control,
    name: "elections",
  });

  /**
   * Sets the value of the text field and enables save button
   */
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setElectionAmount(event.target.value);
  };

  /**
   * Sets the value of the text field and saves value to store
   */
  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const allocationAmount = convertElectionInputToRoundedNumber(
      event.target.value
    );
    setElectionAmount(
      allocationAmount ? allocationAmount.toLocaleString() : ""
    );
    if (allocationAmount < electionMinimum) {
      setErrorMsg(
        ElectionsLabels.MINIMUM_ALLOCATION_VALIDATION_MESSAGE(electionMinimum)
      );
      setAllocateDisabled(true);
    } else {
      setErrorMsg(undefined);
      setAllocateDisabled(false);
    }
  };

  const cancelElection = () => {
    setElectionAmount("");
    setAllocateDisabled(true);
  };

  const dispatch = useDispatch();

  /*
  whenever closing the modal, we want to wipe the election amount,
    clear errors, and disable the allocate button
  */
  const handleClose = () => {
    setOpen(false);
    // add small timeout to ensure modal closes before values clear
    setTimeout(() => {
      setElectionAmount("");
      setErrorMsg(undefined);
      setAllocateDisabled(true);
    }, 150);
  };

  const handleAllocate = () => {
    dispatch(setIsSBSElectionSaveEnabled(true));
    const allocationAmount =
      convertElectionInputToRoundedNumber(electionAmount);

    // compute election decisions
    const newElectionDecisions: IElectionDecision[] = allocationStrategies.map(
      (strategyForecastPercentage: IStrategyForecastedDeploymentPercentage) => {
        return {
          strategyId: strategyForecastPercentage.strategyId,
          electedAmount: some(
            Math.round(
              (strategyForecastPercentage.percentage / 100) * allocationAmount
            )
          ),
        };
      }
    );

    // compute total election amount after rounding
    const roundedSum = newElectionDecisions.reduce(
      (a, v) => a + zeroIfNothing(v.electedAmount),
      0
    );
    if (roundedSum !== allocationAmount) {
      // if different than what user entered, add/subtract from last strategy
      const diff = allocationAmount - roundedSum;
      let temp = zeroIfNothing(
        newElectionDecisions[newElectionDecisions.length - 1].electedAmount
      );
      temp += diff;
      newElectionDecisions[newElectionDecisions.length - 1].electedAmount =
        some(temp);
    }

    // replace form fields with new values
    const newFields = fields.map((f: IElectionDecision) => {
      return (
        newElectionDecisions.find((el) => el.strategyId === f.strategyId) ?? f
      );
    });
    replace(newFields);
    // update redux store with new values
    dispatch(updateAllElectionDecisions(newElectionDecisions));
    // close modal
    handleClose();
  };

  return (
    <Dialog open={open}>
      <DialogTitle variant="h3">
        <Stack direction={"row"} justifyContent={"space-between"}>
          {ElectionsLabels.ELECTION_ALLOCATION}
          <Clear className={styles.clear} onClick={handleClose} />
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack className={styles.content}>
          <Typography>
            {ElectionsLabels.ELECTION_ALLOCATION_HEADER_MESSAGE}
          </Typography>
          <Stack className={styles.offerAmount}>
            <Typography
              variant={"h3"}
            >{`${ElectionsLabels.OFFER_AMOUNT}:`}</Typography>
            <Typography variant={"h2"}>
              {getCurrencyFormattedValueWithZeroDefaultForOptional(offerAmount)}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center">
            <Typography>{ElectionsLabels.AMOUNT_TO_ALLOCATE}</Typography>
            <span className={styles.requiredField}></span>
          </Stack>
          <Box className={`${styles.box} ${errorMsg ? styles.error : ""}`}>
            <TextFieldWithClear
              id={styles.textField}
              value={electionAmount}
              onChange={onChange}
              handleClear={cancelElection}
              customOnBlur={onBlur}
              inputComponent={NumericFormatInput}
              fullWidth={false}
              errorMsg={errorMsg}
              helperText={errorMsg}
              onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === "Enter") {
                  // on enter, focus on back to manual elect button if the value is not valid
                  //    otherwise, focus on auto allocate button
                  event.preventDefault();
                  const allocationAmount =
                    convertElectionInputToRoundedNumber(electionAmount);
                  if (allocationAmount < electionMinimum) {
                    if (manualElectionButton && manualElectionButton.current) {
                      manualElectionButton.current.focus();
                    }
                  } else {
                    if (autoAllocateButton && autoAllocateButton.current) {
                      autoAllocateButton.current.disabled = false;
                      autoAllocateButton.current.focus();
                    }
                  }
                }
              }}
            />
          </Box>
          <AllocationGrid allocationStrategies={allocationStrategies} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack className={styles.actionButtons}>
          <Button
            className={styles.actionButton}
            ref={manualElectionButton}
            color="secondary"
            onClick={handleClose}
          >
            {ElectionsLabels.CONTINUE_TO_MANUAL_ELECTION}
          </Button>
          <Button
            className={styles.actionButton}
            ref={autoAllocateButton}
            onClick={handleAllocate}
            disabled={allocateDisabled}
          >
            {ElectionsLabels.AUTO_ALLOCATE}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
