import {
  DocumentsLabel,
  Flatten,
  isSomething,
  MultiSelect,
  Optional,
} from "common";
import React, { useState } from "react";

import {
  FilterComponentProps,
  stringIdentityFunc,
} from "../shared/FilterComponentProps";

export const PeriodFilter = <K extends "period", T extends string>(
  props: FilterComponentProps<K, T>
) => {
  const mapPeriodToString = (list: Optional<string>[]): string[] => {
    const filteredList: string[] = [];
    list.forEach((value: Optional<string>) => {
      if (isSomething(value)) {
        filteredList.push(value.value);
      }
    });
    return filteredList;
  };

  const [selections, setSelections] = useState<string[]>(
    mapPeriodToString(props.columnState.filter.values)
  );

  const onChange = (newValues: string[]) => {
    if (newValues.includes(DocumentsLabel.ALL)) {
      // if "All" was checked and something else is checked, uncheck all
      if (selections.length === 0) {
        newValues = newValues.filter(
          (valueName) => valueName !== DocumentsLabel.ALL
        );
        // if "All" gets checked, clear values so that everything else is unchecked
      } else {
        newValues = [];
      }
    }

    const newOptions = props.columnState.filter.options.filter(
      (option: Optional<string>) =>
        isSomething(option) && newValues.includes(option.value)
    );

    props.onChange(
      props.columnDefinition.type,
      newOptions as Optional<NonNullable<Flatten<T>>>[],
      []
    );

    setSelections(newValues);
  };

  const dropdownOptions: string[] = [
    DocumentsLabel.ALL,
    ...mapPeriodToString(props.columnState.filter.options),
  ];

  return (
    <MultiSelect
      options={dropdownOptions}
      value={selections.length === 0 ? [DocumentsLabel.ALL] : selections}
      disabled={false}
      onChange={onChange}
      getStringToSearch={stringIdentityFunc}
      getLabel={stringIdentityFunc}
      getId={stringIdentityFunc}
    />
  );
};
