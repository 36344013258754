import { FormLabel, Stack } from "@mui/material";
import { InfoTooltip } from "common";
import React from "react";

import styles from "./FieldLabelWithTooltip.module.scss";

type FieldLabelWithTooltipProps = {
  label: string;
  tooltip?: string | React.ReactNode;
};

const FieldLabelWithTooltip = (props: FieldLabelWithTooltipProps) => {
  return (
    <Stack direction="row" alignItems="center">
      <FormLabel required className={styles.inputLabel}>
        <span>{props.label}</span>
      </FormLabel>
      {props.tooltip ? (
        <InfoTooltip
          className={styles.tooltip}
          tooltipContent={props.tooltip}
          leftMargin={4}
        />
      ) : null}
    </Stack>
  );
};

export default FieldLabelWithTooltip;
