import { View } from "@react-pdf/renderer";
import { IElectCardData } from "common";
import React from "react";

import { ElectionAmountTableFinancingRows } from "./ElectionAmountTableFinancingRows/ElectionAmountTableFinancingRows";
import { ElectionAmountTableHeader } from "./ElectionAmountTableHeader/ElectionAmountTableHeader";
import { ElectionAmountTableRows } from "./ElectionAmountTableRows/ElectionAmountTableRows";
import { ElectionAmountTableRowTotal } from "./ElectionAmountTableRowTotal/ElectionAmountTableRowTotal";
import { styles } from "./ElectionAmountTableStyleSheet";

interface IElectionAmountTableProps {
  electionAmount: IElectCardData;
  canUseFinancing: boolean;
}

export const ElectionAmountTable = (props: IElectionAmountTableProps) => {
  const { electionAmount, canUseFinancing } = props;

  return (
    <View style={styles.container}>
      <ElectionAmountTableHeader />
      <ElectionAmountTableRows items={electionAmount.electCardData} />
      <ElectionAmountTableRowTotal item={electionAmount.totalRow} />
      {canUseFinancing && (
        <ElectionAmountTableFinancingRows
          financing={electionAmount.financing}
        />
      )}
    </View>
  );
};
