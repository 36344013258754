import { Typography } from "@mui/material";
import {
  ElectionsLabels,
  FormCardHeader,
  getCurrencyFormattedValueWithZeroDefaultForOptional,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import {
  selectOfferAmountTooltip,
  selectOfferedAmount,
} from "../../../../../redux/selectors";
import { ElectionCard } from "../ElectionCard";
import styles from "./OverviewAmountCard.module.scss";

export const OfferAmountCard = () => {
  const tooltip = useSelector(selectOfferAmountTooltip);
  const offeredAmount = useSelector(selectOfferedAmount);

  return (
    <ElectionCard>
      <FormCardHeader
        title={ElectionsLabels.OFFER_AMOUNT}
        bottomBorder
        tooltip={tooltip}
      />
      <Typography className={styles.callout}>
        {getCurrencyFormattedValueWithZeroDefaultForOptional(offeredAmount)}
      </Typography>
    </ElectionCard>
  );
};
