import { ColDef, ICellRendererParams } from "ag-grid-community";
import {
  dateFormatterWithDash,
  DocumentDownloadIcon,
  ElectionsLabels,
  ElectionsListGridKeys,
  filterIconOutlined,
  getCurrencyFormattedValue,
  IElectionsForClientListItem,
  selectInvestmentVehicles,
  toEntityNameString,
} from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../../redux/store";
import { DownloadDocumentButton } from "../../Shared/DownloadDocumentButton/DownloadDocumentButton";
import { ElectionsChip } from "../ElectionChip/ElectionChip";
import styles from "../ElectionsList.module.scss";
import { ElectionTooltip } from "../ElectionTooltip/ElectionTooltip";

export const useElectionList = () => {
  const investmentVehicles = useSelector((state: InvestorUIStore) =>
    selectInvestmentVehicles(state)
  );

  type SafeColDef<T> = Omit<ColDef<T>, "field"> & { field?: keyof T };

  const DownloadElectionDocument = (
    props: ICellRendererParams<IElectionsForClientListItem>
  ) => {
    const { data } = props;
    if (data !== undefined) {
      return (
        <DownloadDocumentButton
          documents={data.documents}
          electionRoundId={data.electionRoundId}
        />
      );
    }
  };

  const colDefs: SafeColDef<IElectionsForClientListItem>[] = useMemo(() => {
    const electionsListColumns = [
      {
        field: ElectionsListGridKeys.CURRENT_STAGE,
        headerName: ElectionsLabels.STATUS,
        minWidth: 150,
        maxWidth: 150,
        cellRenderer: (params: { data: IElectionsForClientListItem }) =>
          ElectionsChip(params.data),
      },
      {
        field: ElectionsListGridKeys.DOCUMENTS,
        headerComponent: () => <DocumentDownloadIcon />,
        minWidth: 70,
        maxWidth: 70,
        cellClass: styles.documentCell,
        cellRenderer: DownloadElectionDocument,
      },
      {
        field: ElectionsListGridKeys.SUBMISSION_DEADLINE,
        headerName: ElectionsLabels.ELECTION_DATE,
        maxWidth: 150,
        valueFormatter: dateFormatterWithDash,
      },
      {
        field: ElectionsListGridKeys.NAME,
        headerName: ElectionsLabels.ELECTION_NAME,
        minWidth: 200,
        resizable: true,
        valueFormatter: (params: { value: string }) => {
          return params.value;
        },
      },
    ];
    const electedAmount = {
      field: ElectionsListGridKeys.ELECTED_AMOUNT,
      headerName: ElectionsLabels.ELECTION_DECISION,
      maxWidth: 200,
      valueFormatter: (params: { value: number }) =>
        getCurrencyFormattedValue(params.value),
    };
    const electionsListColumnDefWithIv = [
      ...electionsListColumns,
      {
        field: ElectionsListGridKeys.INVESTMENT_VEHICLE,
        headerName: ElectionsLabels.INVESTMENT_VEHICLE,
        minWidth: 200,
        resizable: true,
        valueFormatter: toEntityNameString,
      },
      electedAmount,
    ];

    const electionsListColumnDefWithoutIv = [
      ...electionsListColumns,
      electedAmount,
    ];

    return investmentVehicles.size > 1
      ? electionsListColumnDefWithIv
      : electionsListColumnDefWithoutIv;
  }, [investmentVehicles]);

  const defaultColDef: ColDef<IElectionsForClientListItem> = useMemo(
    () => ({
      resizable: true,
      icons: {
        menu: filterIconOutlined,
      },
      filter: false,
      minWidth: 110,
      menuTabs: [],
      sortable: false,
      lockPinned: true,
      suppressHeaderMenuButton: true,
      cellRenderer: ElectionTooltip,
    }),
    []
  );

  return {
    colDefs,
    defaultColDef,
  };
};
