import { Info } from "@mui/icons-material";
import { Link, Stack, Typography } from "@mui/material";
import { ElectionsLabels, isSomething } from "common";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  selectElectionRoundConfiguration,
  selectResidentialInformation,
} from "../../../../redux/selectors";
import styles from "./TaxForms.module.scss";

interface ITaxFormsProps {
  showInfoIcon?: boolean;
}

export const TaxForms = (props: ITaxFormsProps) => {
  const { showInfoIcon = false } = props;
  const { isUSResident, isNYResident } = useSelector(
    selectResidentialInformation
  );

  const { taxFormsDueDate } = useSelector(selectElectionRoundConfiguration);

  const shouldShowUSResidentTaxForms = useMemo(() => {
    // The user selected that they are a US Resident
    return isSomething(isUSResident) && isUSResident.value === true;
  }, [isUSResident]);

  const shouldShowNonNYResidentTaxForms = useMemo(() => {
    // The user selected they are not a US Resident
    return (
      (isSomething(isUSResident) && isUSResident.value === false) ||
      // OR The user selected they are not a NY Resident
      (isSomething(isNYResident) && isNYResident.value === false)
    );
  }, [isNYResident, isUSResident]);

  return (
    <>
      <Stack direction="row" className={styles.actionItem}>
        {showInfoIcon && <Info color="info" />}
        <Typography>
          {!showInfoIcon && <b>{ElectionsLabels.PLEASE_NOTE}</b>}
          {shouldShowUSResidentTaxForms ? (
            <>
              <b>{ElectionsLabels.DUE_TO_YOUR_RESPONSES_A}</b>
              <Link id="w9" href={ElectionsLabels.W9_URL} target="_blank">
                {ElectionsLabels.W9_FORM}
              </Link>
              <b>{ElectionsLabels.SHOULD_BE_COMPLETED}</b>
              <Link href={`mailto:${ElectionsLabels.TAX_FORMS_EMAIL}`}>
                {ElectionsLabels.TAX_FORMS_EMAIL}
              </Link>
              <b>{ElectionsLabels.BY_DATE(taxFormsDueDate)}</b>
              {ElectionsLabels.PLEASE_COMPLETE_THIS_FORM +
                ElectionsLabels.IF_YOU_PREVIOUSLY_SUBMITTED}
              {ElectionsLabels.FAILURE_TO_PROVIDE(ElectionsLabels.W9_FORM)}
              {ElectionsLabels.ADVERSE_US_TAX_CONSEQUENCES}
              {ElectionsLabels.ADDITIONALLY_CONSULT_TAX_ADVISOR}
            </>
          ) : (
            <>
              <b>{ElectionsLabels.DUE_TO_YOUR_RESPONSES_A}</b>
              <Link id="w8" href={ElectionsLabels.W8BEN_URL} target="_blank">
                {ElectionsLabels.FORM_W8BEN}
              </Link>
              <b>
                {ElectionsLabels.AND_CRS + ElectionsLabels.SHOULD_BE_COMPLETED}
              </b>
              <Link href={`mailto:${ElectionsLabels.TAX_FORMS_EMAIL}`}>
                {ElectionsLabels.TAX_FORMS_EMAIL}
              </Link>
              <b>{ElectionsLabels.BY_DATE(taxFormsDueDate)}</b>
              {ElectionsLabels.PLEASE_COMPLETE_THESE_FORMS +
                ElectionsLabels.PLEASE_INDICATE_ON_THE_FORM_W8BEN}
              <Link href={ElectionsLabels.W7_URL}>
                {ElectionsLabels.FORM_W7_APPLICATION}
              </Link>
              {ElectionsLabels.FOR_IRS_ITIN}
              {ElectionsLabels.IF_YOU_PREVIOUSLY_SUBMITTED}
              {ElectionsLabels.FAILURE_TO_PROVIDE(
                ElectionsLabels.FORM_W8BEN + ElectionsLabels.AND_CRS
              )}
              {ElectionsLabels.ADVERSE_US_TAX_CONSEQUENCES}
              {ElectionsLabels.ADDITIONALLY_CONSULT_TAX_ADVISOR}
            </>
          )}
        </Typography>
      </Stack>
      {shouldShowNonNYResidentTaxForms && (
        <Stack direction="row" className={styles.actionItem}>
          {showInfoIcon && <Info color="info" />}
          <Typography>
            <b>{ElectionsLabels.FURTHER_NOTE}</b>
            {ElectionsLabels.DUE_TO_YOUR_STATE_RESIDENCY}
            <Link href={ElectionsLabels.IT2658E_URL} target="_blank">
              {ElectionsLabels.FORM_IT2658E}
            </Link>{" "}
            {ElectionsLabels.SHOULD_BE_COMPLETED}
            <Link href={`mailto:${ElectionsLabels.TAX_FORMS_EMAIL}`}>
              {ElectionsLabels.TAX_FORMS_EMAIL}
            </Link>
            {ElectionsLabels.BY_DATE(taxFormsDueDate)}
            {ElectionsLabels.FAILURE_TO_PROVIDE(ElectionsLabels.FORM_IT2658E)}
            {ElectionsLabels.ADVERSE_NYS_TAX_CONSEQUENCES}
            {ElectionsLabels.ADDITIONALLY_CONSULT_TAX_ADVISOR}
          </Typography>
        </Stack>
      )}
      <Typography>
        {ElectionsLabels.YOU_MAY_NOW_PROCEED}
        <Link href={`mailto:${ElectionsLabels.ELECTION_CONTACT_EMAIL}`}>
          {ElectionsLabels.ELECTION_CONTACT_EMAIL}
        </Link>
        .
      </Typography>
    </>
  );
};
