import { ArrowBackIosNew } from "@mui/icons-material";
import { Breadcrumbs } from "@mui/material";
import {
  convertElectionWorkflowStateToUpdateSource,
  ElectionsLabels,
  isSomething,
  PathFragment,
  reqClearActiveElection,
  reqElectionsForClient,
  reqPutElectionWorkflowState,
  selectActiveElectionClient,
} from "common";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { InvestorUIStore } from "../../../../redux/store";
import { UnsavedChangesModal } from "../../ElectionWorkflow/Shared/UnsavedChangesModal/UnsavedChangesModal";
import styles from "./BackToElectionsButton.module.scss";

export interface IBackToElectionsButtonProps {
  checkForUnsavedChanges?: boolean;
}

export const BackToElectionsButton = (props: IBackToElectionsButtonProps) => {
  const { checkForUnsavedChanges = false } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isUnsavedChangesModalOpen, setIsUnsavedChangesModalOpen] =
    useState<boolean>(false);

  const activeElectionClient = useSelector(selectActiveElectionClient);

  // keeps track of the user's current stage
  const { electionWorkflowStateLocal } = useSelector(
    (state: InvestorUIStore) => state.electionsData
  );

  const { isSBSElectionSaveEnabled } = useSelector(
    (state: InvestorUIStore) => state.viewData
  );

  const navigateBackToElections = useCallback(() => {
    if (isSomething(activeElectionClient)) {
      dispatch(reqElectionsForClient(activeElectionClient.value));
    }
    dispatch(reqClearActiveElection());
    navigate(`/${PathFragment.ELECTIONS}`);
  }, [activeElectionClient, dispatch, navigate]);

  const handleGo = useCallback(
    (persistLocalState: boolean) => {
      if (persistLocalState && isSomething(electionWorkflowStateLocal)) {
        dispatch(
          reqPutElectionWorkflowState({
            investmentVehicleId:
              electionWorkflowStateLocal.value.investmentVehicleId,
            electionRoundId: electionWorkflowStateLocal.value.electionRoundId,
            targetState: convertElectionWorkflowStateToUpdateSource(
              electionWorkflowStateLocal.value
            ),
          })
        );
      }
      navigateBackToElections();
      window.scrollTo(0, 0);
    },
    [electionWorkflowStateLocal, dispatch, navigateBackToElections]
  );

  const handleClickBackToElections = () => {
    if (checkForUnsavedChanges && isSBSElectionSaveEnabled) {
      setIsUnsavedChangesModalOpen(true);
    } else {
      navigateBackToElections();
    }
  };

  return (
    <>
      <Breadcrumbs>
        <div className={styles.breadcrumb} onClick={handleClickBackToElections}>
          <div className={styles.arrow}>
            <ArrowBackIosNew />
          </div>
          {ElectionsLabels.BACK_TO_ELECTIONS}
        </div>
      </Breadcrumbs>
      <UnsavedChangesModal
        open={isUnsavedChangesModalOpen}
        setOpen={setIsUnsavedChangesModalOpen}
        handleGoWithoutSave={() => {
          handleGo(false);
        }}
        handleGoWithSave={() => {
          handleGo(true);
        }}
      />
    </>
  );
};
