import { Stack, TextField } from "@mui/material";
import { get } from "common/src/utils/objectUtils";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import {
  AddBankAccountFormState,
  AddBankAccountState,
} from "../../AddBankAccountDialog";
import FieldLabelWithTooltip from "../FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./TextFieldInput.module.scss";

export interface ITextFieldInputProps {
  field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>;
  attributeName: string;
  label: string;
  placeHolderText: string;
  tooltip?: string | React.ReactNode;
  prefix?: string;
}

export const TextFieldInput = (props: ITextFieldInputProps) => {
  const { field, attributeName, placeHolderText, prefix = "" } = props;

  const onTextInputChange = (
    field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>,
    name: string
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      // TODO: we might want to use immer in order to modify nested objects more easily
      const originalValue: AddBankAccountState = field.value;
      const getUpdatedState = (): AddBankAccountState => {
        if (prefix.length !== 0) {
          const nestedChild = originalValue[
            prefix as keyof typeof originalValue
          ] as Record<string, unknown>;
          return {
            ...originalValue,
            [prefix]: {
              ...nestedChild,
              [name]: event.currentTarget.value,
            },
          };
        } else {
          return {
            ...originalValue,
            [name]: event.currentTarget.value,
          };
        }
      };
      field.onChange(getUpdatedState());
    };
  };

  return (
    <Stack className={styles.stack}>
      <FieldLabelWithTooltip {...props} />
      <TextField
        className={styles.textInput}
        variant="outlined"
        autoComplete={"off"}
        placeholder={placeHolderText}
        onChange={onTextInputChange(field, attributeName)}
        value={get(
          field.value as unknown as Record<string, unknown>,
          [prefix, attributeName].join(".")
        )}
      />
    </Stack>
  );
};
