import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { AccountIntermediaryType, AccountType } from "common";
import React from "react";
import { ControllerRenderProps } from "react-hook-form";

import {
  AddBankAccountFormState,
  AddBankAccountState,
} from "../../AddBankAccountDialog";
import FieldLabelWithTooltip from "../FieldLabelWithTooltip/FieldLabelWithTooltip";
import styles from "./AccountTypeRadioButtonInput.module.scss";

export interface IAccountTypeRadioButtonInputProps {
  field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>;
  disabled: boolean;
  label: string;
  tooltip?: string | React.ReactNode;
  isIntermediaryAccount: boolean;
  options: Array<AccountType | AccountIntermediaryType>;
}

export const AccountTypeRadioButtonInput = (
  props: IAccountTypeRadioButtonInputProps
) => {
  const { field, disabled, options, isIntermediaryAccount } = props;

  const onAccountTypeChange = (
    field: ControllerRenderProps<AddBankAccountFormState, `addBankAccount`>
  ) => {
    return (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      const originalValue: AddBankAccountState = field.value;
      const getUpdatedValue = (): AddBankAccountState => {
        // TODO: we might want to use immer in order to modify nested objects more easily
        if (isIntermediaryAccount) {
          return {
            ...originalValue,
            intermediaryAccount: {
              ...originalValue.intermediaryAccount,
              accountType: value as AccountIntermediaryType,
            },
          };
        }
        return {
          ...originalValue,
          accountType: value as AccountType,
        };
      };
      field.onChange(getUpdatedValue());
    };
  };

  return (
    <Stack>
      <FieldLabelWithTooltip {...props} />
      <RadioGroup
        {...field}
        row
        onChange={onAccountTypeChange(field)}
        value={
          isIntermediaryAccount
            ? field.value.intermediaryAccount.accountType
            : field.value.accountType
        }
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={"AddAccountRadio-".concat(option)}
              className={styles.radioButton}
              value={option}
              control={<Radio disabled={disabled} />}
              label={option}
            />
          );
        })}
      </RadioGroup>
    </Stack>
  );
};
