import { Alert, Stack, Typography } from "@mui/material";
import clsx from "clsx";
import { InfoTooltip, isSomething, Markdown, Optional } from "common";
import React from "react";

import styles from "./FormCardHeader.module.scss";

interface IFormCardHeaderProps {
  title: string;
  required?: boolean;
  subTitle?: string;
  hasError?: boolean;
  tooltip?: Optional<string>;
  actionElement?: JSX.Element;
  bottomBorder?: boolean;
  isAccordionCard?: boolean;
  isRichContext?: boolean;
}

export const FormCardHeader = ({
  title,
  subTitle,
  hasError,
  tooltip,
  actionElement,
  bottomBorder,
  required = false,
  isAccordionCard = false,
  isRichContext = true,
}: IFormCardHeaderProps) => {
  return (
    <Stack
      className={clsx(
        styles.formCardHeader,
        isAccordionCard && styles.isAccordion,
        bottomBorder && styles.bottomBorder
      )}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          <Typography
            variant="h3"
            className={hasError ? styles.errorTitle : ""}
          >
            {title}
          </Typography>
          <span className={required ? styles.requiredField : ""}></span>
          {tooltip && isSomething(tooltip) && (
            <InfoTooltip
              tooltipContent={
                isRichContext ? (
                  <Markdown text={tooltip.value} />
                ) : (
                  [tooltip.value]
                )
              }
            />
          )}
        </Stack>
        {actionElement}
      </Stack>
      {subTitle && !hasError && (
        <Stack direction="row">
          <Typography className={styles.subTitle} variant="h4">
            {subTitle}
          </Typography>
        </Stack>
      )}
      {hasError && subTitle && (
        <Stack direction="row" className={styles.errorAlert}>
          <Alert sx={{ width: "100%" }} variant="standard" severity="error">
            {subTitle}
          </Alert>
        </Stack>
      )}
    </Stack>
  );
};
