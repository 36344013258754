import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  row: {
    display: "flex",
    flexDirection: "row",
    fontSize: 10,
    width: 531,
    alignItems: "center",
    paddingLeft: 236,
  },
  election: {
    width: 120,
  },
  totalCommitment: {
    width: 97,
  },
  forecastedInvestment: {
    width: 84,
  },
});
