import { Text, View } from "@react-pdf/renderer";
import React from "react";

import { styles } from "./QuestionResponseStyleSheet";

export interface IQuestionResponseProps {
  question: string;
  response: string;
}

export const QuestionResponse = (props: IQuestionResponseProps) => {
  const { question, response } = props;
  return (
    <View style={styles.container}>
      <View style={styles.leftColumn}>
        <Text style={styles.question}>{question}</Text>
      </View>
      <View style={styles.rightColumn}>
        <Text style={styles.response}>{response}</Text>
      </View>
    </View>
  );
};
