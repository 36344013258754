import { createReducer } from "@reduxjs/toolkit";
import {
  DataLoadStatus,
  DefaultColumnDefinitions,
  DefaultColumnStates,
  deselectAllDocuments,
  EMPTY_DOCUMENT_RESPONSE,
  errAvailableDocumentFilters,
  errDocuments,
  isSomething,
  markDocumentsAsDownloaded,
  nothing,
  PageSize,
  PossibleDocumentColumns,
  recvDocuments,
  reqAvailableDocumentFilters,
  reqDocuments,
  reqDocumentsForSearch,
  setColumnFilterOptions,
  setSelectedDocumentClient,
  shiftSelectDocuments,
  some,
  toggleDocumentSelection,
  toggleVisibleDocumentSelection,
  updateDocumentAvailableFilterConfig,
  updateDocumentColumnSettings,
  updateDocumentSearchTerm,
  updateDocumentSortOptions,
} from "common";

import { InvestorUIDocumentStore } from "../../types/storetypes";

export const investorUIDocumentReducer = createReducer<InvestorUIDocumentStore>(
  {
    activeDocumentClient: nothing,
    columnStates: DefaultColumnStates,
    availableFiltersLoadStatus: DataLoadStatus.NOT_REQUESTED,
    documentLoadStatus: DataLoadStatus.NOT_REQUESTED,
    page: 1,
    pageSize: 50,
    searchTerm: nothing,
    documentsForGrid: EMPTY_DOCUMENT_RESPONSE,
    selectedDocuments: {},
    sortOptions: nothing,
    lastSelectedIndex: nothing,
  },
  (builder) => {
    builder.addCase(setSelectedDocumentClient, (state, action) => {
      state.activeDocumentClient = action.payload;
    });
    builder.addCase(updateDocumentColumnSettings, (state, action) => {
      state.columnStates = action.payload;
    });
    builder.addCase(updateDocumentSearchTerm, (state, action) => {
      state.searchTerm = action.payload;
    });
    builder.addCase(reqAvailableDocumentFilters, (state) => {
      state.availableFiltersLoadStatus = DataLoadStatus.LOADING;
    });
    builder.addCase(updateDocumentAvailableFilterConfig, (state, action) => {
      // set each clumn filter options based on action payload provided
      for (const column of PossibleDocumentColumns) {
        const columnFilterOptions = action.payload[column];
        if (columnFilterOptions !== undefined) {
          setColumnFilterOptions<typeof column>(
            DefaultColumnDefinitions[column],
            state.columnStates[column],
            columnFilterOptions
          );
        }
      }
    });
    builder.addCase(errAvailableDocumentFilters, (state, action) => {
      state.availableFiltersLoadStatus = action.payload;
    });
    builder.addCase(reqDocuments, (state, action) => {
      state.documentLoadStatus = DataLoadStatus.LOADING;
      // update page and page size based on request
      const pageSize: PageSize = action.payload.body.limit as PageSize;
      state.pageSize = pageSize;
      state.page = action.payload.body.offset / pageSize + 1;
    });
    builder.addCase(reqDocumentsForSearch, (state, action) => {
      state.documentLoadStatus = DataLoadStatus.LOADING;
      // update page and page size based on request
      const pageSize: PageSize = action.payload.body.limit as PageSize;
      state.pageSize = pageSize;
      state.page = action.payload.body.offset / pageSize + 1;
    });
    builder.addCase(recvDocuments, (state, action) => {
      state.documentsForGrid = action.payload;
      state.documentLoadStatus = DataLoadStatus.SUCCESSFUL;
      // if new documents are loaded, we need a new index to shift click from
      state.lastSelectedIndex = nothing;
    });
    builder.addCase(errDocuments, (state) => {
      state.documentLoadStatus = DataLoadStatus.UNSUCCESSFUL;
    });
    builder.addCase(toggleDocumentSelection, (state, action) => {
      // if the selectedDocuments object already has a key matching the
      // documentOId in the payload, remove that key to "uncheck"
      if (Object.hasOwn(state.selectedDocuments, action.payload)) {
        delete state.selectedDocuments[action.payload];
      } else {
        // otherwise add the key
        state.selectedDocuments[action.payload] = true;
        // prepare for possible shift selection on next click
        const selectedIndex = state.documentsForGrid.documents.findIndex(
          (doc) => doc.documentOId === action.payload
        );
        state.lastSelectedIndex = some(selectedIndex);
      }
    });
    builder.addCase(toggleVisibleDocumentSelection, (state, action) => {
      // set all to selected;
      if (action.payload === true) {
        state.documentsForGrid.documents.forEach((document) => {
          state.selectedDocuments[document.documentOId] = true;
        });
      } else {
        state.documentsForGrid.documents.forEach((document) => {
          delete state.selectedDocuments[document.documentOId];
        });
      }
      // no last selection in grid after clicking header toggle
      state.lastSelectedIndex = nothing;
    });
    builder.addCase(deselectAllDocuments, (state) => {
      state.selectedDocuments = {};
    });
    builder.addCase(updateDocumentSortOptions, (state, action) => {
      state.sortOptions = action.payload;
    });
    builder.addCase(markDocumentsAsDownloaded, (state, action) => {
      const set = new Set(action.payload);
      const newDownloadedDate = new Date();
      for (const document of state.documentsForGrid.documents) {
        if (set.has(document.documentOId)) {
          document.downloadedDate = newDownloadedDate;
        }
      }
    });
    builder.addCase(shiftSelectDocuments, (state, action) => {
      const previousSelectionIndex = state.lastSelectedIndex;
      const currentSelectionIndex = state.documentsForGrid.documents.findIndex(
        (doc) => doc.documentOId === action.payload
      );
      if (isSomething(previousSelectionIndex)) {
        const start = Math.min(
          previousSelectionIndex.value,
          currentSelectionIndex
        );
        const end = Math.max(
          previousSelectionIndex.value,
          currentSelectionIndex
        );
        for (let i = start; i <= end; i++) {
          const documentOId = state.documentsForGrid.documents[i].documentOId;
          state.selectedDocuments[documentOId] = true;
        }
      }
      state.lastSelectedIndex = some(currentSelectionIndex);
    });
  }
);
