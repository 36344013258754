import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { EnvironmentResolver, LoadingIndicator, useLogin } from "common";
import React, { useState } from "react";
import { useAuth } from "react-oidc-context";

import styles from "./Login.module.scss";

export const Login = () => {
  return (
    <div className={styles.loginPage}>
      <Card className={styles.authCard}>
        <CardHeader
          title="Welcome to BXWealth"
          titleTypographyProps={{
            className: styles.authCardTitle,
          }}
        />
        <CardContent className={styles.authCardContent}>
          <AuthCard />
        </CardContent>
      </Card>
    </div>
  );
};

const AuthCard = () => {
  const auth = useAuth();
  const [email, setEmail] = useState<string>("");

  const handleLogin = () =>
    auth.signinRedirect({ extraQueryParams: { login_hint: email } });

  const isAuthInProgress = useLogin(auth);

  if (isAuthInProgress) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {auth.error && (
        <Alert className={styles.alert} severity="error">
          <AlertTitle className={styles.alertTitle}>Error</AlertTitle>
          {auth.error.message}
        </Alert>
      )}
      <Box
        className={styles.loginForm}
        component="form"
        onSubmit={(e: React.FormEvent) => {
          e.preventDefault();
          handleLogin();
        }}
      >
        <TextField
          autoComplete={"off"}
          className={styles.loginInput}
          label="Email"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <Button type="submit">Log In</Button>
      </Box>
      <Box
        className={styles.footerLinks}
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="center"
      >
        <Link href="mailto:BXTI-WealthPortal@Blackstone.com">Help</Link>
        <Typography className={styles.splitter}> | </Typography>
        <Link href="https://www.blackstone.com/privacy#onlinePrivacyNotice">
          Privacy Policy
        </Link>
        <Typography className={styles.splitter}> | </Typography>
        <Link href={EnvironmentResolver.ENV.REACT_APP_BXACCESS_URL}>
          BXAccess
        </Link>
      </Box>
    </>
  );
};
