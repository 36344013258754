import {
  ColumnDefinition,
  ColumnState,
  DocumentColumnName,
  DocumentGridRow,
  Flatten,
  IEntity,
  isSomething,
  Optional,
} from "common";

export type FilterComponentProps<
  K extends DocumentColumnName,
  T extends DocumentGridRow[K]
> = {
  columnDefinition: ColumnDefinition<K>;
  columnState: ColumnState<K>;
  onChange: (
    column: K,
    value:
      | Optional<NonNullable<Flatten<T>>>
      | Optional<NonNullable<Flatten<T>>>[],
    errors: string[],
    index?: number
  ) => void;
};

export const mapOptionalToEntity = (list: Optional<IEntity>[]): IEntity[] => {
  const filteredList: IEntity[] = [];
  list.forEach((value: Optional<IEntity>) => {
    if (isSomething(value)) {
      filteredList.push(value.value);
    }
  });
  return filteredList.sort((a: IEntity, b: IEntity) =>
    a.name > b.name ? 1 : -1
  );
};

export const stringIdentityFunc = (option: string): string => {
  return option;
};
