import "./index.scss";

import { datadogRum } from "@datadog/browser-rum";
import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LicenseManager } from "ag-grid-enterprise";
import { EnvironmentResolver, OIDCConfig } from "common";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import React from "react";
import { createRoot } from "react-dom/client";
import ReactGA from "react-ga4";
import { AuthProvider } from "react-oidc-context";
import { Provider } from "react-redux";

import App from "./app/App";
import { CustomTheme } from "./constants/CustomTheme";
import { store } from "./redux/store";
import reportWebVitals from "./reportWebVitals";

EnvironmentResolver.configureEnvironment().then(() => {
  // AG Grid Enterprise licence. Reach out to BXTI - Purchasing if expired.
  LicenseManager.setLicenseKey(
    "Using_this_{AG_Grid}_Enterprise_key_{AG-059916}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Blackstone_Alternative_Credit_Advisors_LP}_is_granted_a_{Multiple_Applications}_Developer_License_for_{42}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_{AG_Grid}_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{30_June_2025}____[v3]_[01]_MTc1MTIzODAwMDAwMA==eb5e8f7c989d2cbdbeaad880e101365c"
  );

  // Set up front-end monitoring with Datadog.
  datadogRum.init({
    applicationId: "68e1d89e-1ba3-493b-bc3b-3a2acd23b2b5",
    clientToken: "pub2cf85c6d7c5566bd2fdb90915e19f83c",
    site: "datadoghq.com",
    service: "bxwealth",
    env: EnvironmentResolver.ENV.REACT_APP_ENV,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask",
  });

  datadogRum.startSessionReplayRecording();

  const oidcConfig = OIDCConfig(EnvironmentResolver.ENV);

  // Set up the Google Analytics tracker
  if (
    EnvironmentResolver.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKER !== undefined
  ) {
    ReactGA.initialize(
      EnvironmentResolver.ENV.REACT_APP_GOOGLE_ANALYTICS_TRACKER,
      {
        gaOptions: {
          cookieFlags: "SameSite=None;Secure",
        },
        gtagOptions: {
          user_properties: {
            bx_env: EnvironmentResolver.ENV.REACT_APP_ENV,
          },
        },
      }
    );
  }

  dayjs.extend(utc);

  const container = document.getElementById("root");

  // null assertion required according to official docs
  // https://reactjs.org/blog/2022/03/08/react-18-upgrade-guide.html#updates-to-client-rendering-apis
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <AuthProvider {...oidcConfig}>
          <ThemeProvider theme={CustomTheme}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <StyledEngineProvider injectFirst>
                <App />
              </StyledEngineProvider>
            </LocalizationProvider>
          </ThemeProvider>
        </AuthProvider>
      </Provider>
    </React.StrictMode>
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
