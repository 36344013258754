import { Typography } from "@mui/material";
import {
  DocumentsLabel,
  reqAvailableDocumentFilters,
  useFetchDatasetIfIdDefined,
} from "common";
import React from "react";
import { useSelector } from "react-redux";

import { InvestorUIStore } from "../../../redux/store";
import { DocumentsGrid } from "./DocumentsGrid/DocumentsGrid";
import styles from "./DocumentsGridPage.module.scss";

export const DocumentsGridPage = () => {
  const { activeDocumentClient, availableFiltersLoadStatus } = useSelector(
    (store: InvestorUIStore) => store.documentSettings
  );

  useFetchDatasetIfIdDefined(
    reqAvailableDocumentFilters,
    activeDocumentClient,
    availableFiltersLoadStatus
  );

  return (
    <div className={styles.page}>
      <div className={styles.pageHeader}>
        <Typography variant="h1">{DocumentsLabel.DOCUMENTS}</Typography>
      </div>
      <div className={styles.pageContent}>
        <DocumentsGrid />
      </div>
    </div>
  );
};
