import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, TextField } from "@mui/material";
import React from "react";

import styles from "./SearchBar.module.scss";

interface SearchBarProps {
  className?: string | undefined;
  placeholder?: string | undefined;
  onChange?: React.ChangeEventHandler<HTMLInputElement> | undefined;
  value?: string | undefined;
  searchIconPosition?: "end" | "start" | undefined;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement> | undefined;
  disabled?: boolean | undefined;
}

export const SearchBar = (props: SearchBarProps) => {
  return (
    <TextField
      size="small"
      placeholder={props.placeholder}
      fullWidth
      id={styles.searchBar}
      className={`${props.className} ${props.disabled ? styles.disabled : ""}`}
      onChange={props.onChange}
      onKeyDown={props.onKeyDown}
      value={props.value}
      autoComplete="off"
      disabled={props.disabled}
      InputProps={
        props.searchIconPosition == "end"
          ? {
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon className={styles.searchIcon} />
                </InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={styles.searchIcon} />
                </InputAdornment>
              ),
            }
      }
      sx={{
        marginBottom: 0,
      }}
    />
  );
};
