import { CardFooter, isSomething } from "common";
import React from "react";
import { useSelector } from "react-redux";

import { selectStrategyFootnotes } from "../../../../../../redux/selectors";
import { InvestorUIStore } from "../../../../../../redux/store";
import { StrategyOverviewTable } from "./StrategyOverviewTable/StrategyOverviewTable";

export const StrategyOverviewContent = () => {
  const footnotes = useSelector((state: InvestorUIStore) =>
    selectStrategyFootnotes(state)
  );

  return (
    <>
      <StrategyOverviewTable />
      {isSomething(footnotes) && <CardFooter footerLines={[footnotes.value]} />}
    </>
  );
};
