import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { IHeaderGroupParams } from "ag-grid-community";
import React from "react";

import { SortDirection } from "../../constants/enums"; // Assuming SortDirection is still used here
import { ISortOptions, SortByEnum } from "../../types/dataTypes";
import { isSomething } from "../../types/typeGuards";
import { nothing, Optional, some } from "../../types/typeUtils";
import styles from "./GridHeaderWithFilterAndSort.module.scss";

interface IGridHeaderWithFilterAndSortProps<T extends SortByEnum>
  extends IHeaderGroupParams {
  showFilterIcon: boolean;
  labelClass?: string | null;
  textClass?: string | null;
  handleSort?: (sort: Optional<ISortOptions<T>>) => void | undefined;
  currentSortOptions?: Optional<ISortOptions<T>> | undefined;
  fieldToSortBy?: T | undefined;
}

export const GridHeaderWithFilterAndSort = <T extends SortByEnum>(
  props: IGridHeaderWithFilterAndSortProps<T>
) => {
  const {
    displayName,
    showFilterIcon,
    labelClass,
    textClass,
    fieldToSortBy,
    handleSort,
    currentSortOptions,
  } = props;

  // if no field to sort on specified, don't sort
  const canSort = fieldToSortBy != undefined;

  const handleSortClick = () => {
    // if we can't sort, don't sort
    if (!canSort) {
      return;
    }

    let newSortOptions: Optional<ISortOptions<T>> = some({
      sortBy: fieldToSortBy,
      orderBy: SortDirection.ASC,
    });

    // if there already is a sort applied for the field/column
    if (
      currentSortOptions &&
      isSomething(currentSortOptions) &&
      currentSortOptions.value.sortBy === fieldToSortBy
    ) {
      // sort in descending order if previously ascending
      if (currentSortOptions.value.orderBy === SortDirection.ASC) {
        newSortOptions.value.orderBy = SortDirection.DESC;
        // reset sort if previosuly descending
      } else {
        newSortOptions = nothing;
      }
    }

    handleSort && handleSort(newSortOptions);
  };

  return (
    <div
      className={`${labelClass} ag-header-group-cell-label`}
      onClick={handleSortClick}
      onTouchStart={handleSortClick}
      onTouchStartCapture={handleSortClick}
    >
      <div className={`${textClass} ag-header-group-text`}>{displayName}</div>
      {currentSortOptions && isSomething(currentSortOptions) && (
        <>
          {fieldToSortBy === currentSortOptions.value.sortBy &&
            currentSortOptions.value.orderBy === SortDirection.ASC && (
              <ArrowUpwardIcon
                className={`${styles.sortIcon} ${styles.icon}`}
              />
            )}
          {fieldToSortBy === currentSortOptions.value.sortBy &&
            currentSortOptions.value.orderBy === SortDirection.DESC && (
              <ArrowDownwardIcon
                className={`${styles.sortIcon} ${styles.icon}`}
              />
            )}
        </>
      )}
      {showFilterIcon && (
        <FilterAltOutlinedIcon
          className={`${styles.filterIcon} ${styles.icon}`}
        />
      )}
    </div>
  );
};
