import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";

import styles from "./SingleSelect.module.scss";

export interface ISingleSelectProps {
  handleChange: (event: SelectChangeEvent<string>) => void;
  selection: string;
  options: string[];
}

export const SingleSelect = (props: ISingleSelectProps) => {
  const { handleChange, selection, options } = props;

  return (
    <FormControl className={styles.filter}>
      <Select
        className={styles.select}
        variant={"outlined"}
        value={selection}
        onChange={(e) => handleChange(e)}
      >
        {options.map((value: string, index: number) => {
          return (
            <MenuItem key={index} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
